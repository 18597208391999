import React from "react";
import { useDispatch, useSelector } from "react-redux";

import closeIcon from "../../../assets/img/Close_Filter_White.svg";

import {
  changeShowEventList,
  resetSearchValue,
  searchEventsPerPage,
} from "../../../store/actions/eventAction";
import { redirectToMainRoute } from "../../../shared/react-router.utils";
import { useParams, useHistory } from "react-router-dom";
import { RootState } from "../../../store/store";
import { IUrlParams } from "../../../models/navigation";
import { Chip } from "@mui/material";

const SearchChip = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(
    (state: RootState) => state.category.selectedCategory
  );
  const sortedBy = useSelector((state: RootState) => state.event.sortedBy);
  const searchValue = useSelector(
    (state: RootState) => state.event.searchValue
  );
  const dateRange = useSelector((state: RootState) => state.event.dateRange);
  const { eventId }: IUrlParams = useParams();
  const history = useHistory();
  const timezone = useSelector((state: RootState) => state.event.timezone);

  const resetValueSearch = () => {
    dispatch(changeShowEventList(true));
    dispatch(resetSearchValue(selectedCategory));
    dispatch(
      searchEventsPerPage(
        undefined,
        selectedCategory.id,
        undefined,
        undefined,
        sortedBy,
        timezone,
        dateRange
      )
    );
    redirectToMainRoute(history, undefined, eventId, undefined);
  };

  const reverseDateFormat = (date: string) => {
    return date.split("-").reverse().join("-");
  };

  const resetValueDates = () => {
    dispatch(changeShowEventList(true));
    dispatch(
      searchEventsPerPage(
        searchValue,
        selectedCategory.id,
        undefined,
        undefined,
        sortedBy,
        timezone,
        undefined
      )
    );
    redirectToMainRoute(history, undefined, eventId, undefined);
  };

  return (
    <>
      {searchValue && (
        <Chip
          color="primary"
          variant="search"
          label={searchValue}
          deleteIcon={
            <img
              src={closeIcon}
              alt="close icon"
              style={{ padding: "0.5rem" }}
            />
          }
          onDelete={() => resetValueSearch()}
          sx={{ mr: 1, mt: 1 }}
        />
      )}
      {dateRange?.fromDate && dateRange?.toDate && (
        <Chip
          color="primary"
          variant="search"
          deleteIcon={
            <img
              src={closeIcon}
              alt="close icon"
              style={{ padding: "0.5rem" }}
            />
          }
          label={`From ${reverseDateFormat(
            dateRange.fromDate
          )} To ${reverseDateFormat(dateRange.toDate)}`}
          onDelete={() => resetValueDates()}
          sx={{ mr: 1, mt: 1 }}
        />
      )}
    </>
  );
};

export default SearchChip;
