import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { persistConfig } from "./persistConfig";

import user from "./usersReducer";
import category from "./categoryReducer";
import event from "./eventReducer";
import error from "./errorReducer";
import navigation from "./navigationReducer";

const rootReducer = combineReducers({
  user,
  category,
  event,
  error,
  navigation,
});

export default persistReducer(persistConfig, rootReducer);
