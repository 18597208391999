import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import ErrorNotification from "../components/ErrorNotification/ErrorNotification";

import { isEmpty } from "../shared/utils-ts";
import { MEDIA_LIBRARY } from "../shared/constants";
import * as S from "../styled";
import { RootState } from "../store/store";

const Layout = ({ pageTitle, children }: LayoutProps) => {
  const globalError = useSelector(
    (state: RootState) => state.error.globalError
  );

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - ${MEDIA_LIBRARY}`}</title>
      </Helmet>
      <S.BaseFlexLayout justifyContent="space-between" alignItems="flex-start">
        {!isEmpty(globalError.status) && <ErrorNotification />}
        {children}
      </S.BaseFlexLayout>
    </>
  );
};

export interface LayoutProps {
  pageTitle: string;
  children?: any;
}
export default Layout;
