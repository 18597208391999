import styled from "styled-components";
import theme from "./theme";

import errorImg from "../assets/img/global-error.svg";

interface IFlexProps {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  background?: boolean;
  borderRadius?: string;
  backgroundColor?: string;
  display?: string;

  direction?: string;
  flex?: string;
  justifyContent?: string;
  alignItems?: string;

  isOpen?: boolean;
  open?: boolean;
  isResized?: boolean;
  isExpandedEvent?: boolean;
}
export const BaseFlex = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  flex: ${(props) => (props.flex ? props.flex : 1)};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  flex: 1;
  position: relative;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 50%;
    justify-content: flex-start;
  }
`;

export const BaseFlexEventName = styled(BaseFlex)`
  flex: 3;
  max-width: 40%;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 50%;
    padding-right: 0%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
  }
`;

export const BaseFlexEventNameDate = styled(BaseFlexEventName)`
  width: 100%;
`;

export const BaseFlexAction = styled(BaseFlex)`
  flex: 1;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 50%;
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const BaseFlexDate = styled(BaseFlex)`
  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 50%;
    justify-content: flex-end;
    align-items: flex-start;
    height: 50px;
  }
`;

export const BaseFlexDateDownloadFiles = styled(BaseFlexDate)`
  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 25%;
  }
`;
export const BaseFlexSizeDownloadFiles = styled(BaseFlexDate)`
  @media (max-width: ${theme.breakpointsWidth.small}) {
    flex: 1 0 25%;
  }
`;

export const BaseFlexLanguage = styled(BaseFlex)`
  justify-content: flex-start;
  transition: color 0.1s linear;
  &:hover {
    color: #ff5d1f;
    cursor: pointer;
    img {
      opacity: 1;
    }
  }
  img {
    opacity: 0;
    transition: opacity 0.1s linear;
  }
`;
export const BaseFlexTranscriptionStatus = styled(BaseFlex)`
  .MuiTypography-p1 {
    color: #7F7E7E;
  }
`;
export const BaseFlexLayout = styled(BaseFlex)`
  width: 100%;
  height: calc(100vh - 56px);

  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: calc(100vh - 56px);
  }
`;

export const FlexDiv = styled(BaseFlex) <IFlexProps>`
  width: ${(props) => (props.width ? props.width : 0)};
  height: ${(props) => (props.height ? props.height : 0)};
  padding: ${(props) => (props.padding ? props.padding : "1.6rem")};
  margin: ${(props) => (props.margin ? props.margin : "0rem")};
  background-color: ${(props) =>
    props.background ? theme.colors.gray10 : theme.colors.white};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
`;

export const DropdownFilterBlock = styled(FlexDiv)`
  flex: 0;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }

  @media (max-width: ${theme.breakpointsWidth.medium}),
    (max-height: ${theme.breakpointsHeight.medium}) {
    padding: 1rem;
  }
`;

export const StyleFlexDiv = styled(FlexDiv)`
  background-color: ${(props) => (props.background ? "F4F5F6" : "transparent")};
`;

export const StyleFlexBlock = styled(FlexDiv)`
  background-color: ${(props) => (props.background ? "F4F5F6" : "transparent")};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    justify-content: space-between;
  }
`;

export const FlexDivSignIn = styled(FlexDiv)`
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 162px);
  top: calc(50% - 210px);
  border-radius: 1rem;
  box-shadow: 0 1px 4px 0 ${theme.colors.blackRgba20};
`;

export const FlexDivLogOut = styled(BaseFlex)`
  position: absolute;
  width: auto;
  right: 20px;
  top: 45px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: ${theme.colors.white};
  padding: 16px;
`;

export const FlexDivLanguages = styled(FlexDivLogOut)`
  padding: 0;
  right: -12px;
  background-color: ${theme.colors.gray80};
  box-shadow: 0 1px 3px 0 ${theme.colors.gray30};
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 3;

  @media (max-width: ${theme.breakpointsWidth.big}) {
    right: -13px;
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    width: 100%;
    top: 63px;
    right: 0;
  }
`;

export const FlexDivLanguagesTranscript = styled(FlexDivLogOut)`
  padding: 0;
  right: 0;
  bottom: 0;
  top: inherit;
  width: 100%;
  transform: translate(0%, 100%);
  background-color: ${theme.colors.gray80};
  box-shadow: 0 1px 3px 0 ${theme.colors.gray30};
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 3;

  @media (max-width: ${theme.breakpointsWidth.big}) {
    right: -13px;
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    width: 100%;
    transform: translate(-6%, 100%);
  }
`;

export const FlexDropDownLang = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    width: 100%;
    padding-left: 0rem;
    align-items: flex-start;
  }
`;

export const FlexDropDownTranscription = styled(FlexDropDownLang)`
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    width: 100%;
    padding-left: 0rem;
    align-items: center;
  }
`;

export const FlexDivDropdownFilter = styled(FlexDivLanguages)`
  width: 130px;
  right: 2rem;
  border-radius: 6px;
  z-index: 9;
  padding: 0;
  overflow: hidden;

  @media (max-width: ${theme.breakpointsWidth.big}) {
    right: 14px;
  }
`;

export const LoaderDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background: gray;
  background-color: ${theme.colors.brownRgba10};
  position: relative;
`;

export const LoaderDivDownloadFiles = styled(LoaderDiv)`
  width: 100%;
  height: 300px;
`;

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
  height: 3.6rem;
  max-width: 1250px;
  margin: 0rem auto 1rem auto;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 40px;
  }
`;

export const EventsBase = styled.div<IFlexProps>`
  width: 100%;
  height: 8rem;
  box-shadow: inset 0 -1px 0 0 ${theme.colors.gray20};
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
`;

export const EventsTableHeader = styled(EventsBase)`
  height: 3.6rem;
  background-color: ${theme.colors.gray10};
  box-shadow: inset 0 -1px 0 0 ${theme.colors.gray20},
    inset 0 1px 0 0 ${theme.colors.gray20};
  padding: 0 2rem;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;

export const MobileNavigationBlock = styled(FlexDiv) <IFlexProps>`
  position: absolute;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  top: 0;
  z-index: 101;
  background-color: ${theme.colors.blackRgba20};

  @media (min-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;

export const MobileNavigation = styled(FlexDiv) <IFlexProps>`
  position: absolute;
  left: ${(props) => (props.isOpen ? "0" : "-305px")};
  top: 0;
  z-index: 101;
  transition: left 0.3s cubic-bezier(0.4, 1.58, 0.83, 0.67);

  @media (min-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;

export const MobileNavigationUser = styled(FlexDiv)`
  background-color: ${theme.colors.orange};
  flex: 0.5 !important;
`;

export const MobileNavigationAction = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 2rem;
`;

export const MobileLogoutBlock = styled(FlexDiv)`
  background-color: ${theme.colors.orange};
  flex: 0.5;
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  height: 0;
  flex: 1;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;

export const StyledBlockElement = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  height: 0;
  flex: 1;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
  }
`;

export const ErrorFlexDiv = styled(FlexDiv)`
  position: absolute;
  top: 0;
  left: 0;
  background: ${theme.colors.blackRgba30};
  z-index: 999;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    justify-content: center;
  }
`;

export const ErrorBlock = styled.div<IFlexProps>`
  position: relative;
  display: flex;
  flex: grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 60%;
  min-height: 300px;
  min-width: 300px;
  max-width: 900px;
  max-height: 550px;
  background-image: url(${errorImg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${theme.colors.white};

  @media (max-width: ${theme.breakpointsWidth.large}) {
    background-size: 80% 80%; 
  }
  @media (max-width: ${theme.breakpointsWidth.medium}) {
    width: 90%;
    background-size: 80% 80%; 
  }
  @media (max-width: ${theme.breakpointsWidth.medium}),
  (max-height: ${theme.breakpointsWidth.large}){
    background-size: 73% 80%; 
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    background-size: 85%; 
  }
`;

export const EventsTableBody = styled(FlexDiv)`
  position: relative;
  flex: none;
  background: #fff0;

  @media (max-width: ${theme.breakpointsWidth.medium}) {
    height: 100%;
  }
`;

export const DownloadFilesEventBody = styled(FlexDiv)`
  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 0;
    flex: 1;
  }
`;

export const LoadingBlock = styled(FlexDiv)`
  position: absolute !important;
  top: 0;
  left: 0;
  background-color: ${theme.colors.orangeRgba60};
`;

export const FlexDivEventInfo = styled(FlexDiv) <IFlexProps>`
  padding: 2rem;
  box-shadow: inset 0 -1px 0 0 ${theme.colors.gray20};
  flex-wrap: wrap;
  background-color: ${(props) =>
    props.isExpandedEvent ? theme.colors.orange : theme.colors.white}};

  @media (max-width: ${theme.breakpointsWidth.big}) {
    padding: 1rem 2rem;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    box-shadow: none;
  }
`;

export const DownloadEventTableHeader = styled(EventsTableHeader)`
  border-radius: 4px;
  background-color: ${theme.colors.gray30};
  background: inherit;
  box-shadow: none;
  font-size: 2.4rem;
  font-family: "Sora";
  padding: 0;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
`;
export const TableHeaderName = styled(BaseFlexEventName)``;

export const FlexDivEventFileInfo = styled(FlexDiv)`
  margin: 4px 0;
  border-radius: 12px;
  padding: 2.4rem;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 150px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FlexTable = styled(FlexDiv)`
  // box-shadow: 0 4px 10px 0 ${theme.colors.blackRgba20};
  background-color: inherit;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    box-shadow: none;
    height: 0;
    flex: 1;
  }
`;

export const RecordingInstanceList = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.white};
  overflow: visible;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    padding: 0;
    overflow-y: auto;
  }
`;

export const MobileBlockCategories = styled(FlexDiv)``;

interface IMediaProps {
  type: string;
}

export const MediaPlayer = styled.div<IMediaProps>`
  width: 98%;
  ${(props) =>
    props.type === "audio"
      ? `
  padding-top: 5px;
  margin-top:0px;
  .video-js {
    font-size: 14px;
    color: ${theme.colors.gray120};
  }
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  .video-js.vjs-9-16,
  .video-js.vjs-1-1 {
    width: 100%;
    max-width: 100%;
    height: 0;
    padding: 20px;
    margin-top: 100px;
    background: transparent;
  }
  .vjs-default-skin .vjs-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    border: 0.06666em solid ${theme.colors.white};
    border-radius: 0.3em;
    /* Align top left. 0.5em = 15px default */
    left: 0.5em;
    top: 0.5em;
  }

  .video-js .vjs-control-bar,
  .video-js .vjs-big-play-button,
  .video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: ${theme.colors.orangeRgba60};
    display: none;
  }

  /* Slider - used for Volume bar and Progress bar */

  .video-js .vjs-volume-level,
  .video-js .vjs-play-progress,
  .video-js .vjs-slider-bar {
    background: ${theme.colors.orange};
    
  }

  .video-js .vjs-volume-panel.vjs-volume-panel-vertical {
    order: 3;
  }

  .video-js .vjs-load-progress {
    background: ${theme.colors.gray30};
  }

  .video-js .vjs-load-progress div {
    background: white;
    background: ${theme.colors.orangeRgba60};
  }
  .my_video_1-dimensions.vjs-fluid {
    padding-top: 0;
    margin-top: 100px;
  }

  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
  }
  .video-js .vjs-volume-control {
    display: block;
    width: 62px;
    bottom: 100px;
    left: -3em !important;
    background-color: ${theme.colors.gray20};
    height: 7em !important;
    width: 2.8em !important;
  }
  span.vjs-seek-to-live-text {
    display: none;
  }
  .video-js .vjs-fullscreen-control {
    display: none;
  }
  .vjs-playback-rate .vjs-playback-rate-value {
    display: none;
  }
  button.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge {
    width: auto;
  }
  .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
    width: auto;
  }
  button.vjs-picture-in-picture-control.vjs-control.vjs-button {
    width: auto;
    display: none;
  }
  .vjs-remaining-time.vjs-time-control.vjs-control {
    padding: 0;
  }
  .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
    position: absolute;
    right: 63px;
  }
  .video-js .vjs-control-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 440px;
    margin: 0 auto;
    border: 1px solid ${theme.colors.orange};
    background-color: transparent;
  }
  .video-js .vjs-control:before {
    color: ${theme.colors.grayRgba30};
  }
  .video-js .vjs-slider {
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 2px;
    flex-grow: 1;
    background-color: ${theme.colors.gray30};
  }
  .video-js .vjs-progress-control:hover .vjs-progress-holder {
    font-size:inherit !important;
  }

  .video-js .vjs-play-progress:before {
    font-size: 1.2rem;
    color: ${theme.colors.orange};
    position: absolute;
    pointer-events: all;
  }
  .video-js .vjs-progress-control:hover .vjs-time-tooltip,
  .video-js .vjs-progress-control:hover .vjs-mouse-display:after,
  .video-js .vjs-progress-control:hover .vjs-play-progress:after {
    display: inherit;
    font-family: Arial, Helvetica, sans-serif;
  }
  .video-js .vjs-time-tooltip,
  .video-js .vjs-mouse-display:after,
  .video-js .vjs-play-progress:after {
    position: absolute;
    top: -0.75rem;
    right: -1.9em;
    font-size: 0.9em;
    color: ${theme.colors.black};
    content: attr(data-current-time);
    background-color: transparent;
    visibility: visible !important;
    right: 0 !important;
  }
  .video-js .vjs-progress-control:hover .vjs-mouse-display {
    display: none;
  }
  .video-js .vjs-time-tooltip {
    left: -64px !important;
    right: auto !important;
    color: ${theme.colors.gray120};
    font-size: 14px !important;
    top: -10px;
  }
  .video-js .vjs-progress-control {
    padding-left: 36px;
    min-width: 7rem;
  }
  .vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-horizontal {
    position: relative;
    top: -40px;
    right: -32px;
    width: 50px;
    z-index: 1;
  }

  .vjs-tech{
    display:none;
  }


  .vjs-loading-spinner {
    top: -7px;
    left: 3px;
    margin: auto;
    scale: 0.6;
  }

  `
      : `
  .video-js .vjs-big-play-button {
    top: 40%;
    left: 40%;
  }

  `}
`;
