import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../assets/img/MagnifyGlass.svg";
import { debounce } from "../../shared/utils-ts";
import { ALL_EVENTS_ID, MOBILE_BREAKPOINT } from "../../shared/constants";

import {
  changeClearDate,
  changeShowEventList,
  searchEventsPerPage,
} from "../../store/actions/eventAction";
import { RootState } from "../../store/store";
import {
  Stack,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import DatePicker from "../../shared/DateRangePicker/DateRangePicker";
import palette from "../../material-ui/theme/colors";

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [dimensions, setDimensions] = useState({ width: window.innerWidth });
  const selectedCategory = useSelector(
    (state: RootState) => state.category.selectedCategory
  );
  const isSelectedCategory = useSelector(
    (state: RootState) => state.category.isSelectedCategory
  );
  const sortedBy = useSelector((state: RootState) => state.event.sortedBy);
  const timezone = useSelector((state: RootState) => state.event.timezone);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({ width: window.innerWidth });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [dimensions]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleDateRangeUpdate = ({ fromDate, toDate }: any) => {
    setDateRange({ fromDate, toDate });
    setTimeout(() => {
      buttonRef?.current?.focus();
    }, 50);
  };

  const setSearchValue = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(changeEvent.target.value);
  };

  const onKeyUp = (event: any) => {
    if (
      event.charCode === 13 &&
      ((inputValue.length > 0 && inputValue.trim() !== "") ||
        dateRange.fromDate !== "")
    ) {
      searchEvents();
    } else if (event.charCode === 13 && inputValue.trim() === "") {
      setInputValue("");
    }
  };

  function searchEvents() {
    let categorySelectedId = setSelectedCategoryId(
      { width: String(dimensions) },
      isSelectedCategory,
      ALL_EVENTS_ID
    );
    const dates = {
      fromDate: dateRange.fromDate.split("/").reverse().join("-"),
      toDate: dateRange.toDate.split("/").reverse().join("-"),
    };
    dispatch(changeShowEventList(true));
    dispatch(
      searchEventsPerPage(
        inputValue.trim(),
        categorySelectedId,
        undefined,
        undefined,
        sortedBy,
        timezone,
        dates
      )
    );
    dispatch(changeClearDate(true));
    setInputValue("");
    setDateRange({ fromDate: "", toDate: "" });
  }

  function setSelectedCategoryId(
    dimensions: { width: string },
    isSelectedCategory: boolean,
    ALL_EVENTS_ID: string
  ) {
    switch (true) {
      case dimensions.width < MOBILE_BREAKPOINT && isSelectedCategory:
        return selectedCategory.id;
      case dimensions.width < MOBILE_BREAKPOINT:
        return ALL_EVENTS_ID;
      case dimensions.width > MOBILE_BREAKPOINT:
        return selectedCategory.id;
      default:
        return selectedCategory.id;
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width="100%"
    >
      <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-weight"
          value={inputValue}
          onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(changeEvent)
          }
          startAdornment={<img src={searchIcon} alt="search icon" />}
          aria-describedby="outlined-weight-helper-text"
          placeholder={t("searchBar.searchSpeaker")}
          color="secondary"
          inputProps={{
            "aria-label": "search event",
            "data-testid": "search-input",
            style: {
              fontSize: "16px",
              paddingLeft: 0.5,
              color: palette.grey[100],
              paddingTop: 12.5,
              paddingBottom: 12.5,
            },
          }}
          sx={{
            pl: 1.5,
            "& input": {
              "&::placeholder": {
                color: palette.grey[80],
                opacity: 1,
              },
            },
          }}
          onKeyPress={onKeyUp}
          autoFocus
        />
      </FormControl>
      <DatePicker
        handleDateChange={(fromDate, toDate) =>
          handleDateRangeUpdate({ fromDate, toDate })
        }
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        ref={buttonRef}
        onClick={() => {
          if (
            (inputValue.length > 0 && inputValue.trim() !== "") ||
            (dateRange.fromDate !== "" && dateRange.toDate !== "")
          ) {
            searchEvents();
          } else {
            setInputValue("");
          }
        }}
        disabled={!inputValue && !dateRange.fromDate && !dateRange.toDate}
      >
        <Typography variant="p1_bold" align="center">
          {t("searchBar.search")}
        </Typography>
      </Button>
    </Stack>
  );
};

export default SearchBar;
