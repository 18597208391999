import "../../assets/fonts/stylesheet.css";
import colors from "./colors";
// ----------------------------------------------------------------------

function pxToRem(value: number) {
  return `${value / 10}rem`
}

const FONT_PRIMARY = "Poppins"; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: pxToRem(56),
    fontSize: pxToRem(40),
    fontFamily: "SoraBold",
  },
  h2: {
    fontWeight: 600,
    lineHeight: pxToRem(40),
    fontSize: pxToRem(32),
    fontFamily: "SoraSemiBold",
  },
  h3: {
    fontWeight: 600,
    lineHeight: pxToRem(32),
    fontSize: pxToRem(24),
    fontFamily: "SoraSemiBold",
  },
  h4: {
    fontWeight: 700,
    lineHeight: pxToRem(16),
    fontSize: pxToRem(20),
    fontFamily: "SoraBold",
    textTransform: "uppercase",
  },
  h5: {
    fontWeight: 700,
    lineHeight: pxToRem(16),
    fontSize: pxToRem(12),
    fontFamily: "SoraBold",
    textTransform: "uppercase",
    color: colors.grey[80]
  },
  p1: {
    fontWeight: 400,
    lineHeight: pxToRem(24),
    fontFamily: "Poppins",
    fontSize: pxToRem(16),
  },
  p2: {
    fontWeight: 400,
    lineHeight: pxToRem(20),
    fontFamily: "Poppins",
    fontSize: pxToRem(14),
  },
  p3: {
    fontWeight: 400,
    lineHeight: pxToRem(16),
    fontFamily: "Poppins",
    fontSize: pxToRem(12),
  },
  p1_bold: {
    fontWeight: 600,
    lineHeight: pxToRem(24),
    fontFamily: "PoppinsSemiBold",
    fontSize: pxToRem(16),
  },
  p2_bold: {
    fontWeight: 600,
    lineHeight: pxToRem(20),
    fontFamily: "PoppinsSemiBold",
    fontSize: pxToRem(14),
  },
  p3_bold: {
    fontWeight: 600,
    lineHeight: pxToRem(16),
    fontFamily: "PoppinsSemiBold",
    fontSize: pxToRem(12),
  },
  p1_grey: {
    fontFamily: "Poppins",
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24),
    color: colors.grey[80],
    marginTop: '0.5rem !important'
  },
  caption: {
    fontWeight: 400,
    lineHeight: pxToRem(24),
    fontSize: pxToRem(16),
    fontFamily: "Poppins",
    color: colors.grey[80]
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    // textTransform: "capitalize",
    textTransform: "none"
  },
} as const;

export default typography;
