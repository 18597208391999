import Keycloak from "keycloak-js";

import { persistor } from "../store/store";
import { logout } from "../store/actions/userAction";
import { isNotEmptyString } from "./utils-ts";

// @ts-ignore
let env = `${window.REACT_APP_DOMAIN}`;
// eslint-disable-next-line
let regexSubdomain = new RegExp(`^https:\/\/(?<subdomain>.*).${env}`);
let host = window.location.href;

export let subdomain =
  host.includes("localhost") || !host.match(regexSubdomain)
    ? ""
    // @ts-ignore
    : host.match(regexSubdomain).groups.subdomain;
// @ts-ignore
const DEFAULT_REALM_NAME = window.DEFAULT_REALM_NAME;
export const getOrganizationDetails = async (subdomain: string) => {
  let organizationName;

  if (isNotEmptyString(subdomain)) {
    let response;

    response = await fetch(
      // @ts-ignore
      `${window.REACT_APP_API_CORE}/api/admin/users/partners/domain/info/${subdomain}`
    ).catch((reason) => {
      console.log(reason);
    });

    if (!response || !response.ok) {
      // @ts-ignore
      const redirectToDefaultOrganization = `https://${window.REACT_APP_DOMAIN}`;
      window.location.replace(`${redirectToDefaultOrganization}`);
    }
    // @ts-ignore
    const responseJson = await response.json();
    organizationName = responseJson.data.name;
  } else {
    organizationName = DEFAULT_REALM_NAME;
  }

  let realm = setRealm(organizationName);
  let keycloakOptions = {
    // @ts-ignore
    url: `${window.REACT_APP_KEYCLOAK_URL}`,
    realm: `${realm}`,
    clientId: "medialibrary-ui",
    onLoad: "login-required",
  };
  let keycloak = Keycloak(keycloakOptions);
  return { keycloak, keycloakOptions };
};

export const keycloakSetup = getOrganizationDetails(subdomain);

export function setRealm(organizationName: string) {
  if (isNotEmptyString(organizationName)) {
    return organizationName;
  } else {
    return DEFAULT_REALM_NAME;
  }
}

export const logoutUser = async (keycloak: Keycloak.KeycloakInstance, dispatch: any) => {
  await persistor.purge();
  await dispatch(logout());
  localStorage.clear();
  keycloak.logout();
};
