import { useEffect } from "react";

import Loader from "../Loader/Loader";

const SignIn = ({ onLogin }: SignInProps) => {
  useEffect(() => {
    onLogin();
    // eslint-disable-next-line
  }, []);

  return <Loader />;
};
export interface SignInProps {
  onLogin: Function;
}
export default SignIn;
