export const languages = [
  {
    id: 32,
    shortCode: "multi",
    locale: "multi",
    value: "Multiple floor languages",
  },
  { id: 33, shortCode: "other", locale: "other", value: "Other language" },
  { id: 1, shortCode: "ARA", locale: "ar", value: "Arabic" },
  { id: 2, shortCode: "BUL", locale: "bg", value: "Bulgarian" },
  { id: 34, shortCode: "YUE", locale: "cmn", value: "Cantonese" },
  { id: 3, shortCode: "CAT", locale: "ca", value: "Catalan" },
  { id: 4, shortCode: "HRV", locale: "hr", value: "Croatian" },
  { id: 5, shortCode: "CES", locale: "cs", value: "Czech" },
  { id: 6, shortCode: "DAN", locale: "da", value: "Danish" },
  { id: 7, shortCode: "NLD", locale: "nl", value: "Dutch" },
  { id: 8, shortCode: "ENG", locale: "en", value: "English" },
  { id: 9, shortCode: "FIN", locale: "fi", value: "Finnish" },
  { id: 10, shortCode: "FRA", locale: "fr", value: "French" },
  { id: 11, shortCode: "DEU", locale: "de", value: "German" },
  { id: 12, shortCode: "ELL", locale: "el", value: "Greek" },
  { id: 13, shortCode: "HIN", locale: "hi", value: "Hindi" },
  { id: 14, shortCode: "HUN", locale: "hu", value: "Hungarian" },
  { id: 15, shortCode: "ITA", locale: "it", value: "Italian" },
  { id: 16, shortCode: "JPN", locale: "ja", value: "Japanese" },
  { id: 17, shortCode: "KOR", locale: "ko", value: "Korean" },
  { id: 18, shortCode: "LAV", locale: "lv", value: "Latvian" },
  { id: 19, shortCode: "LIT", locale: "lt", value: "Lithuanian" },
  { id: 20, shortCode: "MSA", locale: "ms", value: "Malay" },
  { id: 21, shortCode: "CMN", locale: "cmn", value: "Mandarin" },
  { id: 22, shortCode: "NOR", locale: "no", value: "Norwegian" },
  { id: 23, shortCode: "POL", locale: "pl", value: "Polish" },
  { id: 24, shortCode: "POR", locale: "pt", value: "Portuguese" },
  { id: 25, shortCode: "RON", locale: "ro", value: "Romanian" },
  { id: 26, shortCode: "RUS", locale: "ru", value: "Russian" },
  { id: 27, shortCode: "SLK", locale: "sk", value: "Slovak" },
  { id: 28, shortCode: "SLV", locale: "sl", value: "Slovenian" },
  { id: 29, shortCode: "SPA", locale: "es", value: "Spanish" },
  { id: 30, shortCode: "SWE", locale: "sv", value: "Swedish" },
  { id: 31, shortCode: "TUR", locale: "tr", value: "Turkish" },
  { id: 35, shortCode: "AFR", locale: "af", value: "Afrikaans" },
  { id: 36, shortCode: "AMH", locale: "am", value: "Amharic" },
  { id: 37, shortCode: "AZE", locale: "az", value: "Azerbaijani" },
  { id: 38, shortCode: "BEN", locale: "bn", value: "Bengali" },
  { id: 39, shortCode: "BOS", locale: "bs", value: "Bosnian" },
  { id: 40, shortCode: "TGL", locale: "fil", value: "Filipino" },
  { id: 41, shortCode: "FRC", locale: "fr-CA", value: "French (Canada)" },
  { id: 42, shortCode: "GLE", locale: "ga", value: "Irish" },
  { id: 43, shortCode: "GUJ", locale: "gu", value: "Gujarati" },
  { id: 44, shortCode: "HYE", locale: "hy", value: "Armenian" },
  { id: 45, shortCode: "ISL", locale: "is", value: "Icelandic" },
  { id: 46, shortCode: "JAV", locale: "jv", value: "Javanese" },
  { id: 47, shortCode: "KAT", locale: "ka", value: "Georgian" },
  { id: 48, shortCode: "KAZ", locale: "kk", value: "Kazakh" },
  { id: 49, shortCode: "KHM", locale: "km", value: "Khmer" },
  { id: 50, shortCode: "KAN", locale: "kn", value: "Kannada" },
  { id: 51, shortCode: "LAO", locale: "lo", value: "Lao" },
  { id: 52, shortCode: "MKD", locale: "mk", value: "Macedonian" },
  { id: 53, shortCode: "MAL", locale: "ml", value: "Malayalam" },
  { id: 54, shortCode: "MLT", locale: "mt", value: "Maltese" },
  { id: 55, shortCode: "MYA", locale: "my", value: "Burmese" },
  { id: 56, shortCode: "NEP", locale: "ne", value: "Nepali" },
  { id: 57, shortCode: "PUS", locale: "ps", value: "Pashto" },
  { id: 58, shortCode: "PTB", locale: "pt-BR", value: "Portuguese (Brazil)" },
  { id: 59, shortCode: "SIN", locale: "si", value: "Sinhala" },
  { id: 60, shortCode: "SOM", locale: "so", value: "Somali" },
  { id: 61, shortCode: "SPM", locale: "es-MX", value: "Spanish (LATAM)" },
  { id: 62, shortCode: "SQI", locale: "sq", value: "Albanian" },
  { id: 63, shortCode: "SRP", locale: "sr", value: "Serbian" },
  { id: 64, shortCode: "SWA", locale: "sw", value: "Swahili" },
  { id: 65, shortCode: "TEL", locale: "te", value: "Telugu" },
  { id: 66, shortCode: "UZB", locale: "uz", value: "Uzbek" },
  { id: 67, shortCode: "TWM", locale: "zh-TW", value: "Chinese (Taiwan Mandarin)" },
  { id: 68, shortCode: "URD", locale: "ur", value: "Urdu" },
  { id: 69, shortCode: "DEC", locale: "de-CH", value: "German (Switzerland)" },
  { id: 70, shortCode: "NLB", locale: "nl-BE", value: "Dutch (Belgium)" },
  { id: 71, shortCode: "BAK", locale: "ba", value: "Bashkir" },
  { id: 72, shortCode: "EUS", locale: "eu", value: "Basque" },
  { id: 73, shortCode: "BEL", locale: "be", value: "Belarusian" },
  { id: 74, shortCode: "EPO", locale: "eo", value: "Esperanto" },
  { id: 75, shortCode: "EST", locale: "et", value: "Estonian" },
  { id: 76, shortCode: "GLG", locale: "gl", value: "Galician" },
  { id: 77, shortCode: "HEB", locale: "he", value: "Hebrew" },
  { id: 78, shortCode: "INA", locale: "ia", value: "Interlingua" },
  { id: 79, shortCode: "IND", locale: "id", value: "Indonesian" },
  { id: 80, shortCode: "MAR", locale: "mr", value: "Marathi" },
  { id: 81, shortCode: "MON", locale: "mn", value: "Mongolian" },
  { id: 82, shortCode: "FAS", locale: "fa", value: "Persian" },
  { id: 83, shortCode: "TAM", locale: "ta", value: "Tamil" },
  { id: 84, shortCode: "THA", locale: "th", value: "Thai" },
  { id: 85, shortCode: "UIG", locale: "ug", value: "Uyghur" },
  { id: 86, shortCode: "UKR", locale: "uk", value: "Ukrainian" },
  { id: 87, shortCode: "VIE", locale: "vi", value: "Vietnamese" },
  { id: 88, shortCode: "CYM", locale: "cy", value: "Welsh" }
];

export const LanguageISO639: {
  alpha3_t: string;
  English: string
}[] = [
    {
      "alpha3_t": "aar",
      "English": "Afar"
    },
    {
      "alpha3_t": "abk",
      "English": "Abkhazian"
    },
    {
      "alpha3_t": "ace",
      "English": "Achinese"
    },
    {
      "alpha3_t": "ach",
      "English": "Acoli"
    },
    {
      "alpha3_t": "ada",
      "English": "Adangme"
    },
    {
      "alpha3_t": "ady",
      "English": "Adyghe; Adygei"
    },
    {
      "alpha3_t": "afa",
      "English": "Afro-Asiatic languages"
    },
    {
      "alpha3_t": "afh",
      "English": "Afrihili"
    },
    {
      "alpha3_t": "afr",
      "English": "Afrikaans"
    },
    {
      "alpha3_t": "ain",
      "English": "Ainu"
    },
    {
      "alpha3_t": "aka",
      "English": "Akan"
    },
    {
      "alpha3_t": "akk",
      "English": "Akkadian"
    },
    {
      "alpha3_t": "sqi",
      "English": "Albanian"
    },
    {
      "alpha3_t": "ale",
      "English": "Aleut"
    },
    {
      "alpha3_t": "alg",
      "English": "Algonquian languages"
    },
    {
      "alpha3_t": "alt",
      "English": "Southern Altai"
    },
    {
      "alpha3_t": "amh",
      "English": "Amharic"
    },
    {
      "alpha3_t": "ang",
      "English": "English, Old (ca.450-1100)"
    },
    {
      "alpha3_t": "anp",
      "English": "Angika"
    },
    {
      "alpha3_t": "apa",
      "English": "Apache languages"
    },
    {
      "alpha3_t": "ara",
      "English": "Arabic"
    },
    {
      "alpha3_t": "arc",
      "English": "Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)"
    },
    {
      "alpha3_t": "arg",
      "English": "Aragonese"
    },
    {
      "alpha3_t": "hye",
      "English": "Armenian"
    },
    {
      "alpha3_t": "arn",
      "English": "Mapudungun; Mapuche"
    },
    {
      "alpha3_t": "arp",
      "English": "Arapaho"
    },
    {
      "alpha3_t": "art",
      "English": "Artificial languages"
    },
    {
      "alpha3_t": "arw",
      "English": "Arawak"
    },
    {
      "alpha3_t": "asm",
      "English": "Assamese"
    },
    {
      "alpha3_t": "ast",
      "English": "Asturian; Bable; Leonese; Asturleonese"
    },
    {
      "alpha3_t": "ath",
      "English": "Athapascan languages"
    },
    {
      "alpha3_t": "aus",
      "English": "Australian languages"
    },
    {
      "alpha3_t": "ava",
      "English": "Avaric"
    },
    {
      "alpha3_t": "ave",
      "English": "Avestan"
    },
    {
      "alpha3_t": "awa",
      "English": "Awadhi"
    },
    {
      "alpha3_t": "aym",
      "English": "Aymara"
    },
    {
      "alpha3_t": "aze",
      "English": "Azerbaijani"
    },
    {
      "alpha3_t": "bad",
      "English": "Banda languages"
    },
    {
      "alpha3_t": "bai",
      "English": "Bamileke languages"
    },
    {
      "alpha3_t": "bak",
      "English": "Bashkir"
    },
    {
      "alpha3_t": "bal",
      "English": "Baluchi"
    },
    {
      "alpha3_t": "bam",
      "English": "Bambara"
    },
    {
      "alpha3_t": "ban",
      "English": "Balinese"
    },
    {
      "alpha3_t": "eus",
      "English": "Basque"
    },
    {
      "alpha3_t": "bas",
      "English": "Basa"
    },
    {
      "alpha3_t": "bat",
      "English": "Baltic languages"
    },
    {
      "alpha3_t": "bej",
      "English": "Beja; Bedawiyet"
    },
    {
      "alpha3_t": "bel",
      "English": "Belarusian"
    },
    {
      "alpha3_t": "bem",
      "English": "Bemba"
    },
    {
      "alpha3_t": "ben",
      "English": "Bengali"
    },
    {
      "alpha3_t": "ber",
      "English": "Berber languages"
    },
    {
      "alpha3_t": "bho",
      "English": "Bhojpuri"
    },
    {
      "alpha3_t": "bih",
      "English": "Bihari languages"
    },
    {
      "alpha3_t": "bik",
      "English": "Bikol"
    },
    {
      "alpha3_t": "bin",
      "English": "Bini; Edo"
    },
    {
      "alpha3_t": "bis",
      "English": "Bislama"
    },
    {
      "alpha3_t": "bla",
      "English": "Siksika"
    },
    {
      "alpha3_t": "bnt",
      "English": "Bantu languages"
    },
    {
      "alpha3_t": "bos",
      "English": "Bosnian"
    },
    {
      "alpha3_t": "bra",
      "English": "Braj"
    },
    {
      "alpha3_t": "bre",
      "English": "Breton"
    },
    {
      "alpha3_t": "btk",
      "English": "Batak languages"
    },
    {
      "alpha3_t": "bua",
      "English": "Buriat"
    },
    {
      "alpha3_t": "bug",
      "English": "Buginese"
    },
    {
      "alpha3_t": "bul",
      "English": "Bulgarian"
    },
    {
      "alpha3_t": "mya",
      "English": "Burmese"
    },
    {
      "alpha3_t": "byn",
      "English": "Blin; Bilin"
    },
    {
      "alpha3_t": "cad",
      "English": "Caddo"
    },
    {
      "alpha3_t": "cai",
      "English": "Central American Indian languages"
    },
    {
      "alpha3_t": "car",
      "English": "Galibi Carib"
    },
    {
      "alpha3_t": "cat",
      "English": "Catalan; Valencian"
    },
    {
      "alpha3_t": "cau",
      "English": "Caucasian languages"
    },
    {
      "alpha3_t": "ceb",
      "English": "Cebuano"
    },
    {
      "alpha3_t": "cel",
      "English": "Celtic languages"
    },
    {
      "alpha3_t": "cha",
      "English": "Chamorro"
    },
    {
      "alpha3_t": "chb",
      "English": "Chibcha"
    },
    {
      "alpha3_t": "che",
      "English": "Chechen"
    },
    {
      "alpha3_t": "chg",
      "English": "Chagatai"
    },
    {
      "alpha3_t": "zho",
      "English": "Chinese"
    },
    {
      "alpha3_t": "chk",
      "English": "Chuukese"
    },
    {
      "alpha3_t": "chm",
      "English": "Mari"
    },
    {
      "alpha3_t": "chn",
      "English": "Chinook jargon"
    },
    {
      "alpha3_t": "cho",
      "English": "Choctaw"
    },
    {
      "alpha3_t": "chp",
      "English": "Chipewyan; Dene Suline"
    },
    {
      "alpha3_t": "chr",
      "English": "Cherokee"
    },
    {
      "alpha3_t": "chu",
      "English": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
    },
    {
      "alpha3_t": "chv",
      "English": "Chuvash"
    },
    {
      "alpha3_t": "chy",
      "English": "Cheyenne"
    },
    {
      "alpha3_t": "cmc",
      "English": "Chamic languages"
    },
    {
      "alpha3_t": "cnr",
      "English": "Montenegrin"
    },
    {
      "alpha3_t": "cop",
      "English": "Coptic"
    },
    {
      "alpha3_t": "cor",
      "English": "Cornish"
    },
    {
      "alpha3_t": "cos",
      "English": "Corsican"
    },
    {
      "alpha3_t": "cpe",
      "English": "Creoles and pidgins, English based"
    },
    {
      "alpha3_t": "cpf",
      "English": "Creoles and pidgins, French-based"
    },
    {
      "alpha3_t": "cpp",
      "English": "Creoles and pidgins, Portuguese-based"
    },
    {
      "alpha3_t": "cre",
      "English": "Cree"
    },
    {
      "alpha3_t": "crh",
      "English": "Crimean Tatar; Crimean Turkish"
    },
    {
      "alpha3_t": "crp",
      "English": "Creoles and pidgins"
    },
    {
      "alpha3_t": "csb",
      "English": "Kashubian"
    },
    {
      "alpha3_t": "cus",
      "English": "Cushitic languages"
    },
    {
      "alpha3_t": "ces",
      "English": "Czech"
    },
    {
      "alpha3_t": "dak",
      "English": "Dakota"
    },
    {
      "alpha3_t": "dan",
      "English": "Danish"
    },
    {
      "alpha3_t": "dar",
      "English": "Dargwa"
    },
    {
      "alpha3_t": "day",
      "English": "Land Dayak languages"
    },
    {
      "alpha3_t": "del",
      "English": "Delaware"
    },
    {
      "alpha3_t": "den",
      "English": "Slave (Athapascan)"
    },
    {
      "alpha3_t": "dgr",
      "English": "Dogrib"
    },
    {
      "alpha3_t": "din",
      "English": "Dinka"
    },
    {
      "alpha3_t": "div",
      "English": "Divehi; Dhivehi; Maldivian"
    },
    {
      "alpha3_t": "doi",
      "English": "Dogri"
    },
    {
      "alpha3_t": "dra",
      "English": "Dravidian languages"
    },
    {
      "alpha3_t": "dsb",
      "English": "Lower Sorbian"
    },
    {
      "alpha3_t": "dua",
      "English": "Duala"
    },
    {
      "alpha3_t": "dum",
      "English": "Dutch, Middle (ca.1050-1350)"
    },
    {
      "alpha3_t": "nld",
      "English": "Dutch; Flemish"
    },
    {
      "alpha3_t": "dyu",
      "English": "Dyula"
    },
    {
      "alpha3_t": "dzo",
      "English": "Dzongkha"
    },
    {
      "alpha3_t": "efi",
      "English": "Efik"
    },
    {
      "alpha3_t": "egy",
      "English": "Egyptian (Ancient)"
    },
    {
      "alpha3_t": "eka",
      "English": "Ekajuk"
    },
    {
      "alpha3_t": "elx",
      "English": "Elamite"
    },
    {
      "alpha3_t": "eng",
      "English": "English"
    },
    {
      "alpha3_t": "enm",
      "English": "English, Middle (1100-1500)"
    },
    {
      "alpha3_t": "epo",
      "English": "Esperanto"
    },
    {
      "alpha3_t": "est",
      "English": "Estonian"
    },
    {
      "alpha3_t": "ewe",
      "English": "Ewe"
    },
    {
      "alpha3_t": "ewo",
      "English": "Ewondo"
    },
    {
      "alpha3_t": "fan",
      "English": "Fang"
    },
    {
      "alpha3_t": "fao",
      "English": "Faroese"
    },
    {
      "alpha3_t": "fat",
      "English": "Fanti"
    },
    {
      "alpha3_t": "fij",
      "English": "Fijian"
    },
    {
      "alpha3_t": "fil",
      "English": "Filipino; Pilipino"
    },
    {
      "alpha3_t": "fin",
      "English": "Finnish"
    },
    {
      "alpha3_t": "fiu",
      "English": "Finno-Ugrian languages"
    },
    {
      "alpha3_t": "fon",
      "English": "Fon"
    },
    {
      "alpha3_t": "fra",
      "English": "French"
    },
    {
      "alpha3_t": "frc",
      "English": "French Canadian"
    },
    {
      "alpha3_t": "frm",
      "English": "French, Middle (ca.1400-1600)"
    },
    {
      "alpha3_t": "fro",
      "English": "French, Old (842-ca.1400)"
    },
    {
      "alpha3_t": "frr",
      "English": "Northern Frisian"
    },
    {
      "alpha3_t": "frs",
      "English": "Eastern Frisian"
    },
    {
      "alpha3_t": "fry",
      "English": "Western Frisian"
    },
    {
      "alpha3_t": "ful",
      "English": "Fulah"
    },
    {
      "alpha3_t": "fur",
      "English": "Friulian"
    },
    {
      "alpha3_t": "gaa",
      "English": "Ga"
    },
    {
      "alpha3_t": "gay",
      "English": "Gayo"
    },
    {
      "alpha3_t": "gba",
      "English": "Gbaya"
    },
    {
      "alpha3_t": "gem",
      "English": "Germanic languages"
    },
    {
      "alpha3_t": "kat",
      "English": "Georgian"
    },
    {
      "alpha3_t": "deu",
      "English": "German"
    },
    {
      "alpha3_t": "gez",
      "English": "Geez"
    },
    {
      "alpha3_t": "gil",
      "English": "Gilbertese"
    },
    {
      "alpha3_t": "gla",
      "English": "Gaelic; Scottish Gaelic"
    },
    {
      "alpha3_t": "gle",
      "English": "Irish"
    },
    {
      "alpha3_t": "glg",
      "English": "Galician"
    },
    {
      "alpha3_t": "glv",
      "English": "Manx"
    },
    {
      "alpha3_t": "gmh",
      "English": "German, Middle High (ca.1050-1500)"
    },
    {
      "alpha3_t": "goh",
      "English": "German, Old High (ca.750-1050)"
    },
    {
      "alpha3_t": "gon",
      "English": "Gondi"
    },
    {
      "alpha3_t": "gor",
      "English": "Gorontalo"
    },
    {
      "alpha3_t": "got",
      "English": "Gothic"
    },
    {
      "alpha3_t": "grb",
      "English": "Grebo"
    },
    {
      "alpha3_t": "grc",
      "English": "Greek, Ancient (to 1453)"
    },
    {
      "alpha3_t": "ell",
      "English": "Greek, Modern (1453-)"
    },
    {
      "alpha3_t": "grn",
      "English": "Guarani"
    },
    {
      "alpha3_t": "gsw",
      "English": "Swiss German; Alemannic; Alsatian"
    },
    {
      "alpha3_t": "guj",
      "English": "Gujarati"
    },
    {
      "alpha3_t": "gwi",
      "English": "Gwich'in"
    },
    {
      "alpha3_t": "hai",
      "English": "Haida"
    },
    {
      "alpha3_t": "hat",
      "English": "Haitian; Haitian Creole"
    },
    {
      "alpha3_t": "hau",
      "English": "Hausa"
    },
    {
      "alpha3_t": "haw",
      "English": "Hawaiian"
    },
    {
      "alpha3_t": "heb",
      "English": "Hebrew"
    },
    {
      "alpha3_t": "her",
      "English": "Herero"
    },
    {
      "alpha3_t": "hil",
      "English": "Hiligaynon"
    },
    {
      "alpha3_t": "him",
      "English": "Himachali languages; Western Pahari languages"
    },
    {
      "alpha3_t": "hin",
      "English": "Hindi"
    },
    {
      "alpha3_t": "hit",
      "English": "Hittite"
    },
    {
      "alpha3_t": "hmn",
      "English": "Hmong; Mong"
    },
    {
      "alpha3_t": "hmo",
      "English": "Hiri Motu"
    },
    {
      "alpha3_t": "hrv",
      "English": "Croatian"
    },
    {
      "alpha3_t": "hsb",
      "English": "Upper Sorbian"
    },
    {
      "alpha3_t": "hun",
      "English": "Hungarian"
    },
    {
      "alpha3_t": "hup",
      "English": "Hupa"
    },
    {
      "alpha3_t": "iba",
      "English": "Iban"
    },
    {
      "alpha3_t": "ibo",
      "English": "Igbo"
    },
    {
      "alpha3_t": "isl",
      "English": "Icelandic"
    },
    {
      "alpha3_t": "ido",
      "English": "Ido"
    },
    {
      "alpha3_t": "iii",
      "English": "Sichuan Yi; Nuosu"
    },
    {
      "alpha3_t": "ijo",
      "English": "Ijo languages"
    },
    {
      "alpha3_t": "iku",
      "English": "Inuktitut"
    },
    {
      "alpha3_t": "ile",
      "English": "Interlingue; Occidental"
    },
    {
      "alpha3_t": "ilo",
      "English": "Iloko"
    },
    {
      "alpha3_t": "ina",
      "English": "Interlingua (International Auxiliary Language Association)"
    },
    {
      "alpha3_t": "inc",
      "English": "Indic languages"
    },
    {
      "alpha3_t": "ind",
      "English": "Indonesian"
    },
    {
      "alpha3_t": "ine",
      "English": "Indo-European languages"
    },
    {
      "alpha3_t": "inh",
      "English": "Ingush"
    },
    {
      "alpha3_t": "ipk",
      "English": "Inupiaq"
    },
    {
      "alpha3_t": "ira",
      "English": "Iranian languages"
    },
    {
      "alpha3_t": "iro",
      "English": "Iroquoian languages"
    },
    {
      "alpha3_t": "ita",
      "English": "Italian"
    },
    {
      "alpha3_t": "jav",
      "English": "Javanese"
    },
    {
      "alpha3_t": "jbo",
      "English": "Lojban"
    },
    {
      "alpha3_t": "jpn",
      "English": "Japanese"
    },
    {
      "alpha3_t": "jpr",
      "English": "Judeo-Persian"
    },
    {
      "alpha3_t": "jrb",
      "English": "Judeo-Arabic"
    },
    {
      "alpha3_t": "kaa",
      "English": "Kara-Kalpak"
    },
    {
      "alpha3_t": "kab",
      "English": "Kabyle"
    },
    {
      "alpha3_t": "kac",
      "English": "Kachin; Jingpho"
    },
    {
      "alpha3_t": "kal",
      "English": "Kalaallisut; Greenlandic"
    },
    {
      "alpha3_t": "kam",
      "English": "Kamba"
    },
    {
      "alpha3_t": "kan",
      "English": "Kannada"
    },
    {
      "alpha3_t": "kar",
      "English": "Karen languages"
    },
    {
      "alpha3_t": "kas",
      "English": "Kashmiri"
    },
    {
      "alpha3_t": "kau",
      "English": "Kanuri"
    },
    {
      "alpha3_t": "kaw",
      "English": "Kawi"
    },
    {
      "alpha3_t": "kaz",
      "English": "Kazakh"
    },
    {
      "alpha3_t": "kbd",
      "English": "Kabardian"
    },
    {
      "alpha3_t": "kha",
      "English": "Khasi"
    },
    {
      "alpha3_t": "khi",
      "English": "Khoisan languages"
    },
    {
      "alpha3_t": "khm",
      "English": "Central Khmer"
    },
    {
      "alpha3_t": "kho",
      "English": "Khotanese; Sakan"
    },
    {
      "alpha3_t": "kik",
      "English": "Kikuyu; Gikuyu"
    },
    {
      "alpha3_t": "kin",
      "English": "Kinyarwanda"
    },
    {
      "alpha3_t": "kir",
      "English": "Kirghiz; Kyrgyz"
    },
    {
      "alpha3_t": "kmb",
      "English": "Kimbundu"
    },
    {
      "alpha3_t": "kok",
      "English": "Konkani"
    },
    {
      "alpha3_t": "kom",
      "English": "Komi"
    },
    {
      "alpha3_t": "kon",
      "English": "Kongo"
    },
    {
      "alpha3_t": "kor",
      "English": "Korean"
    },
    {
      "alpha3_t": "kos",
      "English": "Kosraean"
    },
    {
      "alpha3_t": "kpe",
      "English": "Kpelle"
    },
    {
      "alpha3_t": "krc",
      "English": "Karachay-Balkar"
    },
    {
      "alpha3_t": "krl",
      "English": "Karelian"
    },
    {
      "alpha3_t": "kro",
      "English": "Kru languages"
    },
    {
      "alpha3_t": "kru",
      "English": "Kurukh"
    },
    {
      "alpha3_t": "kua",
      "English": "Kuanyama; Kwanyama"
    },
    {
      "alpha3_t": "kum",
      "English": "Kumyk"
    },
    {
      "alpha3_t": "kur",
      "English": "Kurdish"
    },
    {
      "alpha3_t": "kut",
      "English": "Kutenai"
    },
    {
      "alpha3_t": "lad",
      "English": "Ladino"
    },
    {
      "alpha3_t": "lah",
      "English": "Lahnda"
    },
    {
      "alpha3_t": "lam",
      "English": "Lamba"
    },
    {
      "alpha3_t": "lao",
      "English": "Lao"
    },
    {
      "alpha3_t": "lat",
      "English": "Latin"
    },
    {
      "alpha3_t": "lav",
      "English": "Latvian"
    },
    {
      "alpha3_t": "lez",
      "English": "Lezghian"
    },
    {
      "alpha3_t": "lim",
      "English": "Limburgan; Limburger; Limburgish"
    },
    {
      "alpha3_t": "lin",
      "English": "Lingala"
    },
    {
      "alpha3_t": "lit",
      "English": "Lithuanian"
    },
    {
      "alpha3_t": "lol",
      "English": "Mongo"
    },
    {
      "alpha3_t": "loz",
      "English": "Lozi"
    },
    {
      "alpha3_t": "ltz",
      "English": "Luxembourgish; Letzeburgesch"
    },
    {
      "alpha3_t": "lua",
      "English": "Luba-Lulua"
    },
    {
      "alpha3_t": "lub",
      "English": "Luba-Katanga"
    },
    {
      "alpha3_t": "lug",
      "English": "Ganda"
    },
    {
      "alpha3_t": "lui",
      "English": "Luiseno"
    },
    {
      "alpha3_t": "lun",
      "English": "Lunda"
    },
    {
      "alpha3_t": "luo",
      "English": "Luo (Kenya and Tanzania)"
    },
    {
      "alpha3_t": "lus",
      "English": "Lushai"
    },
    {
      "alpha3_t": "mkd",
      "English": "Macedonian"
    },
    {
      "alpha3_t": "mad",
      "English": "Madurese"
    },
    {
      "alpha3_t": "mag",
      "English": "Magahi"
    },
    {
      "alpha3_t": "mah",
      "English": "Marshallese"
    },
    {
      "alpha3_t": "mai",
      "English": "Maithili"
    },
    {
      "alpha3_t": "mak",
      "English": "Makasar"
    },
    {
      "alpha3_t": "mal",
      "English": "Malayalam"
    },
    {
      "alpha3_t": "man",
      "English": "Mandingo"
    },
    {
      "alpha3_t": "mri",
      "English": "Maori"
    },
    {
      "alpha3_t": "map",
      "English": "Austronesian languages"
    },
    {
      "alpha3_t": "mar",
      "English": "Marathi"
    },
    {
      "alpha3_t": "mas",
      "English": "Masai"
    },
    {
      "alpha3_t": "msa",
      "English": "Malay"
    },
    {
      "alpha3_t": "mdf",
      "English": "Moksha"
    },
    {
      "alpha3_t": "mdr",
      "English": "Mandar"
    },
    {
      "alpha3_t": "men",
      "English": "Mende"
    },
    {
      "alpha3_t": "mga",
      "English": "Irish, Middle (900-1200)"
    },
    {
      "alpha3_t": "mic",
      "English": "Mi'kmaq; Micmac"
    },
    {
      "alpha3_t": "min",
      "English": "Minangkabau"
    },
    {
      "alpha3_t": "mis",
      "English": "Uncoded languages"
    },
    {
      "alpha3_t": "mkh",
      "English": "Mon-Khmer languages"
    },
    {
      "alpha3_t": "mlg",
      "English": "Malagasy"
    },
    {
      "alpha3_t": "mlt",
      "English": "Maltese"
    },
    {
      "alpha3_t": "mnc",
      "English": "Manchu"
    },
    {
      "alpha3_t": "mni",
      "English": "Manipuri"
    },
    {
      "alpha3_t": "mno",
      "English": "Manobo languages"
    },
    {
      "alpha3_t": "moh",
      "English": "Mohawk"
    },
    {
      "alpha3_t": "mon",
      "English": "Mongolian"
    },
    {
      "alpha3_t": "mos",
      "English": "Mossi"
    },
    {
      "alpha3_t": "mul",
      "English": "Multiple languages"
    },
    {
      "alpha3_t": "mun",
      "English": "Munda languages"
    },
    {
      "alpha3_t": "mus",
      "English": "Creek"
    },
    {
      "alpha3_t": "mwl",
      "English": "Mirandese"
    },
    {
      "alpha3_t": "mwr",
      "English": "Marwari"
    },
    {
      "alpha3_t": "myn",
      "English": "Mayan languages"
    },
    {
      "alpha3_t": "myv",
      "English": "Erzya"
    },
    {
      "alpha3_t": "nah",
      "English": "Nahuatl languages"
    },
    {
      "alpha3_t": "nai",
      "English": "North American Indian languages"
    },
    {
      "alpha3_t": "nap",
      "English": "Neapolitan"
    },
    {
      "alpha3_t": "nau",
      "English": "Nauru"
    },
    {
      "alpha3_t": "nav",
      "English": "Navajo; Navaho"
    },
    {
      "alpha3_t": "nbl",
      "English": "Ndebele, South; South Ndebele"
    },
    {
      "alpha3_t": "nde",
      "English": "Ndebele, North; North Ndebele"
    },
    {
      "alpha3_t": "ndo",
      "English": "Ndonga"
    },
    {
      "alpha3_t": "nds",
      "English": "Low German; Low Saxon; German, Low; Saxon, Low"
    },
    {
      "alpha3_t": "nep",
      "English": "Nepali"
    },
    {
      "alpha3_t": "new",
      "English": "Nepal Bhasa; Newari"
    },
    {
      "alpha3_t": "nia",
      "English": "Nias"
    },
    {
      "alpha3_t": "nic",
      "English": "Niger-Kordofanian languages"
    },
    {
      "alpha3_t": "niu",
      "English": "Niuean"
    },
    {
      "alpha3_t": "nno",
      "English": "Norwegian Nynorsk; Nynorsk, Norwegian"
    },
    {
      "alpha3_t": "nob",
      "English": "Bokmål, Norwegian; Norwegian Bokmål"
    },
    {
      "alpha3_t": "nog",
      "English": "Nogai"
    },
    {
      "alpha3_t": "non",
      "English": "Norse, Old"
    },
    {
      "alpha3_t": "nor",
      "English": "Norwegian"
    },
    {
      "alpha3_t": "nqo",
      "English": "N'Ko"
    },
    {
      "alpha3_t": "nso",
      "English": "Pedi; Sepedi; Northern Sotho"
    },
    {
      "alpha3_t": "nub",
      "English": "Nubian languages"
    },
    {
      "alpha3_t": "nwc",
      "English": "Classical Newari; Old Newari; Classical Nepal Bhasa"
    },
    {
      "alpha3_t": "nya",
      "English": "Chichewa; Chewa; Nyanja"
    },
    {
      "alpha3_t": "nym",
      "English": "Nyamwezi"
    },
    {
      "alpha3_t": "nyn",
      "English": "Nyankole"
    },
    {
      "alpha3_t": "nyo",
      "English": "Nyoro"
    },
    {
      "alpha3_t": "nzi",
      "English": "Nzima"
    },
    {
      "alpha3_t": "oci",
      "English": "Occitan (post 1500)"
    },
    {
      "alpha3_t": "oji",
      "English": "Ojibwa"
    },
    {
      "alpha3_t": "ori",
      "English": "Oriya"
    },
    {
      "alpha3_t": "orm",
      "English": "Oromo"
    },
    {
      "alpha3_t": "osa",
      "English": "Osage"
    },
    {
      "alpha3_t": "oss",
      "English": "Ossetian; Ossetic"
    },
    {
      "alpha3_t": "ota",
      "English": "Turkish, Ottoman (1500-1928)"
    },
    {
      "alpha3_t": "oto",
      "English": "Otomian languages"
    },
    {
      "alpha3_t": "paa",
      "English": "Papuan languages"
    },
    {
      "alpha3_t": "pag",
      "English": "Pangasinan"
    },
    {
      "alpha3_t": "pal",
      "English": "Pahlavi"
    },
    {
      "alpha3_t": "pam",
      "English": "Pampanga; Kapampangan"
    },
    {
      "alpha3_t": "pan",
      "English": "Panjabi; Punjabi"
    },
    {
      "alpha3_t": "pap",
      "English": "Papiamento"
    },
    {
      "alpha3_t": "pau",
      "English": "Palauan"
    },
    {
      "alpha3_t": "peo",
      "English": "Persian, Old (ca.600-400 B.C.)"
    },
    {
      "alpha3_t": "fas",
      "English": "Persian"
    },
    {
      "alpha3_t": "phi",
      "English": "Philippine languages"
    },
    {
      "alpha3_t": "phn",
      "English": "Phoenician"
    },
    {
      "alpha3_t": "pli",
      "English": "Pali"
    },
    {
      "alpha3_t": "pol",
      "English": "Polish"
    },
    {
      "alpha3_t": "pon",
      "English": "Pohnpeian"
    },
    {
      "alpha3_t": "por",
      "English": "Portuguese"
    },
    {
      "alpha3_t": "ptb",
      "English": "Brazilian Portuguese"
    },
    {
      "alpha3_t": "pra",
      "English": "Prakrit languages"
    },
    {
      "alpha3_t": "pro",
      "English": "Provençal, Old (to 1500); Occitan, Old (to 1500)"
    },
    {
      "alpha3_t": "pus",
      "English": "Pushto; Pashto"
    },
    {
      "alpha3_t": "qaa-qtz",
      "English": "Reserved for local use"
    },
    {
      "alpha3_t": "que",
      "English": "Quechua"
    },
    {
      "alpha3_t": "raj",
      "English": "Rajasthani"
    },
    {
      "alpha3_t": "rap",
      "English": "Rapanui"
    },
    {
      "alpha3_t": "rar",
      "English": "Rarotongan; Cook Islands Maori"
    },
    {
      "alpha3_t": "roa",
      "English": "Romance languages"
    },
    {
      "alpha3_t": "roh",
      "English": "Romansh"
    },
    {
      "alpha3_t": "rom",
      "English": "Romany"
    },
    {
      "alpha3_t": "ron",
      "English": "Romanian; Moldavian; Moldovan"
    },
    {
      "alpha3_t": "run",
      "English": "Rundi"
    },
    {
      "alpha3_t": "rup",
      "English": "Aromanian; Arumanian; Macedo-Romanian"
    },
    {
      "alpha3_t": "rus",
      "English": "Russian"
    },
    {
      "alpha3_t": "sad",
      "English": "Sandawe"
    },
    {
      "alpha3_t": "sag",
      "English": "Sango"
    },
    {
      "alpha3_t": "sah",
      "English": "Yakut"
    },
    {
      "alpha3_t": "sai",
      "English": "South American Indian languages"
    },
    {
      "alpha3_t": "sal",
      "English": "Salishan languages"
    },
    {
      "alpha3_t": "sam",
      "English": "Samaritan Aramaic"
    },
    {
      "alpha3_t": "san",
      "English": "Sanskrit"
    },
    {
      "alpha3_t": "sas",
      "English": "Sasak"
    },
    {
      "alpha3_t": "sat",
      "English": "Santali"
    },
    {
      "alpha3_t": "scn",
      "English": "Sicilian"
    },
    {
      "alpha3_t": "sco",
      "English": "Scots"
    },
    {
      "alpha3_t": "sel",
      "English": "Selkup"
    },
    {
      "alpha3_t": "sem",
      "English": "Semitic languages"
    },
    {
      "alpha3_t": "sga",
      "English": "Irish, Old (to 900)"
    },
    {
      "alpha3_t": "sgn",
      "English": "Sign Languages"
    },
    {
      "alpha3_t": "shn",
      "English": "Shan"
    },
    {
      "alpha3_t": "sid",
      "English": "Sidamo"
    },
    {
      "alpha3_t": "sin",
      "English": "Sinhala; Sinhalese"
    },
    {
      "alpha3_t": "sio",
      "English": "Siouan languages"
    },
    {
      "alpha3_t": "sit",
      "English": "Sino-Tibetan languages"
    },
    {
      "alpha3_t": "sla",
      "English": "Slavic languages"
    },
    {
      "alpha3_t": "slk",
      "English": "Slovak"
    },
    {
      "alpha3_t": "slv",
      "English": "Slovenian"
    },
    {
      "alpha3_t": "sma",
      "English": "Southern Sami"
    },
    {
      "alpha3_t": "sme",
      "English": "Northern Sami"
    },
    {
      "alpha3_t": "smi",
      "English": "Sami languages"
    },
    {
      "alpha3_t": "smj",
      "English": "Lule Sami"
    },
    {
      "alpha3_t": "smn",
      "English": "Inari Sami"
    },
    {
      "alpha3_t": "smo",
      "English": "Samoan"
    },
    {
      "alpha3_t": "sms",
      "English": "Skolt Sami"
    },
    {
      "alpha3_t": "sna",
      "English": "Shona"
    },
    {
      "alpha3_t": "snd",
      "English": "Sindhi"
    },
    {
      "alpha3_t": "snk",
      "English": "Soninke"
    },
    {
      "alpha3_t": "sog",
      "English": "Sogdian"
    },
    {
      "alpha3_t": "som",
      "English": "Somali"
    },
    {
      "alpha3_t": "son",
      "English": "Songhai languages"
    },
    {
      "alpha3_t": "sot",
      "English": "Sotho, Southern"
    },
    {
      "alpha3_t": "spa",
      "English": "Spanish; Castilian"
    },
    {
      "alpha3_t": "spm",
      "English": "Mexican Spanish"
    },
    {
      "alpha3_t": "srd",
      "English": "Sardinian"
    },
    {
      "alpha3_t": "srn",
      "English": "Sranan Tongo"
    },
    {
      "alpha3_t": "srp",
      "English": "Serbian"
    },
    {
      "alpha3_t": "srr",
      "English": "Serer"
    },
    {
      "alpha3_t": "ssa",
      "English": "Nilo-Saharan languages"
    },
    {
      "alpha3_t": "ssw",
      "English": "Swati"
    },
    {
      "alpha3_t": "suk",
      "English": "Sukuma"
    },
    {
      "alpha3_t": "sun",
      "English": "Sundanese"
    },
    {
      "alpha3_t": "sus",
      "English": "Susu"
    },
    {
      "alpha3_t": "sux",
      "English": "Sumerian"
    },
    {
      "alpha3_t": "swa",
      "English": "Swahili"
    },
    {
      "alpha3_t": "swe",
      "English": "Swedish"
    },
    {
      "alpha3_t": "syc",
      "English": "Classical Syriac"
    },
    {
      "alpha3_t": "syr",
      "English": "Syriac"
    },
    {
      "alpha3_t": "tah",
      "English": "Tahitian"
    },
    {
      "alpha3_t": "tai",
      "English": "Tai languages"
    },
    {
      "alpha3_t": "tam",
      "English": "Tamil"
    },
    {
      "alpha3_t": "tat",
      "English": "Tatar"
    },
    {
      "alpha3_t": "tel",
      "English": "Telugu"
    },
    {
      "alpha3_t": "tem",
      "English": "Timne"
    },
    {
      "alpha3_t": "ter",
      "English": "Tereno"
    },
    {
      "alpha3_t": "tet",
      "English": "Tetum"
    },
    {
      "alpha3_t": "tgk",
      "English": "Tajik"
    },
    {
      "alpha3_t": "tgl",
      "English": "Tagalog"
    },
    {
      "alpha3_t": "tha",
      "English": "Thai"
    },
    {
      "alpha3_t": "bod",
      "English": "Tibetan"
    },
    {
      "alpha3_t": "tig",
      "English": "Tigre"
    },
    {
      "alpha3_t": "tir",
      "English": "Tigrinya"
    },
    {
      "alpha3_t": "tiv",
      "English": "Tiv"
    },
    {
      "alpha3_t": "tkl",
      "English": "Tokelau"
    },
    {
      "alpha3_t": "tlh",
      "English": "Klingon; tlhIngan-Hol"
    },
    {
      "alpha3_t": "tli",
      "English": "Tlingit"
    },
    {
      "alpha3_t": "tmh",
      "English": "Tamashek"
    },
    {
      "alpha3_t": "tog",
      "English": "Tonga (Nyasa)"
    },
    {
      "alpha3_t": "ton",
      "English": "Tonga (Tonga Islands)"
    },
    {
      "alpha3_t": "tpi",
      "English": "Tok Pisin"
    },
    {
      "alpha3_t": "tsi",
      "English": "Tsimshian"
    },
    {
      "alpha3_t": "tsn",
      "English": "Tswana"
    },
    {
      "alpha3_t": "tso",
      "English": "Tsonga"
    },
    {
      "alpha3_t": "tuk",
      "English": "Turkmen"
    },
    {
      "alpha3_t": "tum",
      "English": "Tumbuka"
    },
    {
      "alpha3_t": "tup",
      "English": "Tupi languages"
    },
    {
      "alpha3_t": "tur",
      "English": "Turkish"
    },
    {
      "alpha3_t": "tut",
      "English": "Altaic languages"
    },
    {
      "alpha3_t": "tvl",
      "English": "Tuvalu"
    },
    {
      "alpha3_t": "twi",
      "English": "Twi"
    },
    {
      "alpha3_t": "tyv",
      "English": "Tuvinian"
    },
    {
      "alpha3_t": "udm",
      "English": "Udmurt"
    },
    {
      "alpha3_t": "uga",
      "English": "Ugaritic"
    },
    {
      "alpha3_t": "uig",
      "English": "Uighur; Uyghur"
    },
    {
      "alpha3_t": "ukr",
      "English": "Ukrainian"
    },
    {
      "alpha3_t": "umb",
      "English": "Umbundu"
    },
    {
      "alpha3_t": "und",
      "English": "Undetermined"
    },
    {
      "alpha3_t": "urd",
      "English": "Urdu"
    },
    {
      "alpha3_t": "uzb",
      "English": "Uzbek"
    },
    {
      "alpha3_t": "vai",
      "English": "Vai"
    },
    {
      "alpha3_t": "ven",
      "English": "Venda"
    },
    {
      "alpha3_t": "vie",
      "English": "Vietnamese"
    },
    {
      "alpha3_t": "vol",
      "English": "Volapük"
    },
    {
      "alpha3_t": "vot",
      "English": "Votic"
    },
    {
      "alpha3_t": "wak",
      "English": "Wakashan languages"
    },
    {
      "alpha3_t": "wal",
      "English": "Wolaitta; Wolaytta"
    },
    {
      "alpha3_t": "war",
      "English": "Waray"
    },
    {
      "alpha3_t": "was",
      "English": "Washo"
    },
    {
      "alpha3_t": "cym",
      "English": "Welsh"
    },
    {
      "alpha3_t": "wen",
      "English": "Sorbian languages"
    },
    {
      "alpha3_t": "wln",
      "English": "Walloon"
    },
    {
      "alpha3_t": "wol",
      "English": "Wolof"
    },
    {
      "alpha3_t": "xal",
      "English": "Kalmyk; Oirat"
    },
    {
      "alpha3_t": "xho",
      "English": "Xhosa"
    },
    {
      "alpha3_t": "yao",
      "English": "Yao"
    },
    {
      "alpha3_t": "yap",
      "English": "Yapese"
    },
    {
      "alpha3_t": "yid",
      "English": "Yiddish"
    },
    {
      "alpha3_t": "yor",
      "English": "Yoruba"
    },
    {
      "alpha3_t": "ypk",
      "English": "Yupik languages"
    },
    {
      "alpha3_t": "zap",
      "English": "Zapotec"
    },
    {
      "alpha3_t": "zbl",
      "English": "Blissymbols; Blissymbolics; Bliss"
    },
    {
      "alpha3_t": "zen",
      "English": "Zenaga"
    },
    {
      "alpha3_t": "zgh",
      "English": "Standard Moroccan Tamazight"
    },
    {
      "alpha3_t": "zha",
      "English": "Zhuang; Chuang"
    },
    {
      "alpha3_t": "znd",
      "English": "Zande languages"
    },
    {
      "alpha3_t": "zul",
      "English": "Zulu"
    },
    {
      "alpha3_t": "zun",
      "English": "Zuni"
    },
    {
      "alpha3_t": "zxx",
      "English": "No linguistic content; Not applicable"
    },
    {
      "alpha3_t": "zza",
      "English": "Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki"
    }
  ];
export function DecodeLanguageShortCode(langCode: string | null | undefined) {
  if (typeof langCode !== "string") return "Unknown";
  if (langCode === "SRC" || langCode === "") return "Floor";
  return LanguageISO639.find(language => language.alpha3_t === langCode.toLowerCase())?.English || langCode;
}
