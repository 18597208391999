import styled from "styled-components";
import theme from "./theme";

interface IButtonProps {
  downloading?: boolean;
  isResized?: boolean;
  isDisabled?: boolean;
}

export const BtnOrange = styled.button`
  box-sizing: border-box;
  height: 42px;
  width: 142px;
  border: 2px solid ${theme.colors.orange};
  border-radius: 40px;
  color: ${theme.colors.orange};
  text-transform: uppercase;
  margin: 5rem auto;
`;

export const ConfirmBtn = styled.button`
  box-sizing: border-box;
  height: 40px;
  width: 104px;
  background: ${theme.colors.orange};
  border-radius: 4px;
  color: ${theme.colors.white};
  margin: 1rem auto;
  &:disabled {
    background: ${theme.colors.orangeRgba60};
  }
`;

export const CancelBtn = styled(ConfirmBtn)`
  background: ${theme.colors.gray20};
  color: ${theme.colors.black};
  margin-right: 1rem;
`;
export const GenerateBtn = styled.button`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1.2rem 2.5rem;
`;
export const UserBtnNavigation = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  position: relative;
  color: ${theme.colors.gray70};
  font-family: ${theme.fonts.roboto};
  font-size: 1.2rem;
  line-height: 1.9rem;
  text-align: right;
  padding: 0.5rem;
  transition: 0.2s ease-in-out;
  &:hover {
    color: ${theme.colors.orange};
  }
`;

export const LanguagesBtnNavigation = styled(UserBtnNavigation)`
  width: 110px;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid ${theme.colors.gray30};
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 1rem;
    color: ${theme.colors.gray60};
  }
`;

export const LanguagesBtnDropDown = styled(LanguagesBtnNavigation)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E0E3E5;
  border-radius: 4px;
  border: none;
  margin-top: 15px;
  width: 100%
`;

export const OrganizationAcessButton = styled(LanguagesBtnNavigation)``;

export const ChooseLanguageBtn = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1.5rem;
  font-family: ${theme.fonts.openSans};
  font-size: 1.2rem;
  font-weigt: 600;
  background-color: ${theme.colors.white};
  transition: 0.2s ease-in-out;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.colors.orange};
    color: ${theme.colors.white};
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    padding-left: 2rem;
  }
`;

export const DropdownFilterButton = styled(ChooseLanguageBtn)`
  height: 35px;
`;

export const EventBtn = styled.button`
  width: 16.5rem;
  height: 40px;
  border: 2px solid ${theme.colors.gray40};
  border-radius: 4px;
  background-color: ${theme.colors.white};
  text-transform: uppercase;
  font-family: ${theme.fonts.montserratMedium};
  font-size: 1.2rem;
  font-weight: 500;
  color: ${theme.colors.gray50};
  transition: 0.2s ease-in-out;
  &:hover {
    color: ${theme.colors.orange};
    border: 2px solid ${theme.colors.orange};
  }

  @media (max-width: ${theme.breakpointsWidth.large}) {
    width: 13rem;
  }
`;

export const ToggleDownloadFilesButton = styled(EventBtn) <IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  position: relative;
  margin-right: 1.5rem;
  cursor: cursor !important;
  background-color: ${theme.colors.white};
  box-shadow: 0 4px 6px 0 ${theme.colors.blackRgba20} !important;
  color: ${theme.colors.orange};
  min-width: 120px;

  &:hover {
    color: ${(props) =>
    props.isDisabled
      ? `${theme.colors.gray30} !important`
      : `${theme.colors.orange} `};
  }
  &:active {
    color: ${theme.colors.gray30} !important;
    border: 1px solid
      ${(props) =>
    props.isDisabled
      ? `${theme.colors.gray30} !important`
      : `${theme.colors.orange} !important`};
  }

  @media (max-width: ${theme.breakpointsWidth.large}) {
    width: 18rem;
    padding: 1rem 1rem 1rem 2rem;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
    justify-content: space-around;
    align-items: ${(props) => (props.isDisabled ? "center" : "space-around")};
    width: 10rem;
    margin-right: 0;
    height: 40px;
    width: 170px;
    color: ${theme.colors.orange};
    font-weight: 600;
    font-size: 1.3rem;
    letter-spacing: 1px;
  }
`;

export const DropdownFilterBtn = styled.button`
  padding: 0.5rem 1rem;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: flex-start;
`;

export const LogoutBtn = styled.button`
  padding: 0.5rem 1rem;
  padding-left: 0;
  margin-left: -5px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.openSans};
  font-size: 1.4rem;
  color: ${theme.colors.gray60};
  background-color: ${theme.colors.white};
`;

export const CloseMobMenuBtn = styled.button`
  height: 14px;
`;

export const BackBtn = styled.button`
  width: 130px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 1rem;
  font-family: ${theme.fonts.openSans};
  font-size: 1.4rem;
  font-weight: 600;
  color: ${theme.colors.gray60};
  background-color: ${theme.colors.gray10};
  display: none;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
  }
`;
export const BackButton = styled.button`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.poppins};
  color: ${theme.colors.gray110};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none !important;
  }
`

export const SearchButton = styled.button`
  cursor: default;
  position: absolute;
  height: 33px;
  width: 40px;
  top: 8px;
  right: 1px;
  @media (max-width: ${theme.breakpointsWidth.big}) {
    height: 28px;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 38px;
  }
`;

export const ChooseOrganizationButton = styled(ChooseLanguageBtn)`
  width: 225px;
  height: 100%;
`;

// export const EventActionsButton = styled.button`
//   display: none !important;
//   @media (max-width: ${theme.breakpointsWidth.big}) {
//     position: relative;
//     height: 4rem;
//     border-radius: 4px;
//     margin-right: 10px;
//     display: flex !important;
//     justify-content: center;
//     align-items: center;
//   }
//   @media (max-width: ${theme.breakpointsWidth.small}) {
//     display: none !important;
//   }
// `;
export const PreviewFileButton = styled.button`
  font-family: ${theme.fonts.poppins};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  display: flex;
  justify-content: center;
  &:hover {
    color: #FF5D1F;
  }
`;

export const DownlodFileButton = styled.button<IButtonProps>`
  position: relative;
  width: 165px;
  height: 37px;
  cursor: pointer;
  font-family: ${theme.fonts.openSans};
  color: ${(props) =>
    props.downloading ? `${theme.colors.gray20}` : `${theme.colors.orange}`};
  border: 1px solid ${theme.colors.orange};
  border-radius: 25px;
  font-size: ${(props) => (props.downloading ? "1.1rem" : "1.3rem")};
  text-transform: uppercase;
  padding: 5px 10px;

  @media (max-width: ${theme.breakpointsWidth.big}) {
    height: 35px;
    width: 160px;
  }
  @media (max-width: ${theme.breakpointsWidth.medium}) {
    height: 35px;
    width: 140px;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    width: 150px;
    font-weight: 600;
    border: 2px solid ${theme.colors.orange};
  }
`;
