import { PURGE } from "redux-persist";
import {
  addCategoryHelperFunc,
  addIsOpenHelperFunc,
  deleteCategoryHelperFunc,
  preserveCategoryOpen,
  renameCategoryHelperFunc,
  decrementLevelNestedByOne,
  setSelectedCategory,
} from "../../shared/utils-category";
import { findItemNested } from "../../shared/utils-category"
import { ALL_EVENTS_OBJECT } from "../../shared/constants";

import {
  GET_CATEGORIES,
  GET_CATEGORY,
  ADD_CATEGORY,
  RENAME_CATEGORY,
  MOVE_CATEGORY,
  DELETE_CATEGORY,
  RESIZE_SIDEBAR,
  TOGGLE_MOVE_TO_CATEGORY_MODAL,
  TOGGLE_CATEGORY_ACTIONS,
  GO_BACK,
  EXPAND_CATEGORY,
  COLLAPSE_CATEGORY,
  SET_VISIBILITY_MOVE_TO_CATEGORY_MODAL,
  DISABLE_MOVE_TO_BUTTON,
  CHANGE_ORGANIZATION,
  GET_EVENT,
  ON_ERROR_REDIRECT,
} from "../actions/types";
import { ActionWithPayload } from "./eventReducer";
import { ICategory } from "../../models/categories";



export interface ICategoryState {
  categories: ICategory[];
  selectedCategory: ICategory;
  resizedSidebar: boolean;
  toggleMoveToCategoryModal: boolean;
  toggleMoveEventToCategoryModal: boolean;
  toggleCategoryActions: boolean;
  levelNested: number;
  parentIds: string[];
  isSelectedCategory: boolean;
  selectedCategoryWithSubCategoriesId: string[];
  disableMoveToButton: boolean;
}

const initialState: ICategoryState = {
  categories: [ALL_EVENTS_OBJECT],
  selectedCategory: ALL_EVENTS_OBJECT,
  resizedSidebar: true,
  toggleMoveToCategoryModal: false,
  toggleMoveEventToCategoryModal: false,
  toggleCategoryActions: false,
  levelNested: 0,
  parentIds: [],
  isSelectedCategory: false,
  selectedCategoryWithSubCategoriesId: [],
  disableMoveToButton: false,
};

export interface ICategoryPayload extends ICategoryState {
  data: any[];
  id: string;
  category: ICategory;
  newCategory: {};
  selectedCategoryWithSubCategoriesIdArray: string[];
  categoryId: string;
  categoryNewName: string;
  isSelectedCategory: boolean;
  moveToCategoryId: string;
  moveToParentId: string;
  isDisabled: boolean;
}

let newCategory = {};
// eslint-disable-next-line
export default (state: ICategoryState = initialState, action: ActionWithPayload<Partial<ICategoryPayload>>): ICategoryState => {
  switch (action.type) {
    case GET_CATEGORIES:
      let updatedAllCategories = {
        ...state.categories[0],
        //@ts-ignore
        subCategories: [...addIsOpenHelperFunc(action.payload.data)],
      };
      let allEventsSubCategoriesLength =
        state.categories[0].subCategories.length;
      return {
        ...state,
        categories: [updatedAllCategories],
        selectedCategory:
          allEventsSubCategoriesLength === 0
            ? updatedAllCategories
            : state.selectedCategory,
        levelNested: 0,
        parentIds: [],
        isSelectedCategory: false,
      };
    case GET_CATEGORY:
      let uniqueArrId = [...state.parentIds];
      uniqueArrId = uniqueArrId.concat((action.payload.category?.id)!);
      uniqueArrId = Array.from(new Set(uniqueArrId));
      let selectCategory = findItemNested(
        state.categories,
        action.payload.category?.id
      );
      let updatedLevelNest = state.levelNested + 1;
      return {
        ...state,
        selectedCategory: selectCategory,
        levelNested: updatedLevelNest,
        parentIds: uniqueArrId,
        isSelectedCategory: action.payload.isSelectedCategory ? true : false,
      };
    case CHANGE_ORGANIZATION:
      return {
        ...state,
        selectedCategory: ALL_EVENTS_OBJECT,
      };
    case ADD_CATEGORY:
      newCategory = action.payload.newCategory!;
      return {
        ...state,
        categories: addCategoryHelperFunc(
          state.categories,
          action.payload.category?.id,
          action.payload.newCategory
        ),
      };
    case RENAME_CATEGORY:
      const { categoryNewName } = action.payload;
      return {
        ...state,
        categories: renameCategoryHelperFunc(
          state.categories,
          action.payload.categoryId,
          categoryNewName
        ),
      };
    case MOVE_CATEGORY:
      // eslint-disable-next-line
      const { categoryId, moveToCategoryId, moveToParentId } = action.payload;
      newCategory = findItemNested(state.categories, categoryId);
      newCategory = {
        ...newCategory,
        parentId: moveToParentId,
      };
      let slicedCategories = deleteCategoryHelperFunc(
        state.categories,
        categoryId
      );
      let updatedCategories = addCategoryHelperFunc(
        slicedCategories,
        moveToCategoryId,
        newCategory
      );

      return {
        ...state,
        categories: addIsOpenHelperFunc(updatedCategories),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: deleteCategoryHelperFunc(
          state.categories,
          action.payload.id
        ),
      };
    case EXPAND_CATEGORY:
      let selectedCat = findItemNested(state.categories, action.payload.id);
      selectedCat.open = true;
      return {
        ...state,
        categories: preserveCategoryOpen(state.categories),
        selectedCategory: selectedCat,
      };
    case COLLAPSE_CATEGORY:
      let selectedCateg = findItemNested(state.categories, action.payload.id);
      selectedCateg.open = false;
      return {
        ...state,
        selectedCategory: selectedCateg,
        categories: preserveCategoryOpen(state.categories),
      };
    case RESIZE_SIDEBAR:
      return {
        ...state,
        resizedSidebar: !state.resizedSidebar,
        toggleMoveToCategoryModal: state.resizedSidebar && false,
        toggleCategoryActions: true,
        toggleMoveEventToCategoryModal: false,
      };
    case TOGGLE_MOVE_TO_CATEGORY_MODAL:
      return {
        ...state,
        toggleMoveToCategoryModal: !state.toggleMoveToCategoryModal,
        resizedSidebar: !state.resizedSidebar,
        disableMoveToButton: false,
      };
    case SET_VISIBILITY_MOVE_TO_CATEGORY_MODAL:
      return {
        ...state,
        toggleMoveEventToCategoryModal: true,
        resizedSidebar: false,
      };
    case TOGGLE_CATEGORY_ACTIONS:
      const { selectedCategoryWithSubCategoriesIdArray } = action.payload;
      return {
        ...state,
        toggleCategoryActions: !state.toggleCategoryActions,
        selectedCategory: action.payload.category
          ? findItemNested(state.categories, action.payload.category.id)
          : state.selectedCategory,
        //@ts-ignore
        selectedCategoryWithSubCategoriesId:
          selectedCategoryWithSubCategoriesIdArray === null
            ? state.selectedCategoryWithSubCategoriesId
            : selectedCategoryWithSubCategoriesIdArray,
      };

    case GO_BACK:
      let lastItemFromArr = state.parentIds.length;
      lastItemFromArr = lastItemFromArr - 1;
      let updatedLevelNested = decrementLevelNestedByOne(state.levelNested);

      return {
        ...state,
        selectedCategory: setSelectedCategory(
          state.levelNested,
          state.categories,
          action.payload.id
        ),
        levelNested: updatedLevelNested,
        parentIds: state.parentIds.filter(
          (category, index) => index !== lastItemFromArr
        ),
        isSelectedCategory: false,
      };
    case DISABLE_MOVE_TO_BUTTON:
      return {
        ...state,
        disableMoveToButton: action.payload.isDisabled || false,
      };
    case GET_EVENT:
      return {
        ...state,
        levelNested: 1,
      };
    case PURGE:
      return {
        ...state,
        categories: [ALL_EVENTS_OBJECT],
      };
    case ON_ERROR_REDIRECT:
      return {
        ...state,
        levelNested: 0,
      }
    default:
      return state;
  }
};
