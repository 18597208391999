import React from "react";
import { Alert } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

import palette from "../../material-ui/theme/colors";

const ValidationMessage = ({message}: ValidationMessageProps) => {
  return (
    <Alert
      severity="error"
      iconMapping={{
        error: <InfoIcon fontSize="inherit" sx={{ color: palette.red[100] }}/>,
      }}
      sx={{
        position: "absolute",
        bottom: "-24px",
        backgroundColor: "transparent",
        fontSize: "16px",
        lineHeight: "24px",
        color: palette.red[100],
        p: 0,
        display: "flex",
        "& .MuiAlert-icon": {
          mr: "4px",
          ml: "2px",
          display: "flex",
          alignItems: "center",
          p: 0
        },
        "& .MuiAlert-message": {
          p: 0
        }
      }}
    >
      {message}
    </Alert>
  );
};

export interface ValidationMessageProps {
  message: string;
}
export default ValidationMessage;
