import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export function redirectToMainRoute(
    history: any, organizationId: string | undefined = undefined,
    eventId: string | undefined = undefined,
    recordingInstanceId: string | undefined = undefined
) {
    if (organizationId || eventId || recordingInstanceId) {
        history?.push("/media-library");
    }
}
