import { PURGE } from "redux-persist";
import { setEnvironment, setUserData } from "../../shared/user-helper.utils";

import {
  SIGN_IN_USER,
  LOG_OUT,
  CHANGE_LANGUAGE,
  MOBILE_MENU_TOGGLE,
  CHANGE_ORGANIZATION,
  SET_DEFAULT_ORGANIZATION,
} from "../actions/types";
import { ActionWithPayload } from "./eventReducer";
import { ILanguage } from "../../components/Languages/Languages";
import { IOrganization } from "../../models/navigation";

export interface IUserState {
  data: {
    mainMediaLibraryOrganization?: any;
    organizations: { id: string, name: string }[];
    email: string;
  }
  language: ILanguage;
  isOpenMobileMenu: boolean;
  selectedOrganization: IOrganization;
  gotOrganizations: boolean;
  environment?: string;

}

export interface IUserPayload extends IUserState {
  id: string;
  name: string;
  mediaLibraryOrganizations: { id: number, name: string }[];
  email: string;
}

const initialState: IUserState = {
  data: {
    organizations: [{ id: "current", name: "Main organization" }],
    email: ""
  },
  language: { id: 1, locale: "en", value: "English" },
  isOpenMobileMenu: false,
  selectedOrganization: { id: "current", name: "Main organization" },
  gotOrganizations: false,
};

let baseUrl = `${(window as any).REACT_APP_API_URL}/organizations/${initialState.selectedOrganization.id}`;
// eslint-disable-next-line
export default (state: IUserState = initialState, action: ActionWithPayload<Partial<IUserPayload>>): IUserState => {
  switch (action.type) {
    case SIGN_IN_USER:
      return {
        ...state,
        data: setUserData(action.payload),
        gotOrganizations: true,
        environment: setEnvironment()
      };
    case LOG_OUT:
      // @ts-ignore
      return { ...state, data: {} };
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload.language!! };
    case CHANGE_ORGANIZATION:
      baseUrl = `${(window as any).REACT_APP_API_URL}/organizations/${action.payload.id}`;
      // @ts-ignore
      return { ...state, selectedOrganization: action.payload };
    case SET_DEFAULT_ORGANIZATION:
      const defaultOrganization = state.data.organizations[0];
      baseUrl = `${(window as any).REACT_APP_API_URL}/organizations/${defaultOrganization.id}`;
      return { ...state, selectedOrganization: defaultOrganization };
    case MOBILE_MENU_TOGGLE:
      return { ...state, isOpenMobileMenu: !state.isOpenMobileMenu };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
export { baseUrl };
