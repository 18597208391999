import React from "react";
import { useTranslation } from "react-i18next";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import palette from "../../material-ui/theme/colors";

const ConfirmDialog = ({
  onCancel,
  onConfirm,
  settings: {
    title = null,
    body = null,
    isConfirm = false,
    isCancelBtn = true,
    maxWidth = 'sm',
    isDelete = false
  },
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  const close = () => {
    onCancel();
  };

  const confirm = () => {
    onConfirm();
    onCancel();
  };

  return (
      <Dialog
        open={true}
        keepMounted
        onClose={close}
        fullWidth={true}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ display: 'flex', padding: '24px 24px 16px 32px' }}>
          {title && (
            <DialogTitle id="customized-dialog-title" style={{ flex: 1, padding: 0 }}>
              <Typography variant="h2" align="left">
                {t(title)}
              </Typography>
            </DialogTitle>
          )}
          <IconButton
            color="inherit"
            onClick={close}
            aria-label="close"
            style={{ width: '32px', height: '32px', backgroundColor: palette.grey[10] }}
          >
            <CloseIcon/>
          </IconButton>
        </div>
        {body && <DialogContent style={{padding: '0 32px 24px'}}>
          <DialogContentText id="alert-dialog-slide-description">
            {body}
          </DialogContentText>
        </DialogContent>
        }
        <DialogActions style={{padding: '0 32px 24px'}}>
          <Button
            variant="contained"
            color={isDelete ? 'error' : 'primary'}
            size="large"
            disableRipple
            onClick={confirm}
            disabled={!isConfirm}
          >
            <Typography variant="p1" align="center">
              {isDelete ? t("tooltip.delete") : t("confirmDialog.confirm")}
            </Typography>
          </Button>
          {isCancelBtn && (
            <Button
              variant="outlined"
              color="secondary"
              disableRipple
              onClick={close}
            >
              <Typography variant="p1" align="center">
                {t("confirmDialog.cancel")}
              </Typography>
            </Button>
          )}
        </DialogActions>
      </Dialog>
  );
};

export interface ConfirmDialogProps {
  onCancel: Function;
  onConfirm: Function;
  settings: {
    title: any;
    body: any;
    isConfirm: boolean;
    isCancelBtn?: boolean;
    maxWidth?: Breakpoint;
    isDelete?: boolean;
  };
}
export default ConfirmDialog;
