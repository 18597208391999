import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { languages } from "../../../../../shared/Language";

export interface ILanguage {
  id: number;
  shortCode: string;
  locale: string;
  value: string;
}

interface IlanguageSelect {
  setLanguage: Function;
  handleSelect: Function;
}
const LanguageSelect = (props: IlanguageSelect) => {
  const { t } = useTranslation();

  const [lang, setLang] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
    props.setLanguage(event.target.value as string);
    props.handleSelect(true);
  };

  return (
    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="medium">
      <Select
        value={lang}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem key={"empty"} value="" disabled>
          <Typography variant="p1_grey" align="left">
            {t("languages.selectLanguage")}
          </Typography>
        </MenuItem>
        {languages.map((lang) => (
          <MenuItem
            key={lang.shortCode}
            value={lang.shortCode}
            sx={{ padding: "1.2rem 1.6rem" }}
          >
            <Typography variant="p1" align="left">
              {lang.value}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
