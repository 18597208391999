import {
  RESET_GLOBAL_ERROR,
  SET_ERROR,
  RESET_ERROR,
  SET_GLOBAL_ERROR,
} from "./types";

import { logoutUser, keycloakSetup } from "../../shared/keycloack";
import { ERROR_WITH_SERVER } from "../../shared/constants";
import { Dispatch } from "redux";
import { AxiosError } from "axios";

export const resetGlobalError = () => async (dispatch: Dispatch) => {
  return dispatch({ type: RESET_GLOBAL_ERROR });
};

export const setTosterError = (errorDetails: string | null = null, errorMessage: string) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ERROR,
    payload: {
      errorMessage: errorMessage,
      errorDetails: errorDetails,
    },
  });

  return dispatch({ type: RESET_ERROR });
};

export const setGlobalError = async (error: AxiosError, dispatch: Dispatch) => {
  if (!!error) {
    if (!!error.response) {
      const { status, errorMessage, errorDetails } = error.response.data;
      if (status === 401 || error.response.status === 401) {
        const keycloakObject = await keycloakSetup;
        const { keycloak } = keycloakObject;
        logoutUser(keycloak, dispatch);
        return Promise.reject(error.response);
      }
      if (status === 404 || status === 403) {
        dispatch({
          type: SET_GLOBAL_ERROR,
          payload: error.response.data,
        });
        return Promise.reject(error.response);
      } else if (errorMessage) {
        setErrorMsg(errorDetails, dispatch, errorMessage);
        return Promise.reject(error.response);
      }
    }
    if (error.request) {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: {
          status: 500,
          message: ERROR_WITH_SERVER,
        },
      });
    }
  }
};

function setErrorMsg(errorDetails: { [s: string]: unknown; } | ArrayLike<unknown>, dispatch: Dispatch, errorMessage: string) {
  dispatch({
    type: SET_ERROR,
    payload: {
      errorMessage: errorMessage,
      errorDetails: errorDetails ? Object.values(errorDetails) : "",
    },
  });
  return dispatch({ type: RESET_ERROR });
}
