import { Theme } from "@mui/material/styles";
import palette from "../theme/colors";

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: palette.grey[0],
          
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.red[100]
          }
        },
      },
    },
  };
}
