import styled, { css, keyframes } from "styled-components";
import theme from "./theme";

export const MainLoader = styled.div<{ isDownloading: boolean }>`
  display: inline-block;
  position: absolute;
  top: ${(props) => (props.isDownloading ? "-1rem" : "50%")};
  right: ${(props) => (props.isDownloading ? "0.4rem" : "50%")};
  z-index: 9;
  width: ${(props) => (props.isDownloading ? "2.4rem" : "80px")};
  height:  ${(props) => (props.isDownloading ? "2.4rem" : "80px")};
  @media (max-width: ${theme.breakpointsWidth.medium}) {
    top: ${(props) => (props.isDownloading ? "6px" : "50%")};
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    top: ${(props) => (props.isDownloading ? "6px" : "50%")};
    right: ${(props) => (props.isDownloading ? "35px" : "40%")};
  }
`;

const spin = keyframes`
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
`;

const downloadingSpin = keyframes`
    0% {
      top: 10px;
      left: 10px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
      opacity: 0;
    }
`;

export const ChildLoader = styled.div<{ isDownloading: boolean }>`
  position: absolute;
  border: ${(props) =>
    props.isDownloading
      ? css`2px solid ${theme.colors.orange}`
      : css`4px solid ${theme.colors.orange}`};
  opacity: 1;
  border-radius: 50%;
  animation: ${(props) =>
    props.isDownloading
      ? css`
          ${downloadingSpin} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite
        `
      : css`
          ${spin} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite
        `}
  };
`;
export const ChildLoaderDelay = styled(ChildLoader)`
  animation-delay: -0.5s;
`;
export const IconWrap = styled.div`
  width: 3rem;
  height: auto;
  position: relative;
  display: block;
  line-height: 1;
`;
