import * as S from "../../styled";

const HealthCheck = () => {
  return (
    <S.FlexDiv
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <h1>Health check status: up</h1>
    </S.FlexDiv>
  );
};

export default HealthCheck;
