import React from "react";

import * as S from "../../styled";

const Loader = ({ isDownloading = false }) => {
  return (
    <S.MainLoader isDownloading={isDownloading}>
      <S.ChildLoader isDownloading={isDownloading}></S.ChildLoader>
      <S.ChildLoaderDelay isDownloading={isDownloading}></S.ChildLoaderDelay>
    </S.MainLoader>
  );
};

export default Loader;
