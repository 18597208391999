import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Dispatch } from 'redux';
import { ILanguage } from '../../components/Languages/Languages';
import { IOrganization } from '../../models/navigation';

import {
  SIGN_IN_USER,
  LOG_OUT,
  CHANGE_LANGUAGE,
  MOBILE_MENU_TOGGLE,
  CHANGE_ORGANIZATION,
  SET_DEFAULT_ORGANIZATION,
} from "./types";

export const authUser = (token: string) => async (dispatch: Dispatch) => {
  const decodedToken = jwtDecode<JwtPayload>(token);
  return dispatch({ type: SIGN_IN_USER, payload: decodedToken });
};

export const logout = () => async (dispatch: Dispatch) => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
  localStorage.removeItem("token-id");
  return dispatch({ type: LOG_OUT });
};

export const changeLanguages = (language: ILanguage) => async (dispatch: Dispatch) => {
  return dispatch({ type: CHANGE_LANGUAGE, payload: {language}});
};

export const changeOrganizationAccess = (organization: IOrganization) => async (dispatch: Dispatch) => {
  return dispatch({ type: CHANGE_ORGANIZATION, payload: organization });
};

export const mobileMenuToggle = () => async (dispatch: Dispatch) => {
  return dispatch({ type: MOBILE_MENU_TOGGLE });
};

export const setBaseUrl = () => async (dispatch: Dispatch) => {
  return dispatch({ type: SET_DEFAULT_ORGANIZATION });
};
