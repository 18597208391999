import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { redirectToMainRoute } from "../../shared/react-router.utils";

import dropdown from "../../assets/img/arrow_down.svg";
import dropdownDark from "../../assets/img/arrow_up_dark.svg";
import check from "../../assets/img/check_primary.svg";

import * as S from "../../styled";
import palette from "../../material-ui/theme/colors";
import { changeOrganizationAccess } from "../../store/actions/userAction";
import { getCategories } from "../../store/actions/categoryAction";
import { RootState } from "../../store/store";
import { IOrganization, IUrlParams } from "../../models/navigation";
import {
  changeShowEventList,
  resetSearchValue,
} from "../../store/actions/eventAction";
import { Button, Menu, Stack, TextField, Typography } from "@mui/material";

const UserOrganizations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userOrganizations = useSelector(
    (state: RootState) => state.user.data.organizations
  );
  const selectedOrganization = useSelector(
    (state: RootState) => state?.user.selectedOrganization
  );
  const selectedCategory = useSelector(
    (state: RootState) => state?.category?.selectedCategory
  );
  const searchValue = useSelector(
    (state: RootState) => state?.event?.searchValue
  );
  const dateRange = useSelector((state: RootState) => state?.event?.dateRange);
  const [organizationsDropdown, setOrganizationsDropdown] = useState(false);
  const { eventId }: IUrlParams = useParams();
  const [searched, setSearched] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOrganizationsDropdown(!organizationsDropdown);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setOrganizationsDropdown(false);
    setAnchorEl(null);
    setSearched("");
  };

  const changeOrganization = (organization: IOrganization) => {
    dispatch(changeOrganizationAccess(organization));
    setOrganizationsDropdown(false);
    handleCloseDropdown();
    dispatch(getCategories());
    if (searchValue || (dateRange?.fromDate && dateRange?.toDate)) {
      dispatch(resetSearchValue(selectedCategory));
    }
    dispatch(changeShowEventList(true));
    redirectToMainRoute(history, undefined, eventId, undefined);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={organizationsDropdown ? "alternateFilled" : "alternate"}
        disableRipple
        onClick={handleClick}
      >
        <Typography variant="p1_bold" sx={{ color: palette.grey[80], mr: 1 }}>
          {`${t("user.organization")}: `}
        </Typography>
        <Typography variant="p1_bold">{selectedOrganization?.name}</Typography>
        <S.DropDownIconInButton
          src={!open ? dropdown : dropdownDark}
          alt="organizations access dropdown icon"
          aria-expanded={organizationsDropdown}
          aria-controls="dropdown"
          open={organizationsDropdown}
        />
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropdown}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          sx={{ px: 2 }}
          value={searched}
          autoFocus
          onChange={(e) => {
            setSearched(e.target.value);
          }}
        />
        {userOrganizations
          ?.filter((element: IOrganization) =>
            element.name.toLowerCase().includes(searched.toLowerCase())
          )
          ?.map((organization: IOrganization) => (
            <Stack
              key={selectedOrganization.id}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => changeOrganization(organization)}
              px={2}
              py={1}
              sx={{
                "&:hover": {
                  color: palette.brand.main,
                },
                cursor: "pointer",
              }}
            >
              <Typography variant="p1_bold" align="center">
                {organization.name}
              </Typography>
              <Stack direction="row" justifyContent="end" alignItems="center">
                {selectedOrganization.id &&
                  selectedOrganization.id === organization.id && (
                    <img src={check} alt="checked organization icon" />
                  )}
              </Stack>
            </Stack>
          ))}
      </Menu>
    </>
  );
};

export default UserOrganizations;
