import React, { useEffect, useState } from "react";

import videojs, { VideoJsPlayer } from "video.js";

// Styles
import "video.js/dist/video-js.css";
import { IRemoteTrack } from "../../models/events";
import { FlexDiv, MediaPlayer } from "../../styled";
import { DecodeLanguageShortCode } from "../Language";
import axios from "axios";
import { IconButton, TextField, Typography } from "@mui/material";
import colors from "../../material-ui/theme/colors";
import AddIcon from "@mui/icons-material/AddCircle";
import MinusCircleIcon from "@mui/icons-material/RemoveCircle";

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  html5: {
    hls: {
      withCredentials: true,
      overrideNative: true,
      nativeTextTracks: false,
    },
  },
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

interface IVideoPlayerProps {
  options: videojs.PlayerOptions;
  type: string;
  setMediaOffset?: Function;
}
const offsetMin = -999;
const offsetMax = 999;
const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  options,
  type,
  setMediaOffset,
}) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<videojs.Player | null>(null);

  const [userOffset, setUserOffset] = useState(0);
  const [selectedCaption, setSelectedCaption] = useState<any>(null);

  const [currentPlayer, setCurrentPlayer] = useState<VideoJsPlayer | null>(
    null
  );
  const debouncedOffset = useDebounce(userOffset, 300);
  const [captionList, setCaptionList] = useState(options.tracks);

  function decrementOffset():
    | import("react").MouseEventHandler<HTMLButtonElement>
    | undefined {
    if (userOffset - 1 >= offsetMin) setUserOffset(userOffset - 1);
    return;
  }
  function incrementOffset():
    | import("react").MouseEventHandler<HTMLButtonElement>
    | undefined {
    if (userOffset + 1 <= offsetMax) setUserOffset(userOffset + 1);
    return;
  }
  function manualOffset(offset: string) {
    var value = parseInt(offset, 10) || 0;
    if (value > offsetMax) value = offsetMax;
    if (value < offsetMin) value = offsetMin;
    setUserOffset(value);
  }

  async function createOneBlob(caption: IRemoteTrack, player: VideoJsPlayer) {
    if (!caption) return;
    var result = await axios.get(
      // @ts-ignore
      `${caption.src2}${caption.offsetInSeconds - caption.mediaOffset}`,
      { withCredentials: true }
    );
    var vttBlob = new Blob([result.data], { type: "text/vtt" });
    player.addRemoteTextTrack(
      {
        kind: "captions",
        srclang: DecodeLanguageShortCode(caption.srclang),
        src: URL.createObjectURL(vttBlob),
        mode: "showing",
        // @ts-ignore
        offsetInSeconds: debouncedOffset,
      },
      false
    );

    return;
  }
  React.useEffect(() => {
    if (playerRef.current === null) {
      playerRef.current = videojs(videoRef.current, {
        ...initialOptions,
        ...options,
      }).ready(function () {
        if (captionList) {
          setCurrentPlayer(this);
          this.remoteTextTracks().addEventListener("change", async (e) => {
            var tracks = this.remoteTextTracks() || [];
            var track1: any = null;
            for (var i = 0; i < tracks.length; i++) {
              if (tracks[i].mode === "showing") {
                track1 = tracks[i];
              }
            }
            if (!track1) {
              setSelectedCaption(null);
              return;
            } else if (track1 !== null && track1 !== selectedCaption) {
              setSelectedCaption(track1);
            }
            // Find the English captions track and mark it as "showing".
            // if (track.kind === "captions" && track.language === "en") {
            //   track.mode = "showing";
            // }
          });
        }
      });
    } else {
      // console.log("player props updated");
    }
    // eslint-disable-next-line
  }, [options, videoRef, playerRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
        setCurrentPlayer(null);
      }
    };
  }, [playerRef, videoRef]);

  React.useEffect(() => {
    if (selectedCaption && currentPlayer) {
      //@ts-ignore
      const capt = captionList?.filter(
        // @ts-ignore
        (c) => DecodeLanguageShortCode(c.srclang) === selectedCaption.language
      )[0];
      if (capt) {
        // selectedCaption.removeEventListener();
        var tracks = currentPlayer.remoteTextTracks() || [];
        var track1: any = null;
        for (var i = 0; i < tracks.length; i++) {
          if (tracks[i].mode === "showing") {
            track1 = tracks[i];
          }
        }
        if (!track1) return;
        currentPlayer.removeRemoteTextTrack(track1);
        // @ts-ignore
        createOneBlob(capt, currentPlayer);
        // @ts-ignore
        setUserOffset(capt.offsetInSeconds);
      }
    }
  }, [selectedCaption, captionList, currentPlayer]);

  React.useEffect(() => {
    if (selectedCaption && currentPlayer) {
      const capt = captionList?.filter(
        // @ts-ignore
        (c) => DecodeLanguageShortCode(c.srclang) === selectedCaption.language
      )[0];

      if (
        capt &&
        //@ts-ignore
        userOffset !== capt.offsetInSeconds &&
        userOffset === debouncedOffset
      ) {
        if (setMediaOffset) setMediaOffset(capt.id, debouncedOffset);
        setCaptionList(
          captionList?.map((c) =>
            // @ts-ignore
            DecodeLanguageShortCode(c.srclang) === selectedCaption.language
              ? { ...c, offsetInSeconds: debouncedOffset }
              : c
          )
        );
      }
    }
  }, [debouncedOffset, captionList, currentPlayer]);

  return (
    <>
      <MediaPlayer type={type}>
        <video ref={videoRef} id="vdo" className="video-js" controls></video>
      </MediaPlayer>

      {type === "video" &&
        (selectedCaption ? (
          <FlexDiv
            height="48px"
            direction="row"
            width="100%"
            margin="auto auto auto auto"
          >
            <Typography
              variant="p1_bold"
              color="secondary"
              // sx={{ pt: 2 }}
              align="left"
            >
              Live caption offset value (in seconds):
            </Typography>
            <IconButton
              disabled={!selectedCaption}
              aria-label="close"
              onClick={() => decrementOffset()}
              color="primary"
              size="medium"
              disableRipple={true}
              sx={{ "& .MuiSvgIcon-root": { width: 24, height: 24 } }}
            >
              <MinusCircleIcon></MinusCircleIcon>
            </IconButton>
            <TextField
              id="outlined-adornment"
              type={"number"}
              value={userOffset}
              placeholder="0"
              size="small"
              onChange={(event) => {
                manualOffset(event.target.value);
              }}
              label=""
              color="secondary"
              inputProps={{
                "aria-label": "offset",
                min: offsetMin,
                max: offsetMax,
                style: {
                  fontSize: "16px",
                  color: colors.grey[100],
                  textAlign: "center",
                },
              }}
              sx={{
                pr: 0,
                maxWidth: 70,
                "& input": {
                  "&::placeholder": {
                    color: colors.grey[80],
                    opacity: 0,
                  },
                },
              }}
            />
            <IconButton
              aria-label="close"
              disabled={!selectedCaption}
              onClick={() => incrementOffset()}
              color="primary"
              size="medium"
              disableRipple={true}
              sx={{ "& .MuiSvgIcon-root": { width: 24, height: 24 } }}
            >
              <AddIcon></AddIcon>
            </IconButton>
          </FlexDiv>
        ) : (
          <FlexDiv height="48px"></FlexDiv>
        ))}
    </>
  );
};
export default VideoPlayer;

export function useDebounce(value: any, delay: number | undefined) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}
