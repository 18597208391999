import { Theme } from "@mui/material/styles";
import palette from "../theme/colors";

// ----------------------------------------------------------------------

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    alternate: true;
    alternateFilled: true;
  }
}

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      root: {},
      variants: [
        {
          props: { variant: "contained" as "contained" },
          style: {
            borderRadius: "8px",
            boxShadow: 'none !important',
            "&:hover": {
              color: palette.grey[0],
              backgroundColor: palette.brand[90],
            },
            "&:active": {
              color: palette.grey[0],
              backgroundColor: palette.brand[120],
            },
            "&:focus": {
              border: 2,
              borderStyle: "solid",
              borderColor: palette.grey[100],
              color: palette.grey[0],
              backgroundColor: palette.brand[90],
            },
            "&:disabled": {
              color: palette.grey[0],
              backgroundColor: palette.brand.main,
              opacity: 0.5,
            },

            "&.MuiButton-outlinedSecondary": {
              border: `1px solid ${palette.grey[20]}`,
            },
            "&.MuiButton-containedError": {
              backgroundColor: palette.red[100],
            },
            "&.MuiButton-containedError:hover": {
              backgroundColor: palette.red[80],
            },
            "&.MuiButton-containedError:focus": {
              backgroundColor: palette.red[80],
            },
            containedInherit: {
              color: theme.palette.grey[800],
              "&:hover": {
                backgroundColor: theme.palette.grey[400],
              },
            },
          },
        },
        {
          props: { variant: "outlined" as "outlined" },
          style: {
            padding: 12,
            paddingLeft: 24,
            paddingRight: 24,
            backgroundColor: palette.grey[0],
            border: 1,
            borderStyle: "solid",
            borderRadius: 8,
            borderColor: palette.grey[20],
            transitionDuration: "100ms",
            "&:hover": {
              borderColor: palette.brand.main,
              color: palette.brand.main,
              backgroundColor: palette.grey[0],
            },
            "&:active": {
              borderColor: palette.brand.main,
              color: palette.brand.main,
              backgroundColor: palette.brand.main_10,
            },
            "&:focus": {
              border: 2,
              borderStyle: "solid",
              borderColor: palette.grey[100],
              color: palette.brand.main,
              backgroundColor: palette.brand.main_10,
              padding: 11,
              paddingLeft: 23,
              paddingRight: 23,
            },
            "&:disabled": {
              color: palette.grey[100],
              backgroundColor: palette.grey[0],
              borderColor: palette.grey[102],
              opacity: 0.5,
            },
          },
        },
        {
          props: { variant: "text" as "text" },
          style: {
            color: palette.grey[100],
            transitionDuration: "100ms",
            // padding: 0,
            display: "flex",
            marginBottom: 12,
            marginTop: 4,
            paddingBottom: 4,
            paddingTop: 4,
            paddingLeft: 4,
            border: 2,
            borderStyle: "solid",
            borderColor: "transparent",
            img: {
              filter: 'filter: invert(6%) sepia(5%) saturate(823%) hue-rotate(314deg) brightness(101%) contrast(77%);'
            },
            "&:hover": {
              color: palette.brand.main,
              backgroundColor: palette.grey[0],
              img: {
                filter: 'invert(69%) sepia(81%) saturate(5681%) hue-rotate(346deg) brightness(100%) contrast(104%)'
              }
            },
            "&:active": {
              color: palette.brand[120],
              img: {
                filter: 'invert(36%) sepia(33%) saturate(3745%) hue-rotate(355deg) brightness(82%) contrast(91%)'
              }
            },
            "&:focus": {
              color: palette.brand.main,
              borderColor: palette.grey[100],
              img: {
                filter: 'invert(69%) sepia(81%) saturate(5681%) hue-rotate(346deg) brightness(100%) contrast(104%)'
              }
            },
            "&:disabled": {
              color: palette.grey[80],
              img: {
                filter: 'invert(50%) sepia(0%) saturate(1%) hue-rotate(347deg) brightness(98%) contrast(99%);'
              }
            },
          },
        },
        {
          props: { size: "large" as "large" },
          style: {
            height: 48,
            minWidth: 124,
          },
        },
        {
          props: { variant: "alternate" as "alternate" },
          style: {
            color: palette.grey[100],
            transitionDuration: "100ms",
            padding: "10px 12px",
            "&:hover": {
              color: palette.grey[100],
              background: palette.grey[0],
            },
            "&:active": {
              color: palette.grey[100],
            },
            "&:focus": {
              color: palette.grey[100],
            },
            "&:disabled": {
              color: palette.grey[80],
            },
          },
        },
        {
          props: { variant: "alternateFilled" as "alternateFilled" },
          style: {
            color: palette.grey[100],
            background: palette.grey[10],
            transitionDuration: "100ms",
            padding: "10px 12px",
            borderRadius: "8px",
            height: "40px",
            "&:hover": {
              color: palette.grey[100],
              background: palette.grey[10],
            },
            "&:active": {
              color: palette.grey[100],
              background: palette.grey[10],
            },
            "&:focus": {
              color: palette.grey[100],
              background: palette.grey[10],
            },
            "&:disabled": {
              color: palette.grey[80],
              background: palette.grey[10],
            },
          },
        },
      ],
    },
  };
}
