import { Theme } from "@mui/material/styles";
import palette from "../theme/colors";

// ----------------------------------------------------------------------
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    modal: true;
    search: true;
  }
}

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      variants: [
        {
          props: { variant: "filled" as "filled" },
          style: {
            colorPrimary: {
              backgroundColor: palette.grey[10],
              color: palette.grey[100],
            },
            colorSecondary: {
              backgroundColor: palette.grey[100],
            },
          },
        },
        {
          props: { variant: "modal" as "modal" },
          style: {
            padding: "0.8rem 1.6rem 0.8rem 1.6rem",
            marginRight: "0.8rem",
            marginTop: "0.8rem",
            height: "auto",
            borderRadius: "3.2rem",
            ".MuiChip-label": {
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "1.6rem",
              lineHeight: "2.4rem",
              padding: "0",
            },
            "&.MuiChip-colorPrimary": {
              backgroundColor: palette.grey[10],
              color: palette.grey[100],
            },
          },
        },
        {
          props: { variant: "search" as "search" },
          style: {
            padding: "8px 16px",
            borderRadius: "3.2rem", 
            ".MuiChip-label": {
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "1.6rem",
              lineHeight: "2.4rem",
            },
            "&.MuiChip-colorPrimary": {
              backgroundColor: palette.grey[10],
              color: palette.grey[100],
            },
          },
        },
      ],
      // defaultProps: {},
    },
  };
}
