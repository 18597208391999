import styled, { keyframes } from "styled-components";
import theme from "./theme";

interface IImageProps {
  open?: boolean;
  active?: boolean;
  resizedSidebar?: boolean;
  padding?: string;
}
export const DropDownIcon = styled.img<IImageProps>`
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "1rem")};
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const DropDownIconInButton = styled.img<IImageProps>`
  // border-left: ${(props) =>
    !props.open && `1px solid ${theme.colors.gray20}`};
  // border-right: ${(props) =>
    props.open && `1px solid ${theme.colors.gray20}`};
  // padding-right: ${(props) => !props.open && "0.5rem"};
  // padding-left: ${(props) => props.open && "0.5rem"};
`;

export const DropDownIconInButtonLanguges = styled(DropDownIconInButton)`
  @media (min-width: ${theme.breakpointsWidth.small}) {
    border: none;
  }
`;

export const LoopIcon = styled.img`
`;

export const ChangeThumbIcon = styled.img<IImageProps>`
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.7)};
`;

export const ChangeListIcon = styled(ChangeThumbIcon)`
  margin-right: 1rem;
`;

export const InterprefyLogo = styled.img`
  margin-right: 16px;
  cursor: pointer;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    margin: 0 auto;
  }
`;

export const HamburgerIcon = styled.img`
  display: none;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
    cursor: pointer;
  }
`;

export const LogoutIcon = styled.img`
  padding: 0 1rem;
`;

export const BackIcon = styled.img`
  transform: rotate(180deg);
`;

export const BackIconEvent = styled.img`
  margin-right: 0.8rem;
`;

export const MobileIcon = styled.img`
  margin-right: 2rem;
`;
export const DownloadIcon = styled.img`
  width: 2.4rem;
  opacity: 1;
`;
export const AlertIcon = styled.img`
  width: 2rem;
`;

const rotate_animation = keyframes` 
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;
export const ProcessingIcon = styled.img`
  width: 2rem;
  animation-name: ${rotate_animation};
  animation-duration: 1.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: inline-block;
`;
export const PreviewIcon = styled.img`
  margin: 0 0.6rem;
`;

export const CloseErrorIcon = styled.img`
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    top: 2.6rem;
  }
`;
