import { ICategory } from "../models/categories";
import {
  ALL_EVENTS_ID,
  ALL_EVENTS_OBJECT,
} from "./constants";

//#region CATEGORY REDUCER helper methods

export function findItemNested(categories: ICategory[], categoryId: string = ""): any {
  return categories.reduce((a: any, category: ICategory) => {
    if (a) return a;
    if (category.id === categoryId) return category;
    if (!!category.subCategories)
      return findItemNested(category.subCategories, categoryId);
    return a;
  }, null);
}

export function setArrayOfCategoryAndSubCategoriesIds(category: ICategory) {
  let parentId = category.parentId === null ? ALL_EVENTS_ID : category.parentId;
  let arrayOfCategoryAndSubCategoryIds = [category.id, parentId];
  category.subCategories.map((categ: ICategory) => {
    arrayOfCategoryAndSubCategoryIds = arrayOfCategoryAndSubCategoryIds.concat(
      categ.id
    );

    arrayOfCategoryAndSubCategoryIds = arrayOfCategoryAndSubCategoryIds.concat(
      setArrayOfCategoryAndSubCategoriesIds(categ)
    );
    arrayOfCategoryAndSubCategoryIds = Array.from(
      new Set(arrayOfCategoryAndSubCategoryIds)
    );

    // eslint-disable-next-line
    return categ;
  });
  return arrayOfCategoryAndSubCategoryIds;
}

export function addCategoryHelperFunc(categories: any[], categoryId: string = "", newCategory: {} | undefined) {
  return categories.map((category) => {
    if (category.id === categoryId) {
      return {
        ...category,
        subCategories: [...category.subCategories, newCategory],
      };
    }
    if (category["subCategories"])
      category.subCategories = addCategoryHelperFunc(
        category["subCategories"],
        categoryId,
        newCategory
      );
    return category;
  });
}

export function renameCategoryHelperFunc(categories: any[], categoryId: string = "", newName: string | undefined) {
  return categories.map((category) => {
    if (category.id === categoryId) return { ...category, name: newName };

    if (category["subCategories"])
      category.subCategories = renameCategoryHelperFunc(
        category["subCategories"],
        categoryId,
        newName
      );
    return category;
  });
}

export function deleteCategoryHelperFunc(categories: ICategory[], categoryId: string = "") {
  return categories.filter((category) => {
    if (category.id === categoryId && category.subCategories.length === 0)
      // eslint-disable-next-line
      return;
    if (!!category["subCategories"])
      category.subCategories = deleteCategoryHelperFunc(
        category["subCategories"],
        categoryId
      );
    return category;
  });
}

export function addIsOpenHelperFunc(categories: ICategory[]) {
  return categories.filter((category) => {
    category.open = false;
    if (!!category.subCategories) {
      category.subCategories = addIsOpenHelperFunc(category["subCategories"]);
    }
    return category;
  });
}

export function preserveCategoryOpen(categories: ICategory[]) {
  return categories.filter((category) => {
    if (category.open) return { ...category, open: true };
    if (!category.open) return { ...category, open: false };
    preserveCategoryOpen(category["subCategories"]);
    return category;
  });
}

export function decrementLevelNestedByOne(levelNested: number) {
  return levelNested > 1 ? levelNested - 1 : 0;
}

export function setSelectedCategory(levelNested: number, categories: ICategory[], categoryId: string = "") {
  return levelNested === 0
    ? ALL_EVENTS_OBJECT
    : findItemNested(categories, categoryId);
}
//#endregion