import axios from "axios";

import { ALL_EVENTS, ALL_EVENTS_ID } from "../../shared/constants";
import { setGlobalError } from "./errorAction";
import { baseUrl } from "../reducers/usersReducer";

import {
  GET_CATEGORIES,
  GET_CATEGORY,
  EXPAND_CATEGORY,
  COLLAPSE_CATEGORY,
  ADD_CATEGORY,
  RENAME_CATEGORY,
  MOVE_CATEGORY,
  DELETE_CATEGORY,
  RESIZE_SIDEBAR,
  TOGGLE_MOVE_TO_CATEGORY_MODAL,
  TOGGLE_CATEGORY_ACTIONS,
  SET_VISIBILITY_MOVE_TO_CATEGORY_MODAL,
  DISABLE_MOVE_TO_BUTTON,
} from "./types";
import { Dispatch } from "redux";
import { ICategory } from "../../models/categories";

let categoryId = '0';

export const resizeSidebar = () => async (dispatch: Dispatch) => {
  return dispatch({ type: RESIZE_SIDEBAR });
};

export const toggleMoveToCategoryModal = () => async (dispatch: Dispatch) => {
  return dispatch({ type: TOGGLE_MOVE_TO_CATEGORY_MODAL });
};

export const setVisibilityMoveToCategoryModal = () => async (dispatch: Dispatch) => {
  return dispatch({ type: SET_VISIBILITY_MOVE_TO_CATEGORY_MODAL });
};

export const toggleCategoryActionsMenuVisibility =
  (category:ICategory| null = null, selectedCategoryWithSubCategoriesId: string[] = []) =>
    async (dispatch: Dispatch) => {
      let selectedCategoryWithSubCategoriesIdArray;
      selectedCategoryWithSubCategoriesIdArray =
        selectedCategoryWithSubCategoriesId
          ? selectedCategoryWithSubCategoriesId
          : null;

      return dispatch({
        type: TOGGLE_CATEGORY_ACTIONS,
        payload: { category, selectedCategoryWithSubCategoriesIdArray },
      });
    };

export const getCategories = () => async (dispatch: Dispatch) => {
  return axios
    .get(`${baseUrl}/categories`)
    .then((res) => dispatch({ type: GET_CATEGORIES, payload: res }))
    .catch((err) => {
      setGlobalError(err, dispatch);
    });
};

export const getCategory =
  (category: ICategory, isSelectedCategory = false) =>
    async (dispatch: Dispatch) => {
      categoryId = category.id;
      return dispatch({
        type: GET_CATEGORY,
        payload: { category: category, isSelectedCategory },
      });
    };

export const addCategory = (category: ICategory, nameFolder: string) => async (dispatch: Dispatch) => {
  let parentId = category.id;
  if (category.name === ALL_EVENTS) parentId = '';
  return axios
    .post(`${baseUrl}/categories/add`, {
      name: nameFolder,
      parent: parentId,
    })
    .then((res) =>
      dispatch({
        type: ADD_CATEGORY,
        payload: { category, newCategory: res.data },
      })
    )
    .catch((err) => {
      setGlobalError(err, dispatch);
    });
};

export const renameCategory =
  (category: ICategory, categoryNewName: string) => async (dispatch: Dispatch) => {
    return axios
      .patch(`${baseUrl}/categories/${category.id}/name`, {
        name: categoryNewName,
      })
      .then((res) =>
        dispatch({
          type: RENAME_CATEGORY,
          payload: { categoryId: res.data.id, categoryNewName: res.data.name },
        })
      )
      .catch((err) => {
        setGlobalError(err, dispatch);
      });
  };

export const moveCategory = (moveToCategoryId: string | null) => async (dispatch: Dispatch) => {
  let moveToParentId = moveToCategoryId;
  if (moveToCategoryId === ALL_EVENTS_ID) moveToParentId = null;
  return axios
    .patch(`${baseUrl}/categories/${categoryId}/parent`, {
      parentId: moveToParentId,
    })
    .then((res) =>
      dispatch({
        type: MOVE_CATEGORY,
        payload: { categoryId, moveToCategoryId, moveToParentId },
      })
    )
    .catch((err) => setGlobalError(err, dispatch));
};

export const deleteCategory = (category: ICategory) => async (dispatch: Dispatch) => {
  return axios
    .delete(`${baseUrl}/categories/${category.id}`)
    .then((res) => dispatch({ type: DELETE_CATEGORY, payload: category }))
    .catch((err) => {
      setGlobalError(err, dispatch);
    });
};

export const expandCategoryAction = (id: string) => async (dispatch: Dispatch) => {
  return dispatch({ type: EXPAND_CATEGORY, payload: {id} });
};

export const collapseCategoryAction = (id: string) => async (dispatch: Dispatch) => {
  return dispatch({ type: COLLAPSE_CATEGORY, payload: {id} });
};

export const setDisableMoveToButton = (isDisabled: boolean) => async (dispatch: Dispatch) => {
  return dispatch({ type: DISABLE_MOVE_TO_BUTTON, payload: {isDisabled} });
};
