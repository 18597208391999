import { ICategory } from "../models/categories";
import { IEventItem, IRecordingInstanceItem } from "../models/events";
import { MEDIA_LIBRARY, ALL_EVENTS } from "./constants";
import { isNotEmptyObject } from "./utils-ts";

export function makeCategoryEventFilesPath(
  levelNested: number,
  selectedCategory: ICategory,
  event: IEventItem
) {
  return levelNested <= 1
    ? `${MEDIA_LIBRARY} / ${selectedCategory.name} / ${event.name}`
    : `${MEDIA_LIBRARY} / ... / ${selectedCategory.name} / ${event.name}`;
}

export function setBreadcrumbs(
  selectedCategory: ICategory | null = null,
  searchValue: string = "",
  selectedEvent: IEventItem | null = null,
  selectedRecordingInstance: IRecordingInstanceItem | null = null,
  isSelectedCategory: boolean = false,
  levelNested: number = 0
): string {
  switch (true) {
    case levelNested === 1:
      return `${MEDIA_LIBRARY}`;
    case isNotEmptyObject(selectedEvent) &&
      isNotEmptyObject(selectedRecordingInstance):
      return `${MEDIA_LIBRARY} / ... / ${selectedEvent?.name} / ${selectedRecordingInstance?.name}`;
    case selectedCategory?.name === ALL_EVENTS &&
      isNotEmptyObject(selectedEvent):
      return `${MEDIA_LIBRARY} / ${ALL_EVENTS} / ${selectedEvent?.name}`;
    case selectedCategory?.name !== ALL_EVENTS &&
      isNotEmptyObject(selectedEvent):
      return `${MEDIA_LIBRARY} / ... / ${selectedCategory?.name} / ${selectedEvent?.name}`;
    case !isSelectedCategory && !!searchValue:
      return `${MEDIA_LIBRARY} / ${ALL_EVENTS}`;
    case isSelectedCategory && !!searchValue:
      return `${MEDIA_LIBRARY} / ... / ${selectedCategory?.name}`;
    case selectedCategory?.name === ALL_EVENTS:
      return `${MEDIA_LIBRARY} / ${ALL_EVENTS}`;
    case !!selectedCategory?.name && selectedCategory.name !== ALL_EVENTS:
      return `${MEDIA_LIBRARY} / ... / ${selectedCategory?.name}`;
    default:
      return `${MEDIA_LIBRARY}`;
  }
}
