import { IEventFile, IEventItem, IRecordingInstance, ItemStatus, MediaFileType } from "../models/events";
import {
    TRANSCRIPTION,
    CREATE_FLOOR_FILLED_VIDEO,
} from "./constants";
import { timestrToSec } from "./utils-ts";

//#region Formatting
export function formatDate(date: any) {
    //@ts-ignore
    let formatedDateArray = date.split("-");
    const formatedDate = `${formatedDateArray[2]}-${formatedDateArray[1]}-${formatedDateArray[0]}`
    return (formatedDate);
}

export const formatEventDate = (date: any) => {
    const updatedDated = new Date(date?.split("-"))
        .toString()
        .split(" ");

    return `${updatedDated[2]} ${updatedDated[1]} ${updatedDated[3]}`;
};

export const getLocalDateTime = (date: string) => {
    const localDateTime = new Date(date.toString() + "z").toLocaleString('en-GB', { dateStyle: "medium", timeStyle: "short" });
    return localDateTime.split(",");
};

export function addPropertyIsDownloadingAndFormatDateAndAddOffset(eventFilesForDownload: IEventFile[]) {
    let mediaOffset: { [key: string]: number } = {};
    const newEventFilesList = eventFilesForDownload.map((eventFile: IEventFile) => {
        let date = formatDate(eventFile.date);
        const eventFileFormatted = { ...eventFile, date, isDownloading: false }
        if (MediaFileType.get(eventFile.fileType) === 'video') {
            if (!mediaOffset[eventFile.languageCode]) mediaOffset[eventFile.languageCode] = 0;
            eventFileFormatted.mediaOffset = mediaOffset[eventFile.languageCode];
            mediaOffset[eventFile.languageCode] += timestrToSec(eventFile.duration);
        }
        return eventFileFormatted;
    });
    return newEventFilesList;
}
//#endregion

export function setPageNumber(totalPages: number = 0, pageNumber: number = 0) {
    return totalPages >= pageNumber ? pageNumber : totalPages;
}

export function setDownloadEventFiles(
    eventFiles: Partial<IEventItem>[],
    eventId: string = "",
    isDownloadingEventFile: boolean = false
) {
    return eventFiles.map((eventFile) => {
        if (eventFile.id === eventId && isDownloadingEventFile) {
            return { ...eventFile, isDownloading: true };
        } else if (eventFile.id === eventId && !isDownloadingEventFile) {
            return { ...eventFile, isDownloading: false };
        }
        return eventFile;
    });
}
export function addToEventsIsExtendedAndFormatDate(events: Partial<IEventItem>[]) {
    return events.map((event) => {
        let date = formatDate(event.updatedAt);
        return { ...event, updatedAt: date, isExpanded: event.isExpanded === true };
    });
}

export function formatEventsDate(previousEvents: any, loadedEvents: any[]) {
    const loadedUpdatedEvents = loadedEvents.map((event) => {
        let date = formatDate(event.updatedAt);
        return { ...event, updatedAt: date };
    });
    return [...previousEvents, ...loadedUpdatedEvents];
}
//CHECK THIS METHOD
export function updateItemsOnExpand(
    items: any[],
    itemId: string = "",
    hasParams = false,
    firstRecordingInstance: any = null
) {
    return items.map((item) => {
        if (item.id === itemId) {
            if (firstRecordingInstance)
                item.updatedAt = recordingInstanceDate(firstRecordingInstance)[0];
            return {
                ...item,
                isExpanded: hasParams ? true : (item.isExpanded ? item.isExpanded : false),
            };
        }
        return { ...item, isExpanded: false };
    });
}

export function updateExpandedRecordingInstanceFlag(items: Partial<IRecordingInstance>[],
    itemId: string = "") {
    return items.map((item) => {
        if (item.id === itemId) {
            return {
                ...item,
                isExpanded: !item.isExpanded,
            };
        }
        return { ...item, isExpanded: false };
    });
}

export function recordingInstanceDate(recordingInstance: IRecordingInstance) {
    let dateAndTimeArray = recordingInstance.createdAt.split("T");
    let dateUS = new Date(dateAndTimeArray[0]).toLocaleString('en-US', { dateStyle: "medium", timeZone: 'UTC' });
    let date = dateUS.substring(
        dateUS.indexOf(" ") + 1,
        dateUS.lastIndexOf(",")
    ) + ' ' + dateUS.substring(0, 3) + dateUS.substring(dateUS.lastIndexOf(' '), dateUS.length);
    let time = dateAndTimeArray[1].split(":");
    const newTime = `${time[0]}:${time[1]}`;
    return [date, newTime];
}

export function updateStatus(items: Partial<IRecordingInstance>[], itemId: string = "", status: string) {
    return items.map((item) => {
        if (item.id === itemId && status === TRANSCRIPTION) {
            return {
                ...item,
                transcriptionStatus: ItemStatus.REQUESTED,
            };
        }

        if (item.id === itemId && status === CREATE_FLOOR_FILLED_VIDEO) {
            return {
                ...item,
                languageFloorFilledVideoStatus: ItemStatus.REQUESTED,
            };
        }
        return item;
    });
}

export function countMediaFloorFiles(eventFilesForDownload: IEventFile[] | undefined): number {
    const filesCount = eventFilesForDownload?.filter(
        (file) =>
            (MediaFileType.get(file.fileType) === "audio" ||
                MediaFileType.get(file.fileType) === "video") &&
            file.languageCode === "SRC"
    ).length
    return filesCount !== undefined ? filesCount : 0
}
