import { Theme } from "@mui/material/styles";
import palette from "../theme/colors";
// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          "&.MuiPaper-rounded": {
            borderRadius: "16px",
          },
          "&.MuiDialog-paperFullScreen": {
            borderRadius: 0,
          },
          "&.MuiDialog-paper .MuiDialogActions-root": {
            padding: theme.spacing(0, 4, 4),
          },
          "@media (max-width: 600px)": {
            margin: theme.spacing(2),
          },
          "@media (max-width: 663.95px)": {
            "&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody": {
              maxWidth: "100%",
            },
          },
        },
        paperFullWidth: {
          width: "100%",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4, 4, 1),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: 0,
          borderBottom: 0,
          padding: theme.spacing(4),
          color: palette.grey[0],
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: palette.grey[100],
          opacity: 1,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& > :not(:first-of-type)": {
            marginLeft: theme.spacing(1.5),
          },
          justifyContent: "flex-start",
        },
      },
    },
  };
}
