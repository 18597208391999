import styled from "styled-components";
import theme from "./theme";

interface IContainerProps {
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  open?: boolean;
}
export const ContainerFullWidth = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.gray10};
`;

export const Container = styled.div<IContainerProps>`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  padding: 0 2rem;
  @media (min-width: ${theme.breakpointsWidth.large}) {
    max-width: 1560px;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    justify-content: space-between;
  }
`;

export const Navigation = styled.nav`
  width: 100%;
  height  56px;
  background: ${theme.colors.white};
  z-index: 100;
  position: relative;
`;

export const Sidebar = styled.div<IContainerProps>`
  position: relative;
  outline: none;
  width: ${(props) => (props.open ? "65px" : "100%")};
  max-width: 270px;
  height: calc(100vh - 40px);
  background-color: ${theme.colors.white};
  box-shadow: 0 1px 4px 0 ${theme.colors.blackRgba20};
  z-index: 12;
  position: relative;
  padding: 2rem;
  transition: ${(props) =>
    props.open ? "0.3s ease-in-out" : "0.5s ease-in-out"};
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba20};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba50};
  }

  @media (max-width: ${theme.breakpointsWidth.big}) {
    width: ${(props) => (props.open ? "100%" : "65px")};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;
