import { ItemStatus, ITranscriptionStatus } from "../../../../models/events";
import * as S from "../../../../styled";
import alertIcon from "../../../../assets/icons/alert-circle.svg";
import processingIcon from "../../../../assets/icons/ellipse-1.svg";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DecodeLanguageShortCode } from "../../../../shared/Language";

const EventFileTranscription = ({
  transcriptionItem,
}: EventFileTranscriptionProps) => {
  const { t } = useTranslation();
  return (
    <S.FlexDivEventFileInfo
      width="100%"
      height="7.2 rem"
      direction="row"
      padding="1rem"
    >
      <S.BaseFlexTranscriptionStatus>
        <S.IconWrap>
          {transcriptionItem.transcriptionStatus === ItemStatus.FAILED ? (
            <S.AlertIcon src={alertIcon} alt="alert icon"></S.AlertIcon>
          ) : (
            <S.ProcessingIcon
              src={processingIcon}
              alt="loader icon"
            ></S.ProcessingIcon>
          )}
        </S.IconWrap>

        <S.BaseFlex justifyContent="flex-start">
          {transcriptionItem.floor ? (
            <Typography variant="p1_bold" align="left">
              Floor({DecodeLanguageShortCode(transcriptionItem.languageCode)})
            </Typography>
          ) : (
            <Typography variant="p1_bold" align="left">
              {DecodeLanguageShortCode(transcriptionItem.languageCode)}
            </Typography>
          )}
          <Typography variant="p1" ml={1}>
            {transcriptionItem.transcriptionStatus === ItemStatus.FAILED ? (
              <> - {t("events.generationFailed")}</>
            ) : (
              <> - {t("events.generatingFile")}</>
            )}
          </Typography>
        </S.BaseFlex>
      </S.BaseFlexTranscriptionStatus>
    </S.FlexDivEventFileInfo>
  );
};

export default EventFileTranscription;

interface EventFileTranscriptionProps {
  transcriptionItem: ITranscriptionStatus;
}
