import styled from "styled-components";
import palette from "../material-ui/theme/colors";
import theme from "./theme";

interface ITextProps {
  open?: boolean;
  isExpandedEvent?: boolean;
  showName?: boolean;
  hover?: boolean;
}
export const HeadingPrimary = styled.h1`
  font-family: ${theme.fonts.montserratMedium};
  color: ${theme.colors.orange};
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 2.9rem;
  text-align: center;
  text-transform: uppercase;
  margin-top: 5rem;
`;

export const ShowingParagraph = styled.p`
  font-size: 1.2rem;
  font-family: ${theme.fonts.openSans};
  color: ${theme.colors.gray50};
`;

export const ShowingSpan = styled.span`
  font-weight: 600;
  color: ${theme.colors.black};
  &::before,
  &::after {
    content: " ";
  }
`;

export const EventParagraph = styled.p<ITextProps>`
  color: ${theme.colors.gray50};
  font-family: ${theme.fonts.openSans};
  font-size: 1.3rem;
  color: ${(props) =>
    props.isExpandedEvent ? theme.colors.white : theme.colors.gray60}};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    font-size: 1.2rem;
  }
`;

export const EventName = styled(EventParagraph) <ITextProps>`
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  word-break: break-word;
  color: ${(props) =>
    props.isExpandedEvent ? theme.colors.white : theme.colors.gray60}};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

export const EventEmptyParagraph = styled(EventParagraph)`
  font-size: 2rem;
  text-align: center;
  margin-top: 10rem;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    font-size: 1.4rem;
  }
`;

export const EventLoading = styled(EventParagraph)`
  font-size: 2rem;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 0;
  width: calc(100% - 12px);
  z-index: 9;
  color: ${theme.colors.white};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    font-size: 1.4rem;
  }
`;

export const CategoryEmptyMsg = styled(EventLoading)`
  color: ${theme.colors.gray50};
`;

export const UserInfo = styled.p`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.roboto};
  font-size: 1.6rem;
  word-break: break-word;
`;

export const BreadCrumbs = styled.h2`
  width: 100%;
  font-family: ${theme.fonts.openSans};
  font-size: 1.2rem;
  font-weight: 300;
  color: ${theme.colors.gray60};
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.3rem;
  background-color: ${theme.colors.gray10};
  display: none;

  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
  }
`;

export const MobileSpan = styled.span`
  font-family: ${theme.fonts.openSans};
  font-size: 1.4rem;
  color: ${theme.colors.gray60};
  font-weight: 600;
`;

export const ErrorStatus = styled.h2`
  font-family: ${theme.fonts.roboto};
  font-weight: 800;
  font-size: 14rem;
  color: ${theme.colors.orange};
  margin-top: 8rem;
  margin-bottom: 0;

  @media (max-width: ${theme.breakpointsWidth.large}) {
    font-size: 10rem;
  }
  @media (max-width: ${theme.breakpointsWidth.medium}) {
    font-size: 6rem;
  }
  @media (max-width: ${theme.breakpointsWidth.small}) {
    font-size: 10rem;
  }

  @media (max-width: ${theme.breakpointsWidth.big}),
    (max-height: ${theme.breakpointsHeight.medium}) {
    font-size: 15rem;
  }
  @media (max-width: ${theme.breakpointsWidth.large}),
    (max-height: ${theme.breakpointsHeight.medium}) {
    font-size: 14rem;
  }
  @media (max-width: ${theme.breakpointsWidth.medium}),
    (max-height: ${theme.breakpointsHeight.medium}) {
    font-size: 11rem;
    margin-top: 7rem;
  }
  @media (max-width: ${theme.breakpointsWidth.small}),
    (max-height: ${theme.breakpointsHeight.small}) {
    margin-top: 6rem;
    font-size: 9rem;
  }
`;

export const ErrorMessage = styled.p`
  font-family: ${theme.fonts.roboto};
  font-weight: 600;
  font-size: 1.4rem;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 3rem;
  color: ${theme.colors.black};
  margin-top: 2rem;
  @media (max-width: ${theme.breakpointsWidth.medium}) {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }

  @media (max-width: ${theme.breakpointsWidth.large}),
    (max-height: ${theme.breakpointsHeight.big}) {
    bottom: 2rem;
  }
  @media (max-width: ${theme.breakpointsWidth.large}),
    (max-height: ${theme.breakpointsHeight.medium}) {
    bottom: 1rem;
  }
`;

export const SearchValue = styled.p`
  display: flex;
  padding: 5px 15px;
  border-radius: 25px;
  background: ${theme.colors.orange};
  color: ${theme.colors.white};
  font-family: ${theme.fonts.openSans};
  font-size: 1.2rem;
  margin-top: 2px;
  margin-right: 2px;
`;

export const LanguagesTitle = styled.p`
display: none;
font-family: ${theme.fonts.openSans};
font-size: 1.3rem;
color: ${theme.colors.gray50};
@media (max-width: ${theme.breakpointsWidth.small}) {
  display: flex;
  padding-bottom: 1rem;
}
`;

export const LanguagesDefaultText = styled.p`
  display: flex;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: none;
  }
`;

export const LanguageDefaultValue = styled.p`
  display: none;
  @media (max-width: ${theme.breakpointsWidth.small}) {
    display: flex;
  }
`;


export const EventFileName = styled.p`
  font-family: 'Poppins';
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${palette.grey[80]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const EventFileSizeDuration = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${palette.grey[80]};
  margin: auto;
`;

export const EventFileLanguage = styled.p`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
`

export const ToggleDownloadFilesButtonText = styled.span`
  width: 80%;
`;

export const DownloadEventSub = styled.div`
  width: 100%;
  text-align: left;
  color: #7F7E7E;
  margin: 8px 0;
`
