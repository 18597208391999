import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import closeIcon from "../../assets/img/close-error.svg";
import { ERROR_WITH_SERVER } from "../../shared/constants";

import * as S from "../../styled";
import { resetGlobalError } from "../../store/actions/errorAction";
import { RootState } from "../../store/store";

const ErrorNotification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalError = useSelector(
    (state: RootState) => state.error.globalError
  );
  const [componentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef<any | null>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setIsComponentVisible(false);
      dispatch(resetGlobalError());
    }
  };

  const close = () => {
    dispatch(resetGlobalError());
    setIsComponentVisible(false);
  };

  function setErrorMessage(errorMessage: string = "") {
    return errorMessage === ERROR_WITH_SERVER
      ? t("error.errorWithServer")
      : errorMessage;
  }

  return (
    <>
      {componentVisible && (
        <S.ErrorFlexDiv
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          data-testid="error-block"
        >
          <S.ErrorBlock width="60%" height="60%" ref={ref}>
            <button onClick={() => close()}>
              <S.CloseErrorIcon src={closeIcon} alt="close icon" />
            </button>
            <S.ErrorStatus>{globalError.status}</S.ErrorStatus>
            <S.ErrorMessage>
              {setErrorMessage(globalError.message || undefined)}
            </S.ErrorMessage>
          </S.ErrorBlock>
        </S.ErrorFlexDiv>
      )}
    </>
  );
};

export default ErrorNotification;
