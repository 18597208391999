import { subdomain } from "./keycloack";

// USER REDUCER HELPER FUNCTIONS
export function setUserData(userData: any) {
    const updatedOrganizationName = setMainOrganizationName(subdomain);
    let data = Object.assign(userData, {
        organizations: [
            {
                id: "current",
                name:
                    updatedOrganizationName === (window as any).DEFAULT_REALM_NAME
                        ? "Main Organization"
                        : updatedOrganizationName,
            },
            ...(userData.mediaLibraryOrganizations
                ? userData.mediaLibraryOrganizations
                : []),
        ],
    });

    data = Object.keys(data).reduce((object: any, key: string) => {
        if (key !== "mediaLibraryOrganizations") {
            object[key] = data[key];
        }
        return object;
    }, {});
    return data;
}

export function setMainOrganizationName(subdomain: string | undefined): string {
    let mainOrg = null;
    if (!subdomain) {
        mainOrg = (window as any).DEFAULT_REALM_NAME;
    } else {
        if (subdomain.includes(".") && subdomain !== (window as any).DEFAULT_REALM_NAME) {
            mainOrg = subdomain.split(".")[0];
        } else {
            mainOrg = subdomain;
        }
    }

    return (mainOrg = mainOrg.charAt(0).toUpperCase() + mainOrg.slice(1));
}

export function setEnvironment() {
    const envString: string = (window as any).REACT_APP_API_URL;
    if (envString.includes('.test.')) { return 'test' }
    else if (envString.includes('.uat.')) { return 'uat' }
    else if (envString.includes('stage.')) { return 'stage' }
    else { return 'prod' };
}