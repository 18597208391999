import {
  SET_ERROR,
  RESET_ERROR,
  SET_GLOBAL_ERROR,
  RESET_GLOBAL_ERROR,
} from "../actions/types";
import { ActionWithPayload } from "./eventReducer";

export interface ErrorObj {
  errorMsg: string | null;
  errorDetails: string | null;
}

export interface GlobalError {
  status: number | null;
  message: string | null;
}

export interface IErrorState {
  errorObj: ErrorObj;
  globalError: GlobalError;
}

const initialState: IErrorState = {
  errorObj: {
    errorMsg: null,
    errorDetails: null,
  },
  globalError: {
    status: null,
    message: null,
  },
};

export interface IErrorPayload extends IErrorState {
  errorMessage: string | null;
  errorDetails: string | null;
  status: number | null;
  message: string | null;
}
// eslint-disable-next-line
export default (state: IErrorState = initialState, action: ActionWithPayload<Partial<IErrorPayload>>): IErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        errorObj: {
          ...state.errorObj,
          errorMsg: action.payload.errorMessage || null,
          errorDetails: action.payload.errorDetails || null,
        },
      };
    case RESET_ERROR:
      return {
        ...state,
        errorObj: {
          ...state.errorObj,
          errorMsg: null,
          errorDetails: null,
        },
      };
    case SET_GLOBAL_ERROR:
      return {
        ...state,
        globalError: {
          ...state.globalError,
          status: action.payload.status || null,
          message: action.payload.message || null,
        },
      };
    case RESET_GLOBAL_ERROR:
      return {
        ...state,
        globalError: {
          ...state.globalError,
          status: null,
          message: null,
        },
      };

    default:
      return state;
  }
};
