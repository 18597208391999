import { Theme } from '@mui/material/styles'


// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    p1_bold: React.CSSProperties;
    p2_bold: React.CSSProperties;
    p3_bold: React.CSSProperties;
    h4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    p1_bold?: React.CSSProperties;
    p2_bold?: React.CSSProperties;
    p3_bold?: React.CSSProperties;
    h4?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p1: true;
    p2: true;
    p3: true;
    p1_bold: true;
    p2_bold: true;
    p3_bold: true;
    p1_grey: true;
    h3: true;
    h4: true;
  }
}
export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  }
}
