const theme = {
  colors: {
    white: "#FFFFFF",
    gray10: "#F4F5F6",
    gray15: "#F8F8F8",
    gray20: "#E0E3E5",
    gray30: "#D2D2D2",
    gray40: "#D8D8D8",
    gray50: "#657680",
    gray60: "#19242D",
    gray70: "#192429",
    gray80: "#EAEAE9",
    gray100: "#24252A",
    gray110: "#2a2828",
    gray120: "#55606e",
    grayRgba20: "rgba(101, 118, 128, 0.2)",
    grayRgba30: "rgb(86, 101, 116)",
    grayBorderBottom: "#ECECEC",
    grayerBorderBottom: "#D4D4D4",
    orange: "#FF5E21",
    orangeRgba60: "rgba(255, 124, 33, 0.6)",
    brownRgba10: "rgba(255, 69, 0, 0.1)",
    blackLight: "#1D1D26",
    black: "#000000",
    blackRgba10: "rgba(0, 0, 0, 0.1)",
    blackRgba20: "rgba(0, 0, 0, 0.2)",
    blackRgba30: "rgba(0, 0, 0, 0.3)",
    blackRgba40: "rgba(0, 0, 0, 0.4)",
    blackRgba50: "rgba(0, 0, 0, 0.5)",
    blue: "#2B3B55",
  },
  fonts: {
    bold: "bold",
    montserratMedium: "Montserrat",
    montserratSemiBold: "MontserratSemiBold",
    openSans: "OpenSans",
    roboto: "Roboto",
    poppins: "Poppins"
  },
  breakpointsWidth: {
    extraSmall: "320px",
    small: "576px",
    medium: "768px",
    big: "992px",
    large: "1250px",
    larger: "1350px",
    extraLarge: "1440px",
  },
  breakpointsHeight: {
    extraSmall: "420px",
    small: "576px",
    medium: "768px",
    big: "992px",
  },
};

export default theme;
