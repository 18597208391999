import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import DownloadEventFileItem from "./DownloadEventFileItem/DownloadEventFileItem";

import * as S from "../../../styled";
import {
  setGlobalError,
  setTosterError,
} from "../../../store/actions/errorAction";
import { downloadEventFile } from "../../../store/actions/eventAction";
import { baseUrl } from "../../../store/reducers/usersReducer";
import { RootState } from "../../../store/store";
import {
  DownloadFileTypes,
  FileTypes,
  IEventFile,
  IEventItem,
  ITranscriptionStatus,
  ItemStatus,
  MediaFileType,
} from "../../../models/events";
import { Typography } from "@mui/material";
import EventFileTranscription from "./EventFileTranscription/EventFileTranscription";
import {
  formatSRTtoText,
  matchEventFileName,
  updateFileExtension,
} from "../../../shared/utils-ts";
import { useEffect, useState } from "react";

export function getFile(
  selectedEventId: string,
  eventFileId: string,
  recordingInstanceId: string
) {
  return axios.get(
    `${baseUrl}/events/${selectedEventId}/recordings/${recordingInstanceId}/files/${eventFileId}`
  );
}

interface DownloadEventFilesProps {
  timestamp?: string;
}

const segmentMatch = /_\d\d\d[_.]/g;

export function filterCaptionsFiles(
  eventMediaFile: IEventFile,
  eventFilesList: IEventFile[]
) {
  const tempTranscript = (eventFilesList as IEventFile[])
    ?.filter((item) => item.fileType === FileTypes.TRANSCRIPTION)
    ?.filter((item) => matchEventFileName(item.name, eventMediaFile.name));
  const tempCaption = (eventFilesList as IEventFile[])?.filter(
    (item) =>
      item.fileType === FileTypes.CAPTIONS &&
      !tempTranscript.some((el) => el.languageCode === item.languageCode)
  );
  return tempTranscript.concat(tempCaption);
}

const DownloadEventFiles = ({ timestamp }: DownloadEventFilesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const eventFilesForDownload = useSelector(
    (state: RootState) => state.event.eventFilesForDownload
  );
  const selectedEvent = useSelector(
    (state: RootState) => state.event.selectedEvent
  );
  const selectedRecordingInstance = useSelector(
    (state: RootState) => state.event.selectedRecordingInstance
  );
  const showSegmentedFIles = useSelector(
    (state: RootState) => state.event.showSegmentedFiles
  );

  function getVttFile(
    selectedEventId: string,
    eventFileId: string,
    recordingInstanceId: string
  ) {
    return axios.get(
      `${baseUrl}/events/${selectedEventId}/recordings/${recordingInstanceId}/files/${eventFileId}/toWebvtt/0`
    );
  }
  function getSrtFile(url: string) {
    return axios.get(url);
  }

  const download = async (
    eventFileId: string,
    format: DownloadFileTypes,
    fileName: string
  ) => {
    try {
      dispatch(downloadEventFile(eventFileId, true));
      setTimeout(() => {
        dispatch(downloadEventFile(eventFileId, false));
      }, 3000);
      let response: any;
      if (format === DownloadFileTypes.VTT) {
        getVttFile(
          (selectedEvent as IEventItem).id,
          eventFileId,
          selectedRecordingInstance.id
        ).then((result) => {
          const vttBlob = new Blob([result.data], {
            type: "text/vtt",
          });
          let downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(vttBlob);
          downloadLink.setAttribute(
            "download",
            `${updateFileExtension(fileName, "vtt")}`
          );
          downloadLink.target = "_blank";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      } else {
        response = await getFile(
          (selectedEvent as IEventItem).id,
          eventFileId,
          selectedRecordingInstance.id
        );
        const res = await fetch(`${response.data}`);
        if (res.status === 404 || res.status === 401 || res.status === 403) {
          let errorMessage =
            response.status === 404
              ? t("error.mediaForSpecifiedEventNotFound")
              : t("error.tokenUsedInRequestExpired");
          dispatch(setTosterError(undefined, errorMessage));
          dispatch(downloadEventFile(eventFileId, false));
        }
        if (res.status === 200) {
          let responseUrl = response.data;
          if (format === DownloadFileTypes.TEXT) {
            let srtFileResponse = await getSrtFile(responseUrl);
            const srtBlob = new Blob([formatSRTtoText(srtFileResponse.data)], {
              type: "text/srt",
            });
            let downloadTextLink = document.createElement("a");
            downloadTextLink.href = URL.createObjectURL(srtBlob);
            downloadTextLink.setAttribute(
              "download",
              `${updateFileExtension(fileName, "txt")}`
            );
            downloadTextLink.target = "_blank";
            document.body.appendChild(downloadTextLink);
            downloadTextLink.click();
            downloadTextLink.remove();
          } else {
            let link = document.createElement("a");
            link.href = responseUrl;
            link.setAttribute("download", "download");
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      }
    } catch (error: any) {
      setGlobalError(error, dispatch);
    }
  };

  const [audioFiles, setAudioFiles] = useState<IEventFile[]>([]);
  const [videoFiles, setVideoFiles] = useState<IEventFile[]>([]);
  const [captionFiles, setCaptionFiles] = useState<IEventFile[]>([]);
  const [transcriptFiles, setTranscriptFiles] = useState<IEventFile[]>([]);
  const [other, setOther] = useState<IEventFile[]>([]);

  useEffect(() => {
    let audioFiles1: IEventFile[] = [],
      videoFiles1: IEventFile[] = [],
      captions1: IEventFile[] = [],
      transcriptions1: IEventFile[] = [],
      other1: IEventFile[] = [];
    eventFilesForDownload.flatMap((file: IEventFile) => {
      const fileType = MediaFileType.get(file.fileType);
      if (file.name.match(segmentMatch) && !showSegmentedFIles) return {};
      switch (fileType) {
        case "audio":
          audioFiles1.push(file);
          break;
        case "video":
          videoFiles1.push(file);
          break;
        case "caption":
          captions1.push(file);
          break;
        case "transcription":
          transcriptions1.push(file);
          break;
        default:
          other1.push(file);
      }
      return {};
    });
    setAudioFiles(audioFiles1);
    setVideoFiles(videoFiles1);
    setCaptionFiles(captions1);
    setTranscriptFiles(transcriptions1);
    setOther(other1);
  }, [eventFilesForDownload, showSegmentedFIles]);

  function isRequestedTranscript(
    transcripts: ITranscriptionStatus[] | undefined
  ): boolean {
    if (!transcripts) return false;
    return transcripts.some(
      (transcript) => transcript.transcriptionStatus === ItemStatus.REQUESTED
    );
  }
  function isFailedTranscript(
    transcripts: ITranscriptionStatus[] | undefined
  ): boolean {
    if (!transcripts) return false;
    return transcripts.some(
      (transcript) => transcript.transcriptionStatus === ItemStatus.FAILED
    );
  }
  function isFloorTranscriptGenerating(): boolean {
    const floorTranscript = selectedRecordingInstance?.transcriptions?.find(
      (transcript) => transcript.floor === true
    );
    if (
      floorTranscript &&
      floorTranscript.transcriptionStatus === ItemStatus.REQUESTED
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <S.FlexTable width="100%" height="auto" direction="column" padding="0">
      {!(
        (transcriptFiles.length === 0 &&
          selectedRecordingInstance?.transcriptionStatus ===
            ItemStatus.AVAILABLE) ||
        (transcriptFiles.length === 0 && !showSegmentedFIles)
      ) && (
        <S.DownloadEventTableHeader>
          <Typography variant="h3">{t("events.transcriptions")}</Typography>
          <S.BaseFlexAction></S.BaseFlexAction>
        </S.DownloadEventTableHeader>
      )}
      {isRequestedTranscript(selectedRecordingInstance?.transcriptions) ? (
        <S.DownloadEventSub>
          <Typography variant="p1">
            {t("events.transcriptionGenerating")}
          </Typography>
        </S.DownloadEventSub>
      ) : (
        isFailedTranscript(selectedRecordingInstance?.transcriptions) && (
          <S.DownloadEventSub>
            <Typography variant="p1">
              {t("events.transcriptionFailed")}
            </Typography>
          </S.DownloadEventSub>
        )
      )}
      {/* @ts-ignore */}
      {selectedRecordingInstance?.transcriptions?.length > 0 && (
        <S.EventFilesList style={{ marginBottom: 4 }}>
          {/* @ts-ignore */}
          {selectedRecordingInstance.transcriptions
            ?.filter(
              (item) =>
                // item.floor === false &&
                item.transcriptionStatus === ItemStatus.FAILED ||
                item.transcriptionStatus === ItemStatus.REQUESTED
            )
            .flatMap((transcriptionItemMapped) => (
              <EventFileTranscription
                transcriptionItem={transcriptionItemMapped}
              />
            ))}
        </S.EventFilesList>
      )}
      {transcriptFiles.length > 0 && (
        <S.EventFilesList>
          {transcriptFiles.map((eventFile: IEventFile) => (
            <DownloadEventFileItem
              hasDuration={false}
              isFloorTranscriptGenerating={isFloorTranscriptGenerating()}
              key={eventFile.id}
              eventFile={eventFile}
              downloadHandler={download}
            />
          ))}
        </S.EventFilesList>
      )}

      {audioFiles.length > 0 && (
        <>
          <S.DownloadEventTableHeader>
            <Typography variant="h3">{t("events.audio")}</Typography>
            <S.BaseFlexAction></S.BaseFlexAction>
          </S.DownloadEventTableHeader>
          <S.EventFilesList>
            {audioFiles.map((eventFile: IEventFile) => (
              <DownloadEventFileItem
                hasDuration={true}
                key={eventFile.id}
                eventFile={eventFile}
                downloadHandler={download}
              />
            ))}
          </S.EventFilesList>
        </>
      )}
      {videoFiles.length > 0 && (
        <>
          <S.DownloadEventTableHeader>
            <Typography variant="h3">{t("events.video")}</Typography>
            <S.BaseFlexAction></S.BaseFlexAction>
          </S.DownloadEventTableHeader>
          <S.EventFilesList>
            {videoFiles.map((eventFile: IEventFile) => (
              <DownloadEventFileItem
                hasDuration={true}
                key={eventFile.id}
                eventFile={eventFile}
                downloadHandler={download}
                captionFiles={filterCaptionsFiles(
                  eventFile,
                  eventFilesForDownload
                )}
              />
            ))}
          </S.EventFilesList>
        </>
      )}
      {captionFiles.length > 0 && (
        <>
          <S.DownloadEventTableHeader>
            <Typography variant="h3">{t("events.captions")}</Typography>
            <S.BaseFlexAction></S.BaseFlexAction>
          </S.DownloadEventTableHeader>
          <S.EventFilesList>
            {captionFiles.map((eventFile: IEventFile) => (
              <DownloadEventFileItem
                hasDuration={false}
                key={eventFile.id}
                eventFile={eventFile}
                downloadHandler={download}
              />
            ))}
          </S.EventFilesList>
        </>
      )}

      {other.length > 0 && (
        <>
          <S.DownloadEventTableHeader>
            <Typography variant="h3">{t("events.other")}</Typography>
            <S.BaseFlexAction></S.BaseFlexAction>
          </S.DownloadEventTableHeader>
          <S.EventFilesList>
            {other.map((eventFile: IEventFile) => (
              <DownloadEventFileItem
                hasDuration={false}
                key={eventFile.id}
                eventFile={eventFile}
                downloadHandler={download}
              />
            ))}
          </S.EventFilesList>
        </>
      )}
    </S.FlexTable>
  );
};

export default DownloadEventFiles;
