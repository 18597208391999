import { useTranslation } from "react-i18next";
import MediaPlayer from "../MediaPlayer/MediaPlayer";
import { IMedia, IRemoteTrack } from "../../models/events";
import closeIcon from "../../assets/img/close-error.svg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { formatName } from "../utils-ts";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PreviewDialog = ({
  onCancel,
  downloadHook,
  media,
  captions,
  eventName,
  fileName,
  fileSize,
  fileDuration,
  setMediaOffset,
}: PreviewDialogProps) => {
  const { t } = useTranslation();

  const close = () => {
    onCancel();
  };

  const download = () => {
    downloadHook();
    onCancel();
  };

  let playerOptions: videojs.VideoJsPlayerOptions = {
    controls: true,
    fluid: true,
    controlBar: {
      volumePanel: {
        inline: false,
      },
    },
    sources: [
      {
        src: media.src,
        type: "application/x-mpegURL",
      },
    ],
    tracks: captions,
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={close}
      maxWidth={media.type === "audio" ? "xs" : "sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={close}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            mr: 4,
          }}
        >
          {formatName(eventName, 30)}
        </Typography>
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
          <Typography variant="caption" align="left">
            {`${formatName(fileName, 45)} • `}
          </Typography>
          <Typography variant="caption" align="left">
            {`${fileSize} • `}
          </Typography>
          <Typography variant="caption" align="left">
            {fileDuration}
          </Typography>
        </DialogContentText>
        {media.type !== "error" ? (
          <MediaPlayer
            options={playerOptions}
            type={media.type}
            setMediaOffset={setMediaOffset}
          ></MediaPlayer>
        ) : (
          <Typography variant="p2_bold" color="primary" align="left">
            {t("error.noMediaStream")}
          </Typography>
        )}
        {/* {media.type === "video" && (

        )} */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={download}
        >
          <Typography variant="p1" align="center">
            {t("events.download")}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={close}
        >
          <Typography variant="p1" align="center">
            {t("confirmDialog.cancel")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface PreviewDialogProps {
  onCancel: Function;
  downloadHook: Function;
  media: IMedia;
  captions?: IRemoteTrack[];
  eventName: string;
  fileName: string;
  fileSize: string;
  fileDuration: string;
  setMediaOffset: Function;
}
export default PreviewDialog;
