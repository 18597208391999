import { createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";

const migrations = {
  0: (state) => {
    return {
      ...state,
      event: {
        ...state.event,
        sortedBy: { id: 1, value: "name", direction: "asc" },
      },
    };
  },
};

export const persistConfig = {
  key: "root",
  version: 0,
  storage,
  whitelist: ["user", "category", "event", "error", "navigation"],
  migrate: createMigrate(migrations),
};
