import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Languages from "../Languages/Languages";
import UserOrganizations from "../UserOrganizations/UserOrganizations";

import logoWhite from "../../assets/img/interprefy-white.svg";
import close from "../../assets/img/close.svg";
import logoutIcon from "../../assets/img/logout.svg";
import { logoutUser, keycloakSetup } from "../../shared/keycloack";

import * as S from "../../styled";
import { mobileMenuToggle } from "../../store/actions/userAction";
import { RootState } from "../../store/store";

const MobileNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.data);
  const isOpenMobileMenu = useSelector(
    (state: RootState) => state.user.isOpenMobileMenu
  );

  const closeMenu = () => {
    dispatch(mobileMenuToggle());
  };

  const onLogout = async () => {
    await dispatch(mobileMenuToggle());
    const keycloakObject = await keycloakSetup;
    const { keycloak } = keycloakObject;
    logoutUser(keycloak, dispatch);
  };

  return (
    <S.MobileNavigationBlock
      width="100%"
      height="100%"
      isOpen={isOpenMobileMenu}
    >
      <S.MobileNavigation
        direction="column"
        alignItems="flex-start"
        padding="0"
        width="305px"
        height="100%"
        backgroundColor="white"
        isOpen={isOpenMobileMenu}
      >
        <S.MobileNavigationUser
          width="100%"
          height="175px"
          direction="column"
          alignItems="flex-start"
          justifyContent="space-around"
          padding="2rem"
        >
          <S.MobileLogoutBlock
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
            direction="column"
          >
            <S.StyleFlexBlock
              width="100%"
              height="100%"
              padding="0"
              justifyContent="space-between"
            >
              <img src={logoWhite} alt="interprefy logo" width={100} />
              <S.CloseMobMenuBtn onClick={() => closeMenu()}>
                <img src={close} alt="close icon" />
              </S.CloseMobMenuBtn>
            </S.StyleFlexBlock>
            <S.UserInfo>{user.email}</S.UserInfo>
          </S.MobileLogoutBlock>
        </S.MobileNavigationUser>
        <S.MobileNavigationAction>
          <ul>
            <S.MobileNavigationLi>
              <Languages />
            </S.MobileNavigationLi>
            {user.organizations && (
              <S.MobileNavigationLi>
                <UserOrganizations />
              </S.MobileNavigationLi>
            )}
            <S.MobileNavigationLi>
              {user.email && (
                <S.LogoutBtn onClick={() => onLogout()}>
                  <S.LogoutIcon src={logoutIcon} alt="logout icon" />
                  {t("navigation.logout")}
                </S.LogoutBtn>
              )}
            </S.MobileNavigationLi>
          </ul>
        </S.MobileNavigationAction>
      </S.MobileNavigation>
      <S.StyleFlexDiv
        width="100%"
        height="100%"
        data-testid="outside"
        onClick={() => dispatch(mobileMenuToggle())}
      ></S.StyleFlexDiv>
    </S.MobileNavigationBlock>
  );
};

export default MobileNav;
