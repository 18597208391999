import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dropdown from "../../assets/img/Dropdown.svg";
import check from "../../assets/img/check.svg";
import * as S from "../../styled";
import { changeLanguages } from "../../store/actions/userAction";
import { RootState } from "../../store/store";

export interface ILanguage {
  id: number;
  locale: string;
  value: string;
}
const Languages = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const userLanguage = useSelector((state: RootState) => state.user.language);
  const languages = [
    { id: 1, locale: "en", value: t("languages.english") },
    { id: 2, locale: "fr", value: t("languages.french") },
    { id: 3, locale: "es", value: t("languages.spanish") },
  ];
  const btnRef = useRef<any | null>();
  const [toggleBtn, setToggleBtn] = useState<any | null>();
  const [languageDropdown, setLanguageDropdown] = useState(false);

  const changeLanguage = (lang: ILanguage) => {
    i18n.changeLanguage(lang.locale);
    dispatch(changeLanguages(lang));
    setLanguageDropdown(false);
  };

  const toggleDropdown = () => {
    setLanguageDropdown(!languageDropdown);
    setToggleBtn(btnRef.current?.getAttribute("aria-expanded"));
    setToggleBtn(!toggleBtn);
    btnRef.current.setAttribute("aria-expanded", String(!toggleBtn));
  };

  const setLanguageValue = (id: number) => {
    switch (id) {
      case 1:
        return "English";
      case 2:
        return "Français";
      case 3:
        return "Español";
      default:
        return "English";
    }
  };

  return (
    <S.FlexDropDownLang>
      <S.LanguagesTitle>{t("languages.languages")}</S.LanguagesTitle>
      <S.LanguagesBtnNavigation
        onClick={() => toggleDropdown()}
        ref={btnRef}
        data-test="languages-button"
      >
        <S.LanguagesDefaultText>
          {t("languages.languages")}
        </S.LanguagesDefaultText>
        <S.LanguageDefaultValue>
          {setLanguageValue(userLanguage?.id)}
        </S.LanguageDefaultValue>
        <S.DropDownIconInButtonLanguges
          src={dropdown}
          alt="language dropdown icon"
          aria-expanded={languageDropdown}
          aria-controls="dropdown"
          open={languageDropdown}
        />
      </S.LanguagesBtnNavigation>
      {languageDropdown && (
        <S.FlexDivLanguages direction="column" id="dropdown" role="region">
          <S.LanguagesUl>
            {languages.map((lang) => (
              <S.LanguagesLi key={lang.id}>
                <S.ChooseLanguageBtn
                  value={lang.locale}
                  onClick={() => changeLanguage(lang)}
                >
                  {lang.value}
                  {userLanguage.id === lang.id && (
                    <img src={check} alt="checked language icon" />
                  )}
                </S.ChooseLanguageBtn>
              </S.LanguagesLi>
            ))}
          </S.LanguagesUl>
        </S.FlexDivLanguages>
      )}
    </S.FlexDropDownLang>
  );
};

export default Languages;
