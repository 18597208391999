import React, { useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import calenderIcon from "../../assets/img/calender.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { changeClearDate } from "../../store/actions/eventAction";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import colors from "../../material-ui/theme/colors";

const palette = {
  primary: {
    main: colors.grey[10],
    dark: colors.brand.main,
  },
};

const DatePicker = ({ handleDateChange }: IDatePicker) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [keyValue, updateKeyValue] = React.useState("");

  const toggle = () => setOpen(!open);
  const clearDate = useSelector(
    (state: RootState) => state.event?.clearDateValue
  );

  const dateClear = () => {
    const key = Math.random().toString(36).substring(2, 7);
    setStartDate("");
    setEndDate("");
    updateKeyValue(key);
  };

  useEffect(() => {
    if (clearDate) {
      dateClear();
      dispatch(changeClearDate(false));
    }
  }, [clearDate, dispatch]);

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-GB");
  };

  const OnChangeDateRange = (range: any) => {
    setStartDate(formatDate(range.startDate).split("/").join("-"));
    setEndDate(formatDate(range.endDate).split("/").join("-"));
    handleDateChange(formatDate(range.startDate), formatDate(range.endDate));
    toggle();
  };

  const theme = createTheme({
    palette,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event: MouseEvent) => {
    const dateRangeRef = document.getElementsByClassName(
      "CustomDateRangePicker"
    )[0];
    // @ts-ignore
    if (dateRangeRef && !dateRangeRef.contains(event.target)) {
      if (open) toggle();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="p1" align="left" color="secondary">
          {t("searchBar.from")}
        </Typography>
        <FormControl
          variant="outlined"
          sx={{ minWidth: "190px" }}
          onClick={toggle}
        >
          <OutlinedInput
            id="outlined-adornment-password"
            type={"text"}
            value={startDate}
            placeholder="DD-MM-YYYY"
            size="medium"
            onChange={() => {}}
            endAdornment={
              <InputAdornment position="end">
                <img
                  src={calenderIcon}
                  alt="startDate"
                  style={{ width: "24px" }}
                />
              </InputAdornment>
            }
            label=""
            color="secondary"
            inputProps={{
              "aria-label": "weight",
              style: {
                fontSize: "16px",
                color: colors.grey[100],
                paddingTop: 12.5,
                paddingBottom: 12.5,
              },
            }}
            sx={{
              pr: 1,
              "& input": {
                "&::placeholder": {
                  color: colors.grey[80],
                  opacity: 1,
                },
              },
            }}
          />
        </FormControl>
        <Typography variant="p1" align="left" color="secondary">
          {t("searchBar.to")}
        </Typography>
        <FormControl
          variant="outlined"
          onClick={toggle}
          sx={{ minWidth: "190px" }}
        >
          <OutlinedInput
            id="outlined-adornment-password"
            type={"text"}
            value={endDate}
            placeholder="DD-MM-YYYY"
            size="medium"
            onChange={() => {}}
            endAdornment={
              <InputAdornment position="end">
                <img
                  src={calenderIcon}
                  alt="endDate"
                  style={{ width: "24px" }}
                  onClick={toggle}
                />
              </InputAdornment>
            }
            label=""
            color="secondary"
            inputProps={{
              "aria-label": "weight",
              style: {
                fontSize: "16px",
                color: colors.grey[100],
                paddingTop: 12.5,
                paddingBottom: 12.5,
              },
            }}
            sx={{
              pr: 1,
              "& input": {
                "&::placeholder": {
                  color: colors.grey[80],
                  opacity: 1,
                },
              },
            }}
          />
        </FormControl>
      </Stack>
      <ThemeProvider theme={theme}>
        <DateRangePicker
          key={keyValue}
          open={open}
          toggle={toggle}
          wrapperClassName="CustomDateRangePicker"
          maxDate={new Date()}
          onChange={(range) => {
            OnChangeDateRange(range);
          }}
        />
      </ThemeProvider>
    </>
  );
};

interface IDatePicker {
  handleDateChange: (fromDate: string, toDate: string) => any;
}

export default DatePicker;
