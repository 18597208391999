// material
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ReactNode } from "react";

// hooks

import breakpoints from "./breakpoints";
import componentsOverride from "../components";
import palette from "./colors";
import typography from "./typography";

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

const ThemeConfig = ({ children }: ThemeConfigProps) => {
  const theme = createTheme({
    palette,
    typography,
    breakpoints,
  });
  /* add next line to include override component */
  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
