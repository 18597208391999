import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { keycloakSetup, logoutUser } from "./shared/keycloack";

import SignIn from "./components/SignIn/SignIn";
import Layout from "./layouts/Layout";
import Main from "./layouts/Main/Main";
import HealthCheck from "./components/HealthCheck/HelathCheck";

import * as S from "./styled";
import { authUser, logout } from "./store/actions/userAction";
import { IKeyCloack } from "./models/navigation";
import ThemeConfig from "./material-ui/theme";
import Header from "./layouts/Header/Header";

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    keycloakSetup.then((response) => {
      const { keycloak } = response;
      if (!keycloak || !keycloak.authenticated) {
        keycloak.init({
          token: localStorage.getItem("access-token") || undefined,
          refreshToken: localStorage.getItem("refresh-token") || undefined,
          idToken: localStorage.getItem("token-id") || undefined,
        });
      }
    });
  }, []);

  setInterval(async () => {
    const keyclockObject = await keycloakSetup;
    const { keycloak } = keyclockObject;
    const updatedToken = await keycloak.updateToken(30);
    if (updatedToken) {
      localStorage.setItem("access-token", keycloak.token || "");
      localStorage.setItem("refresh-token", keycloak.refreshToken || "");
      setAuthToken(keycloak.token);
    }
  }, 30000);

  async function handleLogin() {
    const keycloakObject = await keycloakSetup;
    const { keycloak, keycloakOptions } = keycloakObject;
    const authenticated = await keycloak.init(keycloakOptions as any);
    if (authenticated) {
      localStorage.setItem("access-token", keycloak.token || "");
      localStorage.setItem("refresh-token", keycloak.refreshToken || "");
      localStorage.setItem("token-id", keycloak.idToken || "");
      setAuthToken(keycloak.token);
      dispatch(authUser(keycloak.token || ""));
      history.push("/media-library");
    }
  }

  async function setAuthToken(token: string | null | undefined) {
    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];

    const keycloakObject = await keycloakSetup;
    const { keycloakOptions } = keycloakObject;

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setHeader(keycloakOptions);
    }
  }

  function setHeader(keycloakOptions: IKeyCloack) {
    axios.defaults.headers.common["X-RealmName"] = `${keycloakOptions.realm}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  }

  async function handleLogout() {
    const keycloakObject = await keycloakSetup;
    const { keycloak } = keycloakObject;
    logoutUser(keycloak, dispatch);
  }

  function PrivateRoute({ children, ...rest }: any) {
    let token = localStorage.getItem("access-token");
    setAuthToken(token);

    return (
      <Route
        {...rest}
        render={({ location }) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <S.ContainerFullWidth>
      <Switch>
        <Route exact path="/signin">
          <ThemeConfig>
            <Header onLogout={() => {}} />
            <Layout pageTitle={`Sign in`}>
              <SignIn onLogin={handleLogin} />
            </Layout>
          </ThemeConfig>
        </Route>
        <PrivateRoute path="/media-library/organizations/:organizationId/events/:eventId/recordings/:recordingInstanceId">
          <ThemeConfig>
            <Header onLogout={handleLogout} />
            <Layout pageTitle={`Library`}>
              <Main />
            </Layout>
          </ThemeConfig>
        </PrivateRoute>
        <PrivateRoute path="/media-library/organizations/:organizationId/events/:eventId">
          <ThemeConfig>
            <Header onLogout={handleLogout} />
            <Layout pageTitle={`Library`}>
              <Main />
            </Layout>
          </ThemeConfig>
        </PrivateRoute>
        <PrivateRoute path="/media-library">
          <ThemeConfig>
            <Header onLogout={handleLogout} />
            <Layout pageTitle={`Library`}>
              <Main />
            </Layout>
          </ThemeConfig>
        </PrivateRoute>
        <Route exact path="/status">
          <ThemeConfig>
            <Header onLogout={logout} />
            <Layout pageTitle={`Health Check`}>
              <HealthCheck />
            </Layout>
          </ThemeConfig>
        </Route>
        <Route path="*">
          <ThemeConfig>
            <Header onLogout={() => {}} />
            <Layout pageTitle={`Sign in`}>
              <SignIn onLogin={handleLogin} />
            </Layout>
          </ThemeConfig>
        </Route>
      </Switch>
    </S.ContainerFullWidth>
  );
};

export default App;
