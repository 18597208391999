import { Theme } from '@mui/material/styles'
import palette from "../theme/colors";
// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.grey[10],
          },
          "&.Mui-disabled": {
            display: 'none'
          }
        },
      },
    },
  }
}
