//#region General methods

export function isEmpty(value: null | undefined | string | number) {
    return value === undefined || value === null;
}
export function isNotEmptyString(value: any) {
    return !!value && /^.*$/.test(value);
}
export function isNumber(value: any) {
    return typeof value === "number" && isFinite(value);
}
export function isNotEmptyObject(object: any) {
    return object && Object.keys(object).length > 0;
}
export function isEmptyObject(object: {}) {
    return object && Object.keys(object).length === 0;
}

export function timestrToSec(timeString: string) {
    var parts = timeString.split(":");
    return (parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60) + (+parts[2]);
}

export function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}
export function debounce(fn: Function, ms: number) {
    let timer: any;
    return (_: any) => {
        clearTimeout(timer);
        timer = setTimeout((_: any) => {
            timer = null;
            //@ts-ignore
            fn.apply(this, arguments);
        }, ms);
    };
}

//#endregion

export const formatName = (name: string | null | undefined, count: number) => {
    if (isEmpty(name) || !name) return "";
    if (name) return name.slice(0, count) + (name.length > count ? "..." : "");
};

export function matchEventFileName(nameSource: string, nameToMatch: string) {
    const nameSourceFormatted = nameSource.slice(nameSource.split('_')[0].length + 1, nameSource.split('.')[0].length);
    const nameToMatchFormatted = nameToMatch.slice(nameToMatch.split('_')[0].length + 1, nameToMatch.split('.')[0].length);
    return nameSourceFormatted.includes(nameToMatchFormatted);
}

export function updateFileExtension(sourceName: string, extension: string): string {
    return sourceName = sourceName.slice(0, sourceName.lastIndexOf(".")) + "." + extension;
}

const speakerMatch = /\[[^\]]*\]:/g;

export function formatSRTtoText(sourceFile: string) {
    const removedTimestamps = sourceFile.replace(/^\d+\n([\d:,]+ --> [\d:,]+\n)/gm, '');
    const removeDuplicateWhitespace = removedTimestamps.replace(/(\r\n|\n|\r)/gm, " ").replace(/\s\s+/g, ' ');
    const newLines = removeDuplicateWhitespace.replace(/[.?!]\s/g, "$&\n");
    const separateSpeakers = newLines.replace(speakerMatch, "\n$&");
    return separateSpeakers;
}
