import theme from "../styled/theme";

export const MEDIA_LIBRARY = "Media Library";

// All Events
export const ALL_EVENTS_ID = "0";
export const ALL_EVENTS = "All Events";
export const ALL_EVENTS_OBJECT = {
  id: ALL_EVENTS_ID,
  name: ALL_EVENTS,
  open: true,
  date: null,
  parentId: null,
  subCategories: [],
};

// event status
export const PROCESSED = "PROCESSED";
export const FINISHED = "FINISHED";
export const PROCESSING = "PROCESSING";
export const RUNNING = "RUNNING";
export const FAILED = "FAILED";
export const REJECTED = "REJECTED";

// error
export const ERROR_WITH_SERVER =
  "Error with server communication. Please try later!";

// query
export const DEFAULT_SORT = { id: 4, value: "createdAt", direction: "desc" };
export const DEFAULT_TIMEZONE = { id: 1, value: "UTC" };
export const DEFAULT_DATERANGE = { fromDate: "", toDate: ""};
export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_PAGE_NUMBER = 0;

// breakpoint
export const MOBILE_BREAKPOINT = theme.breakpointsWidth.small.slice(0, 3);

export const CREATED_AT = "createdAt";
export const UPDATED_AT = "updatedAt";

// setupTest
export const BASE_URL = "https://tst.api.medialib.interprefy.com";

// process request type
export const TRANSCRIPTION = "transcription";
export const CREATE_FLOOR_FILLED_VIDEO = "createFloorFilledVideo";

// process status
export const AVAILABLE = "AVAILABLE";
export const REQUESTED = "REQUESTED";
