import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ToastProvider } from "react-toast-notifications";

import { store, persistor } from "./store/store";
import "./index.css";
import * as S from "./styled";
import App from "./App";
import Loader from "./components/Loader/Loader";
import "./i18n";
import { history } from "./shared/react-router.utils";

const spiningLoader = (
  <S.LoaderDiv>
    <Loader />
  </S.LoaderDiv>
);

ReactDOM.render(
  <Suspense fallback={spiningLoader}>
    <Provider store={store}>
      <Router history={history}>
        <PersistGate persistor={persistor}>
          <ToastProvider>
            <App />
          </ToastProvider>
        </PersistGate>
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
