import { Theme } from "@mui/material/styles";
// import { merge } from "lodash";
import Button from "./buttons";
import Chip from "./chip";
import Dialog from "./dialog";
import Typography from "./typography";
import Input from "./input";
import Menu from "./menu";
import Paper from "./paper";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return {
    ...Button(theme),
    ...Typography(theme),
    ...Chip(theme),
    ...Dialog(theme),
    ...Input(theme),
    ...Menu(theme),
    ...Paper(theme),
  };
}
