import styled from "styled-components";
import theme from "./theme";

import {
  EventName,
  EventParagraph,
  EventBtn,
  FlexDivEventInfo,
  FlexDiv,
  ToggleDownloadFilesButton,
} from ".";

interface IListProps {
  resizedSidebar?: boolean;
  isExpandedEvent?: boolean;
  justifyContent?: string;
  isLastElementInTheList?: boolean;
  categoryId?: string;
  selected?: boolean;
  tabIndex?: any;
  height?: string;
}

export const EventsList = styled.ul<IListProps>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${(props) => (props.isLastElementInTheList ? "0px" : "30px")};

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba20};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba50};
  }
`;

export const RecordingList = styled.ul<IListProps>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${(props) => (props.isLastElementInTheList ? "0px" : "30px")};

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba20};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba50};
  }
`;

export const Event = styled.li<IListProps>`
  max-width: 100%;
  height: ${(props) => (props.isExpandedEvent ? "auto" : "8rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  box-shadow: inset 0 -1px 0 0 ${theme.colors.gray20};
  cursor: grab;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${theme.colors.orange};
    }
    &:hover ${EventName} {
      color: ${theme.colors.white};
    }
    &:hover ${FlexDivEventInfo} {
      background-color: ${theme.colors.orange};
    }
    &:hover ${EventParagraph} {
      color: ${theme.colors.white};
    }
    &:hover ${FlexDiv} {
      color: ${theme.colors.white};
    }
    &:hover ${EventBtn} {
      color: ${theme.colors.orange};
      border: 2px solid ${theme.colors.orange};
    }
    &:hover ${ToggleDownloadFilesButton} {
      border: 2px solid ${theme.colors.gray40};
    }
  }

  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 145px;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2rem 1rem;
  }
`;

export const LanguagesUl = styled.ul`
  overflow-x: auto;
  max-height: 200px;
  width: 100%;
`;
export const LanguagesLi = styled.li`
  width: 100%;
`;

export const DropdownFilterLi = styled(LanguagesLi)`
  height: 35px;
`;
export const MobileNavigationLi = styled.li`
  margin-bottom: 2rem;
`;

export const MobileCategoriesUl = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: ${theme.colors.white};
`;

export const MobileCategoriesLi = styled.li`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
  box-shadow: inset 0 -1px 0 0 ${theme.colors.gray20};
  cursor: pointer;
`;

export const EventFilesList = styled.ul`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba20};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${theme.colors.blackRgba50};
  }
`;
export const RecordingInstanceListItemUl = styled.ul`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const RecordingInstanceListItem = styled.li`
  height: auto;
  border-bottom: 1px solid ${theme.colors.grayerBorderBottom};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 145px;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0;
  }
`;
