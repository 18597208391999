import { ICategory } from "../../models/categories";
import { IEventItem, IRecordingInstanceItem } from "../../models/events";
import { MEDIA_LIBRARY } from "../../shared/constants";
import { setBreadcrumbs } from "../../shared/navigation-helper.utils";
import { findItemNested } from "../../shared/utils-category";
import {
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_SELECTED_CATEGORY_AND_SEARCH_VALUE,
  GET_SELECTED_CATEGORY_AND_SELECTED_EVENT,
  GO_BACK,
  GET_SELECTED_EVENT_AND_SELECTED_RECORDING_INSTANCE,
  RESET_SEARCH_VALUE,
  ON_ERROR_REDIRECT
} from "../actions/types";
import { ActionWithPayload } from "./eventReducer";

export interface INavigationState {
  breadCrumbs: string;
}

const initialState: INavigationState = {
  breadCrumbs: `${MEDIA_LIBRARY}`,
};

export interface INavigationPayload extends INavigationState {
  categories: ICategory[],
  category: ICategory,
  id: string,
  levelNested: number,
  selectedCategory: ICategory,
  searchInput: string,
  isSelectedCategory: boolean,
  selectedEvent: IEventItem,
  selectedRecordingInstance: IRecordingInstanceItem,
}
// eslint-disable-next-line
export default (state: INavigationState = initialState, action: ActionWithPayload<Partial<INavigationPayload>>) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        breadCrumbs: `${MEDIA_LIBRARY}`,
      };
    case GET_CATEGORY:
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(action.payload.category),
      };
    case GO_BACK:
      let selectCategory = findItemNested(
        //@ts-ignore
        action.payload.categories,
        action.payload.id
      );
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(
          selectCategory,
          "",
          null,
          null,
          false,
          action.payload.levelNested
        ),
      };
    case GET_SELECTED_CATEGORY_AND_SEARCH_VALUE:
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(
          action.payload.selectedCategory,
          action.payload.searchInput,
          null,
          null,
          action.payload.isSelectedCategory
        ),
      };
    case GET_SELECTED_CATEGORY_AND_SELECTED_EVENT:
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(
          action.payload.selectedCategory,
          "",
          action.payload.selectedEvent
        ),
      };
    case GET_SELECTED_EVENT_AND_SELECTED_RECORDING_INSTANCE:
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(
          null,
          "",
          action.payload.selectedEvent,
          action.payload.selectedRecordingInstance
        ),
      };
    case RESET_SEARCH_VALUE:
      return {
        ...state,
        breadCrumbs: setBreadcrumbs(action.payload.selectedCategory),
      };
    case ON_ERROR_REDIRECT:
      return {
        ...state,
        breadCrumbs: `${MEDIA_LIBRARY}`,
      };
    default:
      return state;
  }
};
