import styled from "styled-components";
import theme from "./theme";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 205px;
  height: 100%;
  justify-content: flex-start;
  margin-top: 3rem;
`;

export const Label = styled.label`
  font-family: ${theme.fonts.openSans};
  opacity: 0.4;
  color: #1d1d26;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1rem;
  margin-top: 3rem;
`;

export const Input = styled.input`
  width: 100%;
  font-family: ${theme.fonts.montserratMedium};
  padding: 1rem 0;
  border: 0;
  border-bottom: 1px solid ${theme.colors.gray100};
  border-radius: 0;
  color: ${theme.colors.blackLight};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4rem;
`;

export const InputPassword = styled(Input)`
  font-size: 2rem;
`;

export const ErrorMsg = styled.p`
  color: ${theme.colors.orange};
`;
export const StyledSelect = styled.select`
  border: ${theme.colors.white};
  background: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-size: 1.2rem;
  font-family: ${theme.fonts.roboto};
`;

export const InputSearch = styled.input`
  border: 1px solid ${theme.colors.grayerBorderBottom};
  width: 100%;
  height: 4.8rem;
  border-radius: 0.8rem;
  padding: 1rem;
  padding-right: 3rem;
  position: relative;
  font-size: 1.3rem;
  font-family: ${theme.fonts.openSans};
  color: ${theme.colors.gray50};
  background-color: ${theme.colors.white};
  @media (max-width: ${theme.breakpointsWidth.small}) {
    height: 40px;
  }
`;

export const LabelDropdownFilter = styled.label`
  font-weight: 600;
  color: ${theme.colors.black};
  font-size: 1.2rem;
  font-family: ${theme.fonts.openSans};
`;
