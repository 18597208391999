import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import RecordingInstancesItem from "./RecordIngInstanceItem/RecordingInstanceItem";

import { useHistory } from "react-router-dom";
import { redirectToMainRoute } from "../../../shared/react-router.utils";
import {
  changeNoDataRedirect,
  changeShowEventList,
} from "../../../store/actions/eventAction";
import { RootState } from "../../../store/store";
import { IRecordingInstanceItem } from "../../../models/events";
import { t } from "i18next";
import { Typography } from "@mui/material";
import * as S from "../../../styled";

const RecordingInstances = () => {
  const recordingInstances = useSelector(
    (state: RootState) => state.event.recordingInstances
  );
  const redirectIfNoData = useSelector(
    (state: RootState) => state.event.redirectIfNoData
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirectIfNoData) {
      redirectToMainRoute(history, "current", undefined, undefined);
      dispatch(changeNoDataRedirect());
      dispatch(changeShowEventList(true));
    }
    // eslint-disable-next-line
  }, [redirectIfNoData]);

  return (
    <>
      <Typography variant="h4" sx={{ opacity: 0.6,my:1 }}>
        {t("events.availableRecordings")}
      </Typography>
      <S.RecordingList>
        {recordingInstances.length > 0 &&
          recordingInstances.map(
            (recordingInstance: IRecordingInstanceItem) => {
              return (
                <RecordingInstancesItem
                  key={recordingInstance.id}
                  recordingInstance={recordingInstance}
                />
              );
            }
          )}
      </S.RecordingList>
    </>
  );
};

export default RecordingInstances;
