import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../Loader/Loader";
import axios from "axios";

import * as S from "../../../../styled";
import PreviewDialog from "../../../../shared/PreviewDialog/PreviewDialog";
import {
  DownloadFileTypes,
  FileTypes,
  IEventFile,
  IEventItem,
  IMedia,
  IRemoteTrack,
  MediaFileType,
} from "../../../../models/events";
import { baseUrl } from "../../../../store/reducers/usersReducer";
import { RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { DecodeLanguageShortCode } from "../../../../shared/Language";
import downloadIconHover from "../../../../assets/img/download-hover.svg";
import previewIcon from "../../../../assets/img/preview-icon.svg";
import previewIconHover from "../../../../assets/img/preview-icon-orange.svg";
import { setMediaFileOffset } from "../../../../store/actions/eventAction";
import { Menu, MenuItem } from "@mui/material";
import srtIcon from "../../../../assets/icons/srt-icon.png";
import vttIcon from "../../../../assets/icons/vtt-icon.png";
import textIcon from "../../../../assets/icons/text-icon.png";

export function getStreamUrl(
  eventFileId: string,
  recordingInstanceId: string,
  selectedEvenId: string
) {
  return axios.get(
    `${baseUrl}/events/${selectedEvenId}/recordings/${recordingInstanceId}/files/${eventFileId}/mediaStreamUrl`,
    { withCredentials: true }
  );
}

let mediaObj: IMedia;
let captionsObj: IRemoteTrack[];
const DownloadEventFileItem = ({
  hasDuration,
  eventFile,
  downloadHandler,
  captionFiles,
  isFloorTranscriptGenerating = false,
}: DownloadEventFileItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedEvent = useSelector(
    (state: RootState) => state.event.selectedEvent
  );
  const selectedRecordingInstance = useSelector(
    (state: RootState) => state.event.selectedRecordingInstance
  );
  const [activeDialog, setActiveDialog] = useState({
    isOpen: false,
  });
  const [previewHover, setPreviewHover] = useState<boolean>();

  const previewHandler = async (mediaOffset: number = 0) => {
    captionsObj = await prepareCaptionsList(captionFiles || [], mediaOffset);
    await callUrlApi();
    setActiveDialog({
      isOpen: true,
    });
  };

  const setMediaOffset = (fileId: string, newOffset: number) => {
    dispatch(
      setMediaFileOffset(
        // @ts-ignore
        selectedEvent.id,
        selectedRecordingInstance.id,
        fileId,
        newOffset
      )
    );
  };
  const prepareCaptionsList = async (
    filteredItems: IEventFile[],
    mediaOffset: number
  ) => {
    if (filteredItems.length === 0) return [];
    let filteredItemsWithUrls: IRemoteTrack[] = [];
    filteredItems.forEach(async (caption) => {
      filteredItemsWithUrls.push({
        kind: "captions",
        srclang: DecodeLanguageShortCode(caption.languageCode),
        src: "",
        // @ts-ignore
        src2: `${baseUrl}/events/${
          (selectedEvent as IEventItem).id
        }/recordings/${selectedRecordingInstance.id}/files/${
          caption.id
        }/toWebvtt/`,
        mediaOffset:
          caption.fileType === FileTypes.TRANSCRIPTION ? 0 : mediaOffset,
        id: caption.id,
        offsetInSeconds: caption.offsetInSeconds || 0,
        default: false,
      });
    });
    return filteredItemsWithUrls;
  };

  const callUrlApi = async () => {
    try {
      const response = await getStreamUrl(
        eventFile.id,
        selectedRecordingInstance.id,
        (selectedEvent as IEventItem).id
      );
      const res = await fetch(`${response.data}`);
      if (res.status === 404 || res.status === 401 || res.status === 403) {
        mediaObj = {
          type: "error",
          status: res.status,
          src: "",
          apiOffset: 0,
        };
      }
      if (res.status === 200) {
        mediaObj = {
          type: MediaFileType.get(eventFile.fileType) as any,
          status: 200,
          src: response.data.mediaStreamUrl,
          apiOffset: eventFile.offsetInSeconds || 0,
        };
      }
    } catch (error: any) {
      mediaObj = {
        type: "error",
        status: 500,
        src: "",
        apiOffset: 0,
      };
    }
  };

  const [isTextFile, setIsTextFile] = useState(false);
  useEffect(() => {
    setIsTextFile(
      eventFile.fileType === FileTypes.TRANSCRIPTION ||
        eventFile.fileType === FileTypes.CAPTIONS
    );
  }, [eventFile]);

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleDownloadMenu = (event: any) => {
    if (open) {
      setAnchorElMenu(null);
    } else {
      setAnchorElMenu(event.currentTarget);
    }
  };

  const handleDownloadClose = () => {
    setAnchorElMenu(null);
  };
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorElMenu);
  return (
    <>
      {activeDialog.isOpen && (
        <PreviewDialog
          onCancel={() => {
            setActiveDialog({
              isOpen: false,
            });
          }}
          downloadHook={() => downloadHandler(eventFile.id)}
          media={mediaObj}
          captions={captionsObj}
          eventName={(selectedEvent as IEventItem).name}
          fileName={eventFile.name}
          fileSize={eventFile.size}
          fileDuration={eventFile?.duration || ""}
          setMediaOffset={setMediaOffset}
        />
      )}
      <S.FlexDivEventFileInfo
        key={eventFile.id}
        width="100%"
        height="7.2rem"
        direction="row"
        padding="1rem"
      >
        <S.BaseFlexLanguage
          onClick={(e) =>
            isTextFile ? handleDownloadMenu(e) : downloadHandler(eventFile.id)
          }
        >
          <S.EventFileLanguage>
            {DecodeLanguageShortCode(eventFile.languageCode)}
            {eventFile.languageCode === "SRC" &&
              eventFile.fileType === FileTypes.TRANSCRIPTION &&
              isFloorTranscriptGenerating === false && (
                <>
                  (
                  {DecodeLanguageShortCode(
                    selectedRecordingInstance.floorTranscriptLanguage
                  )}
                  )
                </>
              )}
          </S.EventFileLanguage>
          <S.IconWrap>
            {eventFile.isDownloading ? (
              <div data-testid="spinner">
                <Loader isDownloading={eventFile.isDownloading} />
              </div>
            ) : (
              <S.DownloadIcon src={downloadIconHover}></S.DownloadIcon>
            )}
          </S.IconWrap>
          {isTextFile && (
            <Menu
              id="download-menu"
              anchorEl={anchorElMenu}
              open={open}
              onClose={() => handleDownloadClose}
              disableAutoFocusItem={true}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "auto",
                },
              }}
            >
              <MenuItem
                key={"download"}
                onClick={() =>
                  downloadHandler(eventFile.id, DownloadFileTypes.SRT)
                }
              >
                <S.IconWrap>
                  <S.AlertIcon src={srtIcon} alt="download srt"></S.AlertIcon>
                </S.IconWrap>
                <S.EventFileName>{t("events.download")} SRT</S.EventFileName>
              </MenuItem>
              <MenuItem
                key={"download vtt"}
                onClick={() =>
                  downloadHandler(
                    eventFile.id,
                    DownloadFileTypes.VTT,
                    eventFile.name
                  )
                }
              >
                <S.IconWrap>
                  <S.AlertIcon src={vttIcon} alt="download-vtt"></S.AlertIcon>
                </S.IconWrap>
                <S.EventFileName>{t("events.download")} VTT</S.EventFileName>
              </MenuItem>
              <MenuItem
                key={"download text"}
                onClick={() =>
                  downloadHandler(
                    eventFile.id,
                    DownloadFileTypes.TEXT,
                    eventFile.name
                  )
                }
              >
                <S.IconWrap>
                  <S.AlertIcon src={textIcon} alt="download text"></S.AlertIcon>
                </S.IconWrap>
                <S.EventFileName>{t("events.download")} text</S.EventFileName>
              </MenuItem>
            </Menu>
          )}
        </S.BaseFlexLanguage>
        <S.BaseFlexSizeDownloadFiles justifyContent="flex-start">
          {hasDuration && (
            <S.EventFileSizeDuration>
              {eventFile.duration}
            </S.EventFileSizeDuration>
          )}
        </S.BaseFlexSizeDownloadFiles>
        <S.BaseFlexSizeDownloadFiles justifyContent="flex-start">
          <S.EventFileSizeDuration>{eventFile.size}</S.EventFileSizeDuration>
        </S.BaseFlexSizeDownloadFiles>
        <S.BaseFlexEventName justifyContent="flex-start">
          <S.EventFileName title={eventFile.name}>
            {eventFile.name}
          </S.EventFileName>
        </S.BaseFlexEventName>
        <S.BaseFlexAction justifyContent="flex-end">
          {MediaFileType.get(eventFile.fileType) === "video" ||
          MediaFileType.get(eventFile.fileType) === "audio" ? (
            <S.PreviewFileButton
              onMouseEnter={() => setPreviewHover(true)}
              onMouseLeave={() => setPreviewHover(false)}
              data-testid="preview-button"
              onClick={() => previewHandler(eventFile.mediaOffset)}
            >
              <S.PreviewIcon
                src={previewHover ? previewIconHover : previewIcon}
              ></S.PreviewIcon>
              {t("events.preview")}
            </S.PreviewFileButton>
          ) : (
            <></>
          )}
        </S.BaseFlexAction>
      </S.FlexDivEventFileInfo>
    </>
  );
};

export interface DownloadEventFileItemProps {
  hasDuration: boolean;
  eventFile: IEventFile;
  downloadHandler: Function;
  captionFiles?: IEventFile[];
  isFloorTranscriptGenerating?: boolean;
}
export default DownloadEventFileItem;
